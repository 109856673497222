import { formatCurrency } from 'apps/tmr-frontend/src/utils/formatUtils';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import Table from '../../../components/Table/Table';
import { PayrollTimesheetDetail } from '../../../Interfaces/Accounting/ACForms.interfaces';
import JobInput from './JobInput';
import PhaseCodeInput from './PhaseCodeInput';
import RegularHoursInput from './RegularHoursInput';

type TimesheetDateDetailsTableProps = {
  dateDetails: PayrollTimesheetDetail[];
  empID: string;
  detailID: string;
  changeTimesheetValue: (
    empID: string,
    key: keyof PayrollTimesheetDetail,
    value?: number,
    detailID?: string,
    dateDetailID?: string
  ) => void;
  addDateDetailRow: (empID: string, detailID?: string) => void;
  removeDetailRow: (
    empID: string,
    detailID: string,
    dateDetailID?: string
  ) => void;
  hiddenCols?: string[];
  onlyPercentage?: boolean;
  companyHours: number;
  blockPercentage?: boolean;
  showErrors: boolean;
};

const TimesheetDateDetailsTable = ({
  dateDetails,
  empID,
  detailID,
  changeTimesheetValue,
  addDateDetailRow,
  removeDetailRow,
  hiddenCols,
  onlyPercentage,
  companyHours,
  blockPercentage,
  showErrors,
}: TimesheetDateDetailsTableProps) => {
  return (
    <Table
      data={dateDetails ?? []}
      dataKey="id"
      className={`nestedExpandedTable noFooter w-full`}
      rowClassName={() => 'nestedExpandedTableCell'}
      scrollable={false}
      scrollHeight={undefined}
      alwaysHiddenColumns={hiddenCols}
      hideColumns={true}
      hideColumnsDropdown={true}
    >
      <Column
        header="Emp ID"
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="justify-content-center text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={() => {
          return <span className="visibility-hidden">dateDetails</span>;
        }}
        style={{ minWidth: '180px', maxWidth: '180px' }}
        headerClassName="hidden"
        className=" text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '160px', maxWidth: '160px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Total Hrs"
        style={{ minWidth: '100px', maxWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Dates"
        style={{ minWidth: '120px', maxWidth: '120px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          return (
            <RegularHoursInput
              id={`reg_date_detail_${empID}_${options.rowIndex}`}
              regularHrs={record.regularHrs}
              onlyPercentage={onlyPercentage}
              blockPercentage={blockPercentage}
              onChange={(value) => {
                changeTimesheetValue(
                  empID,
                  'regularHrs',
                  value ?? undefined,
                  detailID,
                  record.id
                );
              }}
              companyHours={companyHours}
            />
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="OT Hrs"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.overTimeHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'overTimeHrs',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
                maxFractionDigits={2}
              />
              <span className="printMedia printShow">{record.overTimeHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Sick Hrs"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.sickHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'sickHrs',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
                maxFractionDigits={2}
              />
              <span className="printMedia printShow">{record.sickHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Holiday Hrs"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.holidayHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'holidayHrs',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
                maxFractionDigits={2}
              />
              <span className="printMedia printShow">{record.holidayHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          return (
            <JobInput
              value={record.job}
              onChange={(event) => {
                changeTimesheetValue(
                  empID,
                  'job',
                  event.value,
                  detailID,
                  record.id
                );

                if (record.phaseCode) {
                  changeTimesheetValue(
                    empID,
                    'phaseCode',
                    undefined,
                    detailID,
                    record.id
                  );
                }
              }}
            />
          );
        }}
        style={{ minWidth: '300px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          if (!record.job || record.job === 'N/A') {
            return;
          }

          return (
            <PhaseCodeInput
              value={record.phaseCode}
              onChange={(event) => {
                changeTimesheetValue(
                  empID,
                  'phaseCode',
                  event.value,
                  detailID,
                  record.id
                );
              }}
              job={record.job}
              showErrors={showErrors && !record.phaseCode}
            />
          );
        }}
        style={{ minWidth: '300px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Per Diem"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                min={0}
                value={record.perDiem}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'perDiem',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    detailID,
                    record.id
                  );
                }}
              />
              <span className="printMedia printShow">{record.perDiem}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px', maxWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (options.rowIndex + 1 === dateDetails?.length) {
            return (
              <i
                className="pi pi-plus text-17px cursor-pointer"
                onClick={() => {
                  addDateDetailRow(empID, detailID);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record) => {
          if (dateDetails?.length !== 1) {
            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  removeDetailRow(empID, detailID, record.id);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default TimesheetDateDetailsTable;
