import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { JCProjectedCostDraft } from '../../../Interfaces/Accounting/JobsContracts.interface';
import {
  putApproveProjectedCostDraft,
  postProjectedCostDraft,
  putSubmitProjectedCostDraft,
  putProjectedCostDraft,
  putRejectProjectedCostDraft,
} from '../../../services/JobsContractsService';
import { JobContractFilters } from '../JCFilters';
import { useCompanyContext } from '../../../context/CompanyContext';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import { Access } from '../../../Interfaces/Role.interfaces';
import RejectionField from '../../../components/dialog/RejectionField';

type JCProjectedCostProcessProps = {
  draft: JCProjectedCostDraft;
  access: Access;
  approvalMode?: boolean;
  blockAll?: boolean;
  resetDraft: () => void;
};

const JCProjectedCostProcess = ({
  draft,
  approvalMode,
  access,
  blockAll,
  resetDraft,
}: JCProjectedCostProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const [draftID, setDraftID] = useState(draft.id ?? '');
  const [blockSave, setBlockSave] = useState(true);
  const [canSubmit, setCanSubmit] = useState(!draft.submitted);
  const [canApprove, setCanApprove] = useState(!!draft.id);
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const rendered = useRef(draft.costs);
  const job = useWatch<JobContractFilters>({ name: 'job' }) as string;
  const toast = useRef<Toast>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: (draftID: string) => {
      if (draftID) {
        return putProjectedCostDraft(draftID, draft.costs, !!approvalMode);
      }

      return postProjectedCostDraft({
        companyID: selectedCompany!.id,
        jobNumber: job,
        costs: draft.costs,
      });
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Projected Costs',
        'The data was saved successfully!',
        3000
      );

      setBlockSave(true);
      setCanSubmit(true);
      setCanApprove(true);

      if (!draftID) {
        setDraftID(data);
      }
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Save Projected Costs',
        "The data couldn't be saved",
        3000
      );
    },
  });

  const submitRequest = useMutation({
    mutationFn: () => {
      return putSubmitProjectedCostDraft(draftID);
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Submit Projected Costs',
        'The data was submitted successfully!',
        3000
      );

      setCanSubmit(false);
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Projected Costs',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: () => {
      return putApproveProjectedCostDraft(draftID);
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Approve Projected Costs',
        'The data was approved successfully!',
        3000
      );
      setCanApprove(false);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        'Approve Projected Costs',
        errorData?.message || "The data couldn't be approved",
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (reason: string) => {
      return putRejectProjectedCostDraft(draftID, reason);
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Reject Projected Costs',
        'The data was rejected successfully!',
        3000
      );
      setCanApprove(false);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        'Reject Projected Costs',
        errorData?.message || "The data couldn't be rejected",
        3000
      );
    },
  });

  useEffect(() => {
    if (rendered.current !== draft.costs && !hasBeenApproved) {
      setBlockSave(false);
      setCanSubmit(false);
      setCanApprove(false);
      rendered.current = draft.costs;
    }
  }, [draft.costs, hasBeenApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <div className="sm:mr-auto">
        <ConfirmationDialog
          tagKey="draft-clear"
          Button={
            <LoadingButton
              label="Clear Form"
              fontSize="text-xl"
              bgColor="bg-orange-500"
              isLoading={false}
              type="button"
              disabled={hasBeenApproved}
            />
          }
          onConfirm={() => {
            resetDraft();
          }}
          message={'Are you sure you want to clear the form data?'}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        {((access.shouldApprove && approvalMode) || access?.editable) && (
          <LoadingButton
            isLoading={isLoading}
            label="Save"
            fontSize="text-xl"
            bgColor="buttonSecondary"
            onClick={() => mutate(draftID)}
            disabled={blockAll || blockSave || isLoading}
          />
        )}
        {access?.editable === 'approval' && !approvalMode && (
          <ConfirmationDialog
            Button={
              <LoadingButton
                label="Submit"
                fontSize="text-xl"
                isLoading={submitRequest.isLoading}
                disabled={
                  blockAll || !canSubmit || submitRequest.isLoading || !draftID
                }
                type="button"
              />
            }
            onConfirm={() => submitRequest.mutate()}
            message="Are you sure you want to submit this draft?"
          />
        )}
        {access?.shouldApprove && approvalMode && draftID && (
          <ConfirmationDialog
            tagKey="draft-rejection"
            visibility={visibility}
            Button={
              <LoadingButton
                label="Reject"
                fontSize="text-xl"
                bgColor="bluwaiRed"
                isLoading={rejectRequest.isLoading}
                disabled={blockAll || rejectRequest.isLoading || !canApprove}
                type="button"
                onClick={() => setVisibility(true)}
              />
            }
            contentClassName="pb-0"
            onConfirm={() => {}}
            acceptClassName="hidden"
            rejectClassName="hidden"
            onHide={() => setVisibility(false)}
            message={
              <RejectionField
                onReject={() => {
                  setVisibility(false);
                }}
                onConfirm={(reason) => {
                  rejectRequest.mutate(reason);
                  setVisibility(false);
                }}
              />
            }
          />
        )}
        {access?.shouldApprove && approvalMode && draftID && (
          <ConfirmationDialog
            tagKey="draft-approval"
            Button={
              <LoadingButton
                label="Approve"
                fontSize="text-xl"
                bgColor="bg-green-500"
                isLoading={approveRequest.isLoading}
                disabled={blockAll || approveRequest.isLoading || !canApprove}
                type="button"
              />
            }
            onConfirm={() => approveRequest.mutate()}
            message={`Are you sure you want to approve this draft?`}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default JCProjectedCostProcess;
