import React, { useEffect, useMemo, useRef, useState } from 'react';
import APOpenTableContainer from '../../features/accountsPayables/APTables/APOpenTableContainer';
import APFilters, {
  fetchAccountsPayablesFilters,
} from '../../features/accountsPayables/APFilters';
import { FormProvider, useForm } from 'react-hook-form';
import APResumeContainer from '../../features/accountsPayables/APResume/APResumeContainer';
import APVendorPaymentsTableContainer from '../../features/accountsPayables/APVendorHistory/APVendorPaymentsTableContainer';
import APVendorTableContainer from '../../features/accountsPayables/APVendor/APVendorTableContainer';
import APAgedPayablesTableContainer from '../../features/accountsPayables/APAgedPayables/APAgedPayablesTableContainer';
import APReportHeader from '../../features/accountsPayables/APReport/APReportHeader';
import APReport from '../../features/accountsPayables/APReport/APReport';
import checkPermissions from '../../HOCs/CheckPermissions';
import APopenPerJobTableContainer from '../../features/accountsPayables/APOpenPerJob/APopenPerJobTableContainer';
import APInvoiceArchiveContainer from '../../features/accountsPayables/APTables/APInvoiceArchiveContainer';
import APParams from '../../features/accountsPayables/APParams';
import ReportSelector from '../../components/report-selector/ReportSelector';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import { MODULES, reportsPerSection } from '../../../../src/utils/rolesConst';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { CompanySource } from '../../Interfaces/User.interfaces';
import { useCompanyContext } from '../../context/CompanyContext';
import DashboardGeneralLedgerPayable from '../../features/accounting-dashboard/DashboardGeneralLedgerPayable';
import { Button } from 'primereact/button';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

const AccountPayables = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<fetchAccountsPayablesFilters>();
  const { selectedCompany } = useCompanyContext();
  const [createBatch, setCreateBatch] = useState(false);
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const { watch, resetField, reset } = methods;
  const reportType = watch('reportType');

  const [protectedReports] = useProtectedReportOptions({
    reports: MODULES['accounts payables'],
  });

  const APSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['accounts payables'],
    Children: APResumeContainer,
  });

  const BatchButton = checkPermissions({
    sectionsToAccess: ['create_payment_batch'],
    Children: () => (
      <Button
        className={`md:ml-5 h-fit ${
          createBatch ? 'bluwaiRed' : 'buttonBackground'
        } buttonBackground border-0 text-xl w-fit py-3 px-3 radius-10px`}
        onClick={() => {
          setCreateBatch((value) => !value);
        }}
      >
        {createBatch ? 'Close' : 'Create'} Payment Batch
      </Button>
    ),
    blockAdminType: true,
  });

  const Vendor = checkPermissions({
    sectionsToAccess: ['vendor_list'],
    Children: APVendorTableContainer,
  });
  const AgedPayables = checkPermissions({
    sectionsToAccess: ['aged_payables'],
    Children: APAgedPayablesTableContainer,
  });
  const VendorPayments = checkPermissions({
    sectionsToAccess: ['payment_history'],
    Children: APVendorPaymentsTableContainer,
  });
  const OpenPerJob = checkPermissions({
    sectionsToAccess: ['open_payables_per_job', 'create_payment_batch'],
    Children: APopenPerJobTableContainer,
  });
  const InvArchive = checkPermissions({
    sectionsToAccess: ['invoice_archive'],
    Children: APInvoiceArchiveContainer,
  });

  const updateCreateBatch = (state: boolean) => {
    setCreateBatch(state);
  };

  const apReport = () => {
    switch (reportType) {
      case 'paymentHistory':
        return <VendorPayments />;
      case 'vendorList':
        return <Vendor />;
      case 'agedPayables':
        return <AgedPayables />;
      case 'invoiceArchive':
        return <InvArchive />;
      case 'openPayablesPerJob':
        return (
          <OpenPerJob
            showPerJobAmount={true}
            showPerJobRetainage={true}
            createBatch={createBatch}
            changeCreateBatch={updateCreateBatch}
          />
        );
      case 'openPayables':
        return (
          <APOpenTableContainer
            createBatch={createBatch}
            changeCreateBatch={updateCreateBatch}
          />
        );
      case 'unapprovedInvoices':
        return <APOpenTableContainer />;
      default:
        return null;
    }
  };

  const handleReportChange = () => {
    resetField('info');
    resetField('vendors');
    resetField('dates');
    resetField('jobs');
    setCreateBatch(false);
  };

  const APReportCB = useMemo(apReport, [reportType, createBatch]);

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, reset]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-3 flex gap-2 flex-wrap justify-content-center md:justify-content-start">
          <div className="flex justify-content-center flex-wrap">
            <a
              className="text-white h-fit sm:mr-auto"
              href={process.env['NX_AP_LINK']}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="md:ml-5 h-fit buttonBackground border-0 text-xl w-fit py-3 px-3 radius-10px">
                {process.env['NX_AP_LINK_LABEL']}
              </Button>
            </a>
            {(reportType === 'openPayables' ||
              reportType === 'openPayablesPerJob') && <BatchButton />}
          </div>
          {getSource(selectedCompany) === CompanySource.ViewPoint && <APSummary />}
          {getSource(selectedCompany) === CompanySource.Yardi && (
            <DashboardGeneralLedgerPayable />
          )}
        </div>
        <div className="flex flex-column  gap-2 mx-3 mt-4">
          <div className="text-standard mb-1">Select Report</div>
          <div className="flex flex-row gap-2 justify-content-start">
            <ReportSelector
              protectedReports={protectedReports}
              onChange={() => {
                handleReportChange();
              }}
            />
            <div className="flex w-full justify-content-between  gap-2">
              <FiltersMenu>
                <APFilters />
              </FiltersMenu>
              <APReport elementRef={tableRef} />
            </div>
          </div>
        </div>
        <div className="mb-3 printBackground pdfDivFitContent" ref={tableRef}>
          <APReportHeader />
          {APReportCB}
        </div>
        <APParams changeCreateBatch={updateCreateBatch} />
      </FormProvider>
    </div>
  );
};

export default AccountPayables;
