import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import CalendarStyled from '../../../components/inputs/StyledInputs/CalendarStyled';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import { Access } from '../../../Interfaces/Role.interfaces';
import {
  fetchSCNextInvoiceNumber,
  postSCPayment,
  putSCApprovePayment,
  putSCPayment,
  putSCRejectPayment,
  putSCSubmitPayment,
} from '../../../services/SubcontractsService';
import { SubcontractsFilters } from '../SCFilters';

type SCPaymentProcessProps = {
  draftAmounts: Record<number, number>;
  children: React.ReactNode;
  vendor: number;
  draftDefaultDate?: Date;
  defaultDraftID?: string;
  defaultSubmitted?: boolean;
  isApproval: boolean;
  blockAll?: boolean;
  access: Access;
  resetDraft: () => void;
};

const SCPaymentProcess = ({
  draftAmounts,
  children,
  vendor,
  draftDefaultDate,
  defaultDraftID,
  defaultSubmitted,
  isApproval,
  blockAll,
  access,
  resetDraft,
}: SCPaymentProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const [invoiceDate, setInvoiceDate] = useState<Date | undefined>(
    draftDefaultDate
  );
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const [blockSave, setBlockSave] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(
    defaultSubmitted ?? !defaultDraftID
  );
  const [blockApproval, setBlockApproval] = useState(
    isApproval ? false : !defaultDraftID
  );
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const prevDraft = useRef(draftAmounts);
  const prevInvDate = useRef(draftDefaultDate);
  const toast = useRef<Toast>(null);
  const job = useWatch<SubcontractsFilters>({ name: 'job' });
  const subcontract = useWatch<SubcontractsFilters>({ name: 'subcontract' });

  const { data, isFetching, isError } = useQuery({
    queryKey: ['getSCNextInvoiceNumber', selectedCompany, invoiceDate, vendor],
    queryFn: ({ signal }) => {
      return fetchSCNextInvoiceNumber(
        {
          companyID: selectedCompany!.id,
          invoiceDate: invoiceDate as Date,
          vendor,
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!(selectedCompany && invoiceDate),
  });

  const save = useMutation({
    mutationFn: (draftID?: string) => {
      const basePayload = {
        invoiceDate: invoiceDate as Date,
        amounts: draftAmounts,
      };

      if (draftID) {
        return putSCPayment(draftID, basePayload, !!isApproval);
      }

      const payload = {
        companyID: selectedCompany!.id,
        job: job as string,
        subcontract: subcontract as string,
        ...basePayload,
      };

      return postSCPayment(payload);
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Subcontract Payment',
        'The data was saved successfully!',
        3000
      );

      if (!draftID) {
        setDraftID(data);
      }
      setBlockSave(true);
      setBlockSubmit(false);
      setBlockApproval(false);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        'Save Subcontract Payment',
        errorData?.message ?? "The data couldn't be saved",
        3000
      );
    },
  });

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return putSCSubmitPayment(draftID);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        'Submit Subcontract Payment',
        'The data was submitted successfully!',
        3000
      );

      setBlockSubmit(true);
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Subcontract Payment',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: (draftID: string) => {
      return putSCApprovePayment(draftID);
    },
    onSuccess: () => {
      const label = isApproval ? 'Approve' : 'Upload';
      const mesLabel = isApproval ? 'approved' : 'uploaded';

      showToast(
        'success',
        toast,
        `${label} Subcontract Payment`,
        `The data was ${mesLabel} successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = isApproval ? 'Approve' : 'Upload';
      const mesLabel = isApproval ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `${label} Subcontract Payment`,
        errorData?.message || `The data couldn't be ${mesLabel}.`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putSCRejectPayment(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Subcontract Payment`,
        `The draft was rejected successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Subcontract Payment`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  const resetContent = () => {
    setInvoiceDate(undefined);
    resetDraft();
  };

  useEffect(() => {
    const dateChanged = prevInvDate.current !== invoiceDate;
    const amountsChanged = prevDraft.current !== draftAmounts;
    const hasAmounts = Object.values(draftAmounts).length > 0;

    if (
      (dateChanged || amountsChanged) &&
      !!invoiceDate &&
      hasAmounts &&
      !hasBeenApproved
    ) {
      setBlockSave(false);
      setBlockSubmit(true);
      setBlockApproval(true);
    } else if (!invoiceDate || !hasAmounts) {
      setBlockSave(true);
      setBlockSubmit(true);
      setBlockApproval(true);
    }

    prevDraft.current = draftAmounts;
    prevInvDate.current = invoiceDate;
  }, [draftAmounts, invoiceDate, hasBeenApproved]);

  return (
    <React.Fragment>
      {(isApproval ? access.shouldApprove : access?.editable) && (
        <div className="mx-3 mt-3 mb-1 flex flex-wrap gap-3">
          <div className="flex gap-1 align-content-center flex-column max-w-10rem">
            <label htmlFor="invDate" className="text-standard h-fit my-auto">
              Invoice Date
            </label>
            <CalendarStyled
              placeholder="Select Date"
              inputId="invDate"
              showButtonBar
              value={invoiceDate}
              onChange={(event) =>
                event.target.value
                  ? setInvoiceDate(new Date(event.target.value.toString()))
                  : setInvoiceDate(undefined)
              }
            />
          </div>
          <div className="flex gap-1 align-content-center flex-column max-w-13rem">
            <label htmlFor="invNumber" className="text-standard h-fit my-auto">
              Next Invoice Number
            </label>
            <InputText
              id="invNumber"
              disabled={true}
              value={invoiceDate && !isFetching && !isError ? data : ''}
              placeholder={
                isFetching
                  ? 'Fetching invoice number'
                  : isError
                  ? 'Error fetching data!'
                  : ''
              }
            />
          </div>
        </div>
      )}
      {children}
      <div className="mt-3 flex flex-wrap justify-content-center gap-4 mx-3 printHide">
        <div className="sm:mr-auto">
          <ConfirmationDialog
            tagKey="draft-clear"
            Button={
              <LoadingButton
                label="Clear Form"
                fontSize="text-xl"
                bgColor="bg-orange-500"
                isLoading={false}
                type="button"
                disabled={hasBeenApproved}
              />
            }
            onConfirm={() => {
              resetContent();
            }}
            message={'Are you sure you want to clear the form data?'}
          />
        </div>
        <div className="flex flex-wrap gap-4">
          {(isApproval ? access.shouldApprove : access?.editable) && (
            <React.Fragment>
              <LoadingButton
                id="SC_Bill_Save"
                label="Save"
                fontSize="text-xl"
                bgColor="buttonSecondary"
                isLoading={save.isLoading}
                onClick={() => save.mutate(draftID)}
                disabled={blockAll || blockSave || save.isLoading}
              />
              {(!invoiceDate || !Object.values(draftAmounts).length) && (
                <Tooltip
                  target={`#SC_Bill_Save`}
                  content={`Invoice Date and Billed Amounts must be filled before saving.`}
                  position="left"
                  showOnDisabled={true}
                />
              )}
            </React.Fragment>
          )}
          {!isApproval && access?.editable === 'approval' && (
            <ConfirmationDialog
              Button={
                <LoadingButton
                  label="Submit"
                  fontSize="text-xl"
                  isLoading={submit.isLoading}
                  disabled={blockAll || blockSubmit || submit.isLoading}
                  type="button"
                />
              }
              onConfirm={() => submit.mutate(draftID)}
              message="Are you sure you want to submit this draft?"
            />
          )}
          {isApproval && access?.shouldApprove && (
            <ConfirmationDialog
              tagKey="draft-rejection"
              visibility={visibility}
              Button={
                <LoadingButton
                  label={'Reject'}
                  fontSize="text-xl"
                  bgColor="bluwaiRed"
                  isLoading={rejectRequest.isLoading}
                  disabled={
                    blockAll || blockApproval || rejectRequest.isLoading
                  }
                  type="button"
                  onClick={() => setVisibility(true)}
                />
              }
              contentClassName="pb-0"
              onConfirm={() => {}}
              acceptClassName="hidden"
              rejectClassName="hidden"
              onHide={() => setVisibility(false)}
              message={
                <RejectionField
                  onReject={() => {
                    setVisibility(false);
                  }}
                  onConfirm={(reason) => {
                    rejectRequest.mutate({ draftID, reason });
                    setVisibility(false);
                  }}
                />
              }
            />
          )}
          {(isApproval
            ? access?.shouldApprove
            : access?.editable === 'no-approval') && (
            <ConfirmationDialog
              tagKey="draft-approval"
              Button={
                <LoadingButton
                  label={isApproval ? 'Approve' : 'Upload'}
                  fontSize="text-xl"
                  bgColor="bg-green-500"
                  isLoading={approveRequest.isLoading}
                  disabled={
                    blockAll || blockApproval || approveRequest.isLoading
                  }
                  type="button"
                />
              }
              onConfirm={() => approveRequest.mutate(draftID)}
              message={`Are you sure you want to ${
                isApproval ? 'approve' : 'upload'
              } this draft?`}
            />
          )}
        </div>
      </div>
      <ErrorToast toastRef={toast} />
    </React.Fragment>
  );
};

export default SCPaymentProcess;
