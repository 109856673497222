import {
  formatCellTextToAmount,
  formatCurrency,
} from '../../../../utils/formatUtils';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ErrorToast, { showToast } from '../../messages/ErrorAlert';
import { Toast } from 'primereact/toast';
import WrapperButton from '../../buttons/WrapperButton';

type CurrencyLabelInputProps = {
  pTotal?: number;
  limit?: number;
  exceedErrorMessage?: string;
  blockEdit?: boolean;
  defaultValue?: number;
  changeValue?: (number?: number) => void;
  onInputStart?: () => void;
  onInputEnd?: () => void;
};

const CurrencyLabelInput = ({
  pTotal,
  limit,
  exceedErrorMessage,
  blockEdit,
  defaultValue,
  changeValue,
  onInputStart,
  onInputEnd,
}: CurrencyLabelInputProps) => {
  const toast = useRef<Toast>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue?.toString() ?? '');
  const [showValue, setShowValue] = useState(
    defaultValue ? formatCurrency(defaultValue) : ''
  );

  const formatInputAmount = (text: string) => {
    try {
      const result = formatCellTextToAmount({
        text,
        limitAmount: limit,
        pTotal,
        exceedErrorMessage,
      });
      const roundedResult = result ? Math.round(result * 100) / 100 : null;

      setShowValue(roundedResult ? formatCurrency(roundedResult) ?? '' : '');
      setInputValue(roundedResult?.toString() ?? '');

      if (changeValue) {
        changeValue(roundedResult ?? undefined);
      }
    } catch (e) {
      const error = e as { message: string };
      showToast('error', toast, 'Edit Amount Error', error.message, 3000);
      setShowValue('');
      setInputValue('');
      if (changeValue) {
        changeValue(undefined);
      }
    }

    if (onInputEnd) {
      onInputEnd();
    }
  };

  useEffect(() => {
    if (showInput) {
      setTimeout(() => inputRef.current?.focus(), 25);
    }
  }, [showInput]);

  useEffect(() => {
    setInputValue(defaultValue?.toString() ?? '');
    setShowValue(defaultValue ? formatCurrency(defaultValue) ?? '' : '');
  }, [defaultValue]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      {!showInput && !blockEdit && (
        <div className="border-bottom-2 w-full h-full flex align-items-center justify-content-center">
          <WrapperButton
            onClick={() => {
              setShowInput(true);

              if (onInputStart) {
                onInputStart();
              }
            }}
            className="w-full"
          >
            {showValue ? (
              <div className="w-full text-right totalColor printColor text-standard">
                {showValue}
              </div>
            ) : (
              <i className="pi pi-pencil totalColor printHide" />
            )}
          </WrapperButton>
        </div>
      )}
      {showInput && (
        <InputText
          ref={inputRef}
          type="text"
          keyfilter={/\d|\.|%/}
          className="w-full h-full"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onBlur={() => {
            setShowInput(false);
            formatInputAmount(inputValue);
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              setShowInput(false);
              formatInputAmount(inputValue);
            }
          }}
          tooltip={`Enter amount or percentage by using '%' at the end`}
          tooltipOptions={{ position: 'top', event: 'focus' }}
        />
      )}
    </React.Fragment>
  );
};

export default CurrencyLabelInput;
