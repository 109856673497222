import React from 'react';
import { formatDate } from '../../../../utils/formatUtils';
import { useWatch } from 'react-hook-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchAPOpenJobs } from '../../../services/AccountsPayablesService';
import { useQuery } from '@tanstack/react-query';
import {
  getJobNames,
  getPayableTypeName,
  getVendorNames,
} from './APReportUtils';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { getVendorFetch, getVendorKeyPerCompany } from '../apUtil';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

const APReportHeader = () => {
  const { selectedCompany } = useCompanyContext();
  const vendorKey = getVendorKeyPerCompany(getSource(selectedCompany));
  const filters = useWatch<fetchAccountsPayablesFilters>();

  const { data } = useQuery({
    queryKey: ['getAPVendors', selectedCompany!.id, filters.reportType],
    queryFn: ({ signal }) => {
      return getVendorFetch(filters.reportType ?? '', selectedCompany!, signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const jobs = useQuery({
    queryKey: ['getAPOpenJobs', selectedCompany],
    queryFn: ({ signal }) => {
      return fetchAPOpenJobs(selectedCompany!, signal);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <div className="blackText printMedia printShow">
      <div className="text-30px text-center mt-5">{selectedCompany!.name}</div>
      <div className="text-28px text-center">Accounts Payables Report</div>
      <div className="text-17px ml-3 mt-2">
        <span className="font-bold text-20px">Search criteria:</span>
        <div className="ml-3">
          <div>
            <span className="font-bold mr-1">Report Type:</span>
            {getPayableTypeName(filters.reportType ?? '')}
          </div>
          {filters.jobs && filters.jobs.length !== 0 && (
            <React.Fragment>
              <div>
                <span className="font-bold mr-1">Jobs:</span>
                {filters.jobs.join(', ')}
              </div>
              <div>
                <span className="font-bold mr-1">Job Names:</span>
                {getJobNames(filters.jobs, jobs.data)}
              </div>
            </React.Fragment>
          )}
          {filters.vendors && filters.vendors.length !== 0 && (
            <div>
              <span className="font-bold mr-1">Vendors:</span>
              {getVendorNames(filters.vendors, data, vendorKey)}
            </div>
          )}
          {filters.dates && (
            <div>
              <span className="font-bold mr-1">Date range:</span>
              {formatDate(filters.dates[0])}
              {filters.dates[1] && ` - ${formatDate(filters.dates[1])}`}
            </div>
          )}
          {!filters.dates && filters.reportType === 'paymentHistory' && (
            <div>
              <span className="font-bold mr-1">Month:</span>
              {new Date().toLocaleString('en-US', { month: 'long' })}
            </div>
          )}
          {filters.info && (
            <div>
              <span className="font-bold">Search term:</span> {filters.info}
            </div>
          )}
        </div>
      </div>
      <div className="ml-3 text-17px mb-5">
        <span className="font-bold mr-1">Created at:</span>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default APReportHeader;
