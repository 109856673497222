import {
  DraftBatchPayment,
  Payable,
} from 'apps/tmr-frontend/src/app/Interfaces/Accounting/AccountsPayables.interfaces';
import { Checkbox } from 'primereact/checkbox';
import { transformPayableToPayment } from '../../apUtil';

type PaymentBatchCheckboxProps = {
  isSelected: boolean;
  openPayable: Payable;
  allPayables: Payable[];
  addPayableToBatch: (payable: DraftBatchPayment) => void;
  removePayableFromBatch: (payable: DraftBatchPayment) => void;
  addTotalToBatch: (payment: DraftBatchPayment, allPayables: Payable[]) => void;
  removeTotalFromBatch: (payment: DraftBatchPayment) => void;
};

const PaymentBatchCheckbox = ({
  isSelected,
  openPayable,
  allPayables,
  addPayableToBatch,
  removePayableFromBatch,
  addTotalToBatch,
  removeTotalFromBatch,
}: PaymentBatchCheckboxProps) => {
  return (
    <div className="capitalize">
      <Checkbox
        checked={isSelected}
        onChange={(e) => {
          e.originalEvent.stopPropagation();
          const payment = transformPayableToPayment(openPayable);

          if (e.checked) {
            if (payment.apref) {
              addPayableToBatch(payment);
            } else {
              addTotalToBatch(payment, allPayables);
            }
          } else {
            if (payment.apref) {
              removePayableFromBatch(payment);
            } else {
              removeTotalFromBatch(payment);
            }
          }
        }}
      />
    </div>
  );
};

export default PaymentBatchCheckbox;
