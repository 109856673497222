import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import {
  AgedPayable,
  Payable,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import APDrilldown from '../APDrilldown/APDrilldown';
import {
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { useCompanyContext } from '../../../context/CompanyContext';
import { CompanySource } from '../../../Interfaces/User.interfaces';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

type APAgedPayablesTableProps = {
  accountsPayables: AgedPayable[];
};

const APAgedPayablesTable = React.forwardRef<
  HTMLDivElement,
  APAgedPayablesTableProps
>(({ accountsPayables }, ref) => {
  const [showDrilldown, setShowDrilldown] = useState(false);
  const [selectedPayable, setSelectedPayable] = useState<Payable>();
  const { selectedCompany } = useCompanyContext();

  const calcHeight = (rows: number) => {
    const headerHeight = 32;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const rowClassName = (data: Payable) => {
    let className = data.Reference ? 'cursor-pointer' : '';
    if (!data.Reference) {
      className = `${className} font-bold surface-300`;
    }

    return className;
  };

  const onRowClick = (e: DataTableRowClickEventParams) => {
    setSelectedPayable(e.data);
    setShowDrilldown(true);
  };

  let current = 0;
  let over30 = 0;
  let over60 = 0;
  let over90 = 0;
  let hold = 0;
  let discount = 0;
  accountsPayables.forEach((payable) => {
    if (payable.Reference) {
      discount += payable.DiscountOffered || 0;
      hold += payable.HoldRet;
      current += payable.Current;
      over30 += payable.Over30;
      over60 += payable.Over60;
      over90 += payable.Over90;
    }
  });

  return (
    <div className="pdfDivFitContent">
      {selectedPayable && (
        <APDrilldown
          visible={showDrilldown}
          setVisible={setShowDrilldown}
          payable={selectedPayable}
        />
      )}
      <Table
        id="aged-table"
        ref={ref}
        data={accountsPayables}
        className={`mx-3 dashboardOptionShadow tableFirstLineStyled`}
        calcHeight={useCallback(calcHeight, [])}
        onRowClick={useCallback(onRowClick, [])}
        rowClassName={useCallback(rowClassName, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        hideColumns={true}
      >
        <Column
          field="Vendor"
          header="Vendor"
          body={(ap) => <div className="scroll-text">{ap.Vendor}</div>}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '300px', maxWidth: '300px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Reference"
          header="Inv. Number"
          style={{ minWidth: '150px' }}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="InvoiceDate"
          header="Inv. Date"
          style={{ minWidth: '150px' }}
          body={(ap) => formatUTCDate(ap.InvoiceDate)}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="Description"
          header="Description"
          body={(ap) => <div className="scroll-text">{ap.Description}</div>}
          style={{ minWidth: '250px', maxWidth: '250px' }}
          headerClassName="tableHeader font-normal"
          className=" text-standard blackText tableCell  overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName={`tableFooter`}
          sortable
        />
        <Column
          field="DueDate"
          header="Due Date"
          style={{ minWidth: '125px' }}
          body={(ap) => formatUTCDate(ap.DueDate)}
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footer="Total"
          footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          sortable
        />
        <Column
          field="Current"
          header="Current"
          style={{ minWidth: '120px' }}
          body={(ap) => (ap.Current ? formatCurrency(ap.Current) : '-')}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(current)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="Over30"
          header="31 - 60"
          style={{ minWidth: '120px' }}
          body={(ap) => (ap.Over30 ? formatCurrency(ap.Over30) : '-')}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(over30)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="Over60"
          header="61 - 90"
          style={{ minWidth: '120px' }}
          body={(ap) => (ap.Over60 ? formatCurrency(ap.Over60) : '-')}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(over60)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        <Column
          field="Over90"
          header="Over 90"
          style={{ minWidth: '120px' }}
          body={(ap) => (ap.Over90 ? formatCurrency(ap.Over90) : '-')}
          headerClassName="tableHeader font-normal justify-content-center text-center"
          className="justify-content-end text-standard blackText tableCell"
          footer={formatCurrency(over90)}
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          sortable
        />
        {getSource(selectedCompany) === CompanySource.ViewPoint ? (
          <Column
            field="HoldRet"
            header="Hold/Ret"
            style={{ minWidth: '120px' }}
            body={(ap) => (ap.HoldRet ? formatCurrency(ap.HoldRet) : '-')}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(hold)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
        ) : null}
      </Table>
      <div className="ml-auto w-24rem mr-5 my-5 text-standard">
        <div className="flex justify-content-between">
          <span>Accounts Payables</span>
          <span id="totalPayables">
            {formatCurrency(current + over30 + over60 + over90)}
          </span>
        </div>
        <div className="flex justify-content-between mt-2">
          <span>A/P - Retainage</span>
          <span id="totalRetainage">{formatCurrency(hold)}</span>
        </div>
        <div className="flex justify-content-between mt-2">
          <span>Discount</span>
          <span id="totalDiscount">{formatCurrency(-1 * (discount || 0))}</span>
        </div>
        <div className="flex justify-content-between mt-1 border-top-1 border-bottom-2">
          <span className="font-bold">Grand Total</span>
          <span id="grandTotal">
            {formatCurrency(
              current + over30 + over60 + over90 + hold - discount
            )}
          </span>
        </div>
      </div>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<APAgedPayablesTableProps>,
  nextTransactions: Readonly<APAgedPayablesTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APAgedPayablesTable, transactionsAreEqual);
