import { Controller, useFormContext, useWatch } from 'react-hook-form';
import DropdownStyled from '../../components/inputs/StyledInputs/DropdownStyled';
import FormControlledInput from '../../components/inputs/FormInputs/FormControlledInput';
import FormErrorMessage from '../../components/messages/FormErrorMessage';
import { CompanySource } from '../../Interfaces/User.interfaces';
import FormControlledNumberInput from '../../components/inputs/FormInputs/FormControlledNumberInput';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  fetchCompanies,
  fetchSourceCompanies,
} from '../../services/UsersService';
import FormControlledDropdown from '../../components/inputs/FormInputs/FormControlledDropdown';

export type CompanyFormFields = {
  source: CompanySource;
  code: string;
  name?: string;
  hours?: number;
  payPeriods?: number;
  mask?: string;
};

const CompanyForm = () => {
  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext();
  const source = useWatch({ name: 'source' });

  const maskData = useQuery({
    queryKey: ['getCompanies'],
    queryFn: ({ signal }) => {
      return fetchCompanies({}, signal);
    },
    refetchOnWindowFocus: false,
  });

  const getSourceCompany = useQuery({
    queryKey: ['getSourceCompanies', source],
    queryFn: ({ signal }) => fetchSourceCompanies(source, signal),
    refetchOnWindowFocus: false,
    enabled: source === CompanySource.ViewPoint,
  });

  return (
    <div className="grid mx-3 justify-content-center">
      <div className="col-12 sm:col-6">
        <label htmlFor="source" className="text-standard block mb-2">
          Company Source
        </label>
        <Controller
          defaultValue=""
          control={control}
          name="source"
          rules={{
            required: {
              value: true,
              message: 'Company Source is a required field',
            },
          }}
          render={({ field: { ref, onChange, ...otherFields } }) => (
            <DropdownStyled
              id="source"
              options={Object.values(CompanySource)}
              placeholder={'Select Company Source'}
              clearable={false}
              inputRef={ref}
              error={!!errors['source']}
              onChange={(e) => {
                onChange(e);
                resetField('code', {
                  defaultValue: CompanySource.ViewPoint === e.value ? null : '',
                });
                resetField('name');
                resetField('hours');
                resetField('payPeriods');
              }}
              {...otherFields}
            />
          )}
        />
        {errors['source'] && (
          <FormErrorMessage errorMessage={errors['source'].message as string} />
        )}
      </div>
      <div className="col-12 sm:col-6" />
      {source !== CompanySource.ViewPoint ? (
        <React.Fragment>
          <div className="col-12 sm:col-6">
            <label htmlFor="code" className="text-standard block mb-2">
              Code
            </label>
            <FormControlledInput
              formID="code"
              rules={{
                required: {
                  value: true,
                  message: 'Code is a required field',
                },
              }}
            />
          </div>
          <div className="col-12 sm:col-6">
            <label htmlFor="name" className="text-standard block mb-2">
              Name
            </label>
            <FormControlledInput
              formID="name"
              rules={{
                required: {
                  value: true,
                  message: 'Name is a required field',
                },
              }}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="col-12 sm:col-6">
            <label htmlFor="code" className="text-standard block mb-2">
              Code
            </label>
            <FormControlledDropdown
              formID="code"
              options={getSourceCompany.data}
              labelField="code"
              valueField="code"
              placeholder={
                getSourceCompany.isError
                  ? 'Failed to load company codes!'
                  : 'Select Code'
              }
              isDisabled={getSourceCompany.isError}
              isLoading={getSourceCompany.isFetching}
              clearable={true}
              filter={true}
              resetFilterOnHide={true}
              rules={{
                required: {
                  value: true,
                  message: 'Code is a required field',
                },
              }}
            />
          </div>
          <div className="col-12 sm:col-6">
            <label htmlFor="code" className="text-standard block mb-2">
              Name
            </label>
            <FormControlledDropdown
              formID="code"
              options={getSourceCompany.data}
              labelField="name"
              valueField="code"
              placeholder={
                getSourceCompany.isError
                  ? 'Failed to load company names!'
                  : 'Select Name'
              }
              isDisabled={getSourceCompany.isError}
              isLoading={getSourceCompany.isFetching}
              clearable={true}
              filter={true}
              resetFilterOnHide={true}
            />
          </div>
          <div className="col-12 sm:col-6">
            <label htmlFor="hours" className="text-standard block mb-2">
              Hours
            </label>
            <FormControlledNumberInput
              formID="hours"
              min={0}
              maxFractionDigits={3}
            />
          </div>
          <div className="col-12 sm:col-6">
            <label htmlFor="payPeriods" className="text-standard block mb-2">
              Pay Periods
            </label>
            <FormControlledNumberInput formID="payPeriods" min={0} />
          </div>
        </React.Fragment>
      )}
      {source === CompanySource.Mask && (
        <React.Fragment>
          <div className="col-12 sm:col-6">
            <label htmlFor="mask" className="text-standard block mb-2">
              Company Mask
            </label>
            <FormControlledDropdown
              formID="mask"
              options={maskData.data?.companies}
              labelField="name"
              valueField="id"
              placeholder={
                maskData.isError
                  ? 'Failed to load companies!'
                  : 'Select Company'
              }
              isDisabled={maskData.isError}
              isLoading={maskData.isFetching}
              clearable={true}
              filter={true}
              resetFilterOnHide={true}
              rules={{
                required: {
                  value: true,
                  message: 'Mask is a required field',
                },
              }}
            />
          </div>
          <div className="col-12 sm:col-6" />
        </React.Fragment>
      )}
    </div>
  );
};

export default CompanyForm;
