import { reportsPerSection } from '../../../utils/rolesConst';
import { useCookies } from 'react-cookie';
import Navbar from '../../components/navbar/Navbar';
import { useCompanyContext } from '../../context/CompanyContext';
import { Company, CompanySource } from '../../Interfaces/User.interfaces';
import { getSource } from 'apps/tmr-frontend/src/utils/company';
import { useEffect, useState } from 'react';
import { navOption } from '../../Interfaces/NavBar.interfaces';

const options = [
  {
    label: 'Accounting Dashboard',
    url: '/accounting-dashboard',
    companySources: [CompanySource.ViewPoint, CompanySource.Yardi],
  },
  {
    label: 'Cash Management',
    url: 'cash-management',
    reports: reportsPerSection['cash management'],
    companySources: [CompanySource.ViewPoint, CompanySource.Yardi],
  },
  {
    label: 'Accounts Payables',
    url: 'account-payables',
    reports: reportsPerSection['accounts payables'],
    companySources: [CompanySource.ViewPoint, CompanySource.Yardi],
  },
  {
    label: 'Accounts Receivables',
    url: 'accounts-receivables',
    reports: reportsPerSection['accounts receivables'],
    companySources: [CompanySource.ViewPoint, CompanySource.Yardi],
  },
  {
    label: 'Contract Billings',
    url: 'contract-billings',
    reports: reportsPerSection['contract billings'],
    companySources: [CompanySource.ViewPoint],
  },
  {
    label: 'Jobs & Contracts',
    url: 'jobs-contracts',
    reports: reportsPerSection['jobs & contracts'],
    companySources: [CompanySource.ViewPoint],
  },
  {
    label: 'Payroll',
    url: 'payroll',
    reports: reportsPerSection['payroll'],
    companySources: [CompanySource.ViewPoint],
  },
  {
    label: 'Subcontracts',
    url: 'subcontracts',
    reports: reportsPerSection['subcontracts'],
    companySources: [CompanySource.ViewPoint],
  },
  {
    label: 'Financial Statements',
    url: 'financialStatement',
    reports: reportsPerSection['financial statements'],
    companySources: [CompanySource.ViewPoint, CompanySource.Yardi],
  },
  {
    label: 'New',
    url: 'new',
    style: 'highlightOption p-2 text-white border-round-lg',
    reports: reportsPerSection['new'],
    companySources: [CompanySource.ViewPoint],
  },
];

const filterOptions = (options: navOption[], selectedCompany?: Company) => {
  const availableOptions = options.filter((opt) => {
    if (!selectedCompany) return false;
    const source = getSource(selectedCompany);
    if (!source) return false;

    return opt.companySources.includes(source);
  });

  return availableOptions;
};

const TabsNavBar = () => {
  const { selectedCompany } = useCompanyContext();
  const [tabOptions, setTabOptions] = useState(
    filterOptions(options, selectedCompany)
  );
  const cookiesMethods = useCookies(['filters']);

  const onOptionClick = () => {
    const remove = cookiesMethods[2];
    remove('filters', { path: '/accounting-dashboard' });
  };

  useEffect(() => {
    setTabOptions(filterOptions(options, selectedCompany));
  }, [selectedCompany]);

  return <Navbar options={tabOptions} onOptionClick={onOptionClick} />;
};

export default TabsNavBar;
