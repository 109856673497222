import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import {
  BatchType,
  DraftBatchPayment,
  Payable,
  PaymentBatch,
  PerJobPayableType,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import APDrilldown from '../APDrilldown/APDrilldown';
import {
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import PaymentBatchCheckbox from '../APTables/PaymentBatchProcess/PaymentBatchCheckbox';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import {
  buildDefaultValuesByJob,
  comparePayableWithPayment,
  comparePayments,
  transformPayableToPayment,
} from '../apUtil';
import PaymentBatchProcess from '../APTables/PaymentBatchProcess/PaymentBatchProcess';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';

export type APOpenPerJobTableProps = {
  accountsPayables: Payable[];
  drilldownStyles?: boolean;
  showRetainage?: boolean;
  showOpenAmount?: boolean;
  createBatch?: boolean;
  changeCreateBatch?: (data: boolean) => void;
  draftID?: PaymentBatch['id'];
  defaultBatchPayables?: DraftBatchPayment[];
};

const APOpenPerJobTable = React.forwardRef<
  HTMLDivElement,
  APOpenPerJobTableProps
>(
  (
    {
      accountsPayables,
      drilldownStyles,
      showRetainage,
      showOpenAmount,
      ...batch
    },
    ref
  ) => {
    const { rolesAcess } = useRolesAccessContext();
    const tableClass = drilldownStyles ? 'mt-5' : 'mx-3 dashboardOptionShadow';
    const columnHeaderClass = drilldownStyles
      ? 'DrilldownHeader'
      : 'tableHeader';
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [selectedPayable, setSelectedPayable] = useState<Payable>();
    const access = rolesAcess?.find(
      (access) => access.report === 'create_payment_batch'
    );
    const defaults = useMemo(
      () =>
        buildDefaultValuesByJob(
          accountsPayables,
          batch.defaultBatchPayables ?? []
        ),
      [accountsPayables, batch.defaultBatchPayables]
    );
    const [defaultValues, setDefaultValues] = useState({
      ...defaults,
      draftID: batch.draftID,
    });
    const [selectedPayables, setSelectedPayables] = useState<
      DraftBatchPayment[]
    >(defaultValues.selectedPayables);
    const [openAmountTotal, setOpenAmountTotal] = useState(
      defaultValues.openAmountTotal
    );
    const [retainageTotal, setRetainageTotal] = useState(
      defaultValues.retainageTotal
    );
    const toast = useRef<Toast>(null);

    const calcHeight = (rows: number) => {
      const headerHeight = 32;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const onRowClick = (e: DataTableRowClickEventParams) => {
      if (e.data.Reference) {
        setSelectedPayable(e.data);
        setShowDrilldown(true);
      }
    };

    const rowClassName = (data: Payable) => {
      let className = data.Reference ? 'cursor-pointer' : '';
      if (!data.Reference) {
        className = `${className} font-bold surface-300`;
      }

      return className;
    };

    const removePayable = (paymentToRemove: DraftBatchPayment) => {
      const newList = selectedPayables.filter(
        (payment) =>
          !comparePayments(paymentToRemove, payment, BatchType.job) &&
          !(
            payment.job === paymentToRemove.job &&
            !payment.apref &&
            payment.type === paymentToRemove.type
          )
      );
      setSelectedPayables(newList);

      switch (paymentToRemove.type) {
        case PerJobPayableType.amount:
          setOpenAmountTotal((amount) => amount - paymentToRemove.amount);
          return;
        case PerJobPayableType.retainage:
          setRetainageTotal((amount) => amount - paymentToRemove.retainage);
          return;
        default:
          return;
      }
    };

    const removeJobFromDraft = (payment: DraftBatchPayment) => {
      setSelectedPayables((currentList) => {
        let total = 0;

        const newList = currentList.filter((current) => {
          if (current.job === payment.job && current.type === payment.type) {
            if (current.apref) {
              switch (current.type) {
                case PerJobPayableType.amount:
                  total += current.amount;
                  break;
                case PerJobPayableType.retainage:
                  total += current.retainage;
                  break;
                default:
                  break;
              }
            }

            return false;
          }

          return true;
        });

        switch (payment.type) {
          case PerJobPayableType.amount:
            setOpenAmountTotal((current) => current - total);
            break;
          case PerJobPayableType.retainage:
            setRetainageTotal((amount) => amount - total);
            break;
          default:
            break;
        }

        return newList;
      });
    };

    const addPayable = (
      payable: DraftBatchPayment,
      jobAmount: number,
      jobTotal: DraftBatchPayment
    ) => {
      setSelectedPayables((list) => {
        const currentJobAmount = list.filter(
          (current) =>
            current.job === payable.job && current.type === payable.type
        ).length;
        const newList = [...list, payable];

        if (currentJobAmount === jobAmount) {
          newList.push(jobTotal);
        }

        return newList;
      });

      switch (payable.type) {
        case PerJobPayableType.amount:
          setOpenAmountTotal((amount) => amount + payable.amount);
          return;
        case PerJobPayableType.retainage:
          setRetainageTotal((amount) => amount + payable.retainage);
          return;
        default:
          return;
      }
    };

    const addJobToBatch = (
      payment: DraftBatchPayment,
      allPayables: Payable[]
    ) => {
      setSelectedPayables((payments) => {
        const currentJobJson = payments.reduce((acc, current) => {
          if (current.job === payment.job && payment.type === current.type) {
            acc[
              `${current.vendor}-${current.apref}-${current.job}-${current.type}`
            ] = true;
          }

          return acc;
        }, {} as Record<string, boolean>);
        const jobPayments: DraftBatchPayment[] = [];
        let total = 0;

        allPayables.forEach((value: Payable) => {
          if (
            value.Job === payment.job &&
            value.Reference &&
            !currentJobJson[
              `${value.VendorCode}-${value.Reference}-${value.Job}-${payment.type}`
            ] &&
            ((payment.type === PerJobPayableType.amount &&
              !value.BatchDisabled) ||
              (payment.type === PerJobPayableType.retainage &&
                !value.RetainageBatchDisabled))
          ) {
            const jobPayment = transformPayableToPayment(value);
            jobPayment.type = payment.type;
            jobPayments.push(jobPayment);

            switch (payment.type) {
              case PerJobPayableType.amount:
                total += jobPayment.amount;
                break;
              case PerJobPayableType.retainage:
                total += jobPayment.retainage;
                break;
              default:
                break;
            }
          }
        });

        switch (payment.type) {
          case PerJobPayableType.amount:
            setOpenAmountTotal((current) => current + total);
            break;
          case PerJobPayableType.retainage:
            setRetainageTotal((amount) => amount + total);
            break;
          default:
            break;
        }

        return [...payments, ...jobPayments, payment];
      });
    };

    const afterSaving = (draftID: string) => {
      setDefaultValues({
        selectedPayables,
        openAmountTotal,
        draftID,
        retainageTotal,
        hasOutdated: false,
      });
    };

    const afterSubmitting = () => {
      selectedPayables.forEach((payment) => {
        const openPayable = accountsPayables.find(
          (payable) =>
            comparePayableWithPayment(payable, payment, BatchType.job) ||
            (!payment.apref &&
              !payable.Reference &&
              payment.job === payable.Job)
        );

        if (!openPayable) {
          return;
        }

        switch (payment.type) {
          case PerJobPayableType.amount:
            openPayable.BatchDisabled = true;
            return;
          case PerJobPayableType.retainage:
            openPayable.RetainageBatchDisabled = true;
            return;
          default:
            return;
        }
      });

      setOpenAmountTotal(0);
      setRetainageTotal(0);
      setSelectedPayables([]);
      setDefaultValues({
        selectedPayables: [],
        openAmountTotal: 0,
        retainageTotal: 0,
        draftID: undefined,
        hasOutdated: false,
      });

      if (batch.changeCreateBatch) {
        batch.changeCreateBatch(false);
      }

      showToast(
        'success',
        toast,
        'Submit Payment Batch',
        'The data was submitted successfully!',
        3000
      );
    };

    const cleanDraft = () => {
      setOpenAmountTotal(0);
      setRetainageTotal(0);
      setSelectedPayables([]);
    };

    useEffect(() => {
      if (!batch.createBatch && access) {
        setSelectedPayables(defaultValues.selectedPayables);
        setOpenAmountTotal(defaultValues.openAmountTotal);
        setRetainageTotal(defaultValues.retainageTotal);
      }
    }, [batch.createBatch, batch.defaultBatchPayables, defaultValues]);

    useEffect(() => {
      if (defaultValues.hasOutdated && batch.createBatch) {
        showToast(
          'warn',
          toast,
          'Payment Batch',
          'The draft has some payables already in a batch!',
          3000
        );
      }
    }, [defaultValues.hasOutdated, batch.createBatch]);

    let amountTotal = 0;
    let originalTotal = 0;
    let discTotal = 0;
    let retainage = 0;
    accountsPayables.forEach((payable: Payable) => {
      if (payable.Reference) {
        amountTotal = amountTotal + payable.NetAmount;
        originalTotal = originalTotal + payable.Gross;
        discTotal = (payable.DiscountOffered ?? 0) + discTotal;
        retainage += payable.Retainage ?? 0;
      }
    });

    return (
      <div>
        {selectedPayable && (
          <APDrilldown
            visible={showDrilldown}
            setVisible={setShowDrilldown}
            payable={selectedPayable}
          />
        )}
        <Table
          id="open-per-job-table"
          ref={ref}
          data={accountsPayables}
          className={tableClass}
          calcHeight={useCallback(calcHeight, [])}
          onRowClick={useCallback(onRowClick, [])}
          rowClassName={useCallback(rowClassName, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          selection={selectedPayables}
          stripedRows={!drilldownStyles}
          hideColumns={true}
          nonHideableColumns={['Select']}
        >
          <Column
            headerClassName={columnHeaderClass}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter"
          />
          {!drilldownStyles && (
            <Column
              field="Job"
              header="Job #"
              style={{ minWidth: '150px' }}
              headerClassName={`${columnHeaderClass} font-normal`}
              className="justify-content-center text-standard blackText tableCell"
              footerClassName="tableFooter"
              sortable
            />
          )}
          {!drilldownStyles && (
            <Column
              field="Description"
              header="Job Name"
              body={(ap) => <div className="scroll-text">{ap.Description}</div>}
              style={{ minWidth: '240px' }}
              headerClassName={`${columnHeaderClass} font-normal`}
              className=" text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
              footerClassName="tableFooter"
            />
          )}
          <Column
            field="Vendor"
            header="Vendor"
            body={(ap) => <div className="scroll-text">{ap.Vendor}</div>}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center`}
            style={{ minWidth: '280px', maxWidth: '280px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '150px' }}
            body={(ap) => formatUTCDate(ap.InvoiceDate)}
            headerClassName={`${columnHeaderClass} font-normal`}
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Reference"
            header="Inv. Number"
            style={{ minWidth: '180px' }}
            headerClassName={`${columnHeaderClass} font-normal`}
            className="justify-content-center text-standard blackText tableCell"
            footer="Total"
            footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
            sortable
          />
          <Column
            field="Gross"
            header={'Original Amount'}
            style={{ minWidth: '150px' }}
            body={(ap) => formatCurrency(ap.Gross)}
            headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center white-space-nowrap`}
            className="justify-content-end text-standard blackText tableCell"
            footer={originalTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          {showOpenAmount && (
            <Column
              field="DiscountOffered"
              header="Discount"
              style={{ minWidth: '120px' }}
              body={(ap) => formatCurrency(ap.DiscountOffered)}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
              className="justify-content-end text-standard blackText tableCell"
              footer={discTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              footerClassName="tableFooter block text-right border-top-2 mt-4"
              sortable
            />
          )}
          {showOpenAmount && (
            <Column
              field="NetAmount"
              header={'Open Amount'}
              style={{ minWidth: '170px' }}
              body={(ap) => formatCurrency(ap.NetAmount)}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
              className="justify-content-end text-standard blackText tableCell"
              footer={amountTotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              footerClassName="tableFooter block text-right border-top-2 mt-4"
              sortable
            />
          )}
          {batch.createBatch && access && (
            <Column
              header="Select"
              body={(ap, options) => {
                if (ap.BatchDisabled) {
                  return;
                }

                return (
                  <PaymentBatchCheckbox
                    isSelected={selectedPayables.some(
                      (payment) =>
                        payment.type === PerJobPayableType.amount &&
                        comparePayableWithPayment(ap, payment, BatchType.job)
                    )}
                    openPayable={ap}
                    allPayables={options.props.value}
                    addPayableToBatch={(payment) => {
                      payment.type = PerJobPayableType.amount;
                      const jobList = options.props.value.filter(
                        (value: Payable) => {
                          return (
                            payment.job === value.Job && !value.BatchDisabled
                          );
                        }
                      );
                      const jobTotalRow = jobList[jobList.length - 1];
                      const jobPayment = transformPayableToPayment(jobTotalRow);
                      jobPayment.type = PerJobPayableType.amount;

                      addPayable(payment, jobList.length - 2, jobPayment);
                    }}
                    removePayableFromBatch={(payment) => {
                      payment.type = PerJobPayableType.amount;
                      removePayable(payment);
                    }}
                    addTotalToBatch={(payment, allPayables) => {
                      payment.type = PerJobPayableType.amount;
                      addJobToBatch(payment, allPayables);
                    }}
                    removeTotalFromBatch={(payment) => {
                      payment.type = PerJobPayableType.amount;
                      removeJobFromDraft(payment);
                    }}
                  />
                );
              }}
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName={`tableHeader font-normal`}
              className={`justify-content-center text-standard blackText tableCell p-0 printHide`}
              footerClassName="tableFooter"
            />
          )}
          {showRetainage && (
            <Column
              field="Retainage"
              header={'Open Retainage'}
              style={{ minWidth: '180px' }}
              body={(ap) => formatCurrency(ap.Retainage)}
              headerClassName={`${columnHeaderClass} font-normal justify-content-center text-center`}
              className="justify-content-end text-standard blackText tableCell"
              footer={formatCurrency(retainage)}
              footerClassName="tableFooter block text-right border-top-2 mt-4"
              sortable
            />
          )}
          {batch.createBatch && access && (
            <Column
              header="Select"
              body={(ap, options) => {
                if (ap.RetainageBatchDisabled) {
                  return;
                }

                return (
                  <PaymentBatchCheckbox
                    isSelected={selectedPayables.some(
                      (payment) =>
                        payment.type === PerJobPayableType.retainage &&
                        comparePayableWithPayment(ap, payment, BatchType.job)
                    )}
                    openPayable={ap}
                    allPayables={options.props.value}
                    addPayableToBatch={(payment) => {
                      payment.type = PerJobPayableType.retainage;
                      const jobList = options.props.value.filter(
                        (value: Payable) => {
                          return (
                            payment.job === value.Job &&
                            !value.RetainageBatchDisabled
                          );
                        }
                      );
                      const jobTotalRow = jobList[jobList.length - 1];
                      const jobPayment = transformPayableToPayment(jobTotalRow);
                      jobPayment.type = PerJobPayableType.retainage;

                      addPayable(payment, jobList.length - 2, jobPayment);
                    }}
                    removePayableFromBatch={(payment) => {
                      payment.type = PerJobPayableType.retainage;
                      removePayable(payment);
                    }}
                    addTotalToBatch={(payment, allPayables) => {
                      payment.type = PerJobPayableType.retainage;
                      addJobToBatch(payment, allPayables);
                    }}
                    removeTotalFromBatch={(payment) => {
                      payment.type = PerJobPayableType.retainage;
                      removeJobFromDraft(payment);
                    }}
                  />
                );
              }}
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName={`tableHeader font-normal`}
              className={`justify-content-center text-standard blackText tableCell p-0 printHide`}
              footerClassName="tableFooter"
            />
          )}
          <Column
            headerClassName={columnHeaderClass}
            className="tableCell p-0 printHide"
            footerClassName="tableFooter"
          />
        </Table>
        <ErrorToast toastRef={toast} />
        {batch.createBatch && access && (
          <div className="w-full px-3 mr-3 my-5 text-standard printHide">
            <div className="ml-auto w-24rem my-5">
              <div className="flex justify-content-between">
                <span>Open Amount Total:</span>
                <span id="openAmountTotal">
                  {formatCurrency(openAmountTotal)}
                </span>
              </div>
              <div className="flex justify-content-between mt-2">
                <span>Retainage Total:</span>
                <span id="retainageTotal">
                  {formatCurrency(retainageTotal)}
                </span>
              </div>
              <div className="flex justify-content-between mt-1 border-top-1 border-bottom-2">
                <span className="font-bold">Payment Batch Total</span>
                <span id="batchTotal">
                  {formatCurrency(openAmountTotal + retainageTotal)}
                </span>
              </div>
            </div>
            <div className="mt-3 flex flex-wrap gap-4 justify-content-center printHide">
              <PaymentBatchProcess
                batch={selectedPayables}
                afterSubmitting={afterSubmitting}
                afterSaving={afterSaving}
                defaultDraftID={defaultValues.draftID}
                type={BatchType.job}
                resetDraft={cleanDraft}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<APOpenPerJobTableProps>,
  nextTransactions: Readonly<APOpenPerJobTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APOpenPerJobTable, transactionsAreEqual);
