import React, { useEffect, useRef } from 'react';
import ARAgedTableContainer from '../../features/accountsReceivables/ARAged/ARAgedTableContainer';
import ARResumeContainer from '../../features/accountsReceivables/ARResume/ARResumeContainer';
import ARFilters, {
  ARFiltersArgs,
  RECEIVABLES_OPTIONS,
} from '../../features/accountsReceivables/ARFilter/ARFilters';
import { FormProvider, useForm } from 'react-hook-form';
import CBRTableContainer from '../../features/accountsReceivables/ContractBillsAndReceipts/CBRTableContainer';
import ARCustomerTableContainer from '../../features/accountsReceivables/ARCustomer/ARCustomerTableContainer';
import ARReceiptTableContainer from '../../features/accountsReceivables/ARReceipts/ARReceiptTableContainer';
import ARReportHeader from '../../features/accountsReceivables/ARReport/ARReportHeader';
import ARReport from '../../features/accountsReceivables/ARReport/ARReport';
import checkPermissions from '../../HOCs/CheckPermissions';
import ARParams from '../../features/accountsReceivables/ARParams';
import useProtectedReportOptions from '../../hooks/useProtectedReportOptions';
import ReportSelector from '../../components/report-selector/ReportSelector';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { reportsPerSection } from '../../../../src/utils/rolesConst';
import DashboardGeneralLedgerReceivable from '../../features/accounting-dashboard/DashboardGeneralLedgerReceivable';
import { CompanySource } from '../../Interfaces/User.interfaces';
import { useCompanyContext } from '../../context/CompanyContext';
import ARFiltersYardi from '../../features/accountsReceivables/ARFilter/ARFiltersYardi';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

const AccountsReceivables = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const methods = useForm<ARFiltersArgs>();
  const { watch, setValue, resetField } = methods;
  const reportType = watch('reportType');
  const { selectedCompany } = useCompanyContext();

  const [protectedReports] = useProtectedReportOptions({
    reports: RECEIVABLES_OPTIONS,
  });

  const ARSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['accounts receivables'],
    Children: ARResumeContainer,
  });

  const Aged = checkPermissions({
    sectionsToAccess: ['aged_analysis'],
    Children: ARAgedTableContainer,
  });
  const Receipts = checkPermissions({
    sectionsToAccess: ['receipts_history'],
    Children: ARReceiptTableContainer,
  });
  const BillReceipts = checkPermissions({
    sectionsToAccess: ['contract_billings_and_receipts'],
    Children: CBRTableContainer,
  });
  const Customer = checkPermissions({
    sectionsToAccess: ['customer_list'],
    Children: ARCustomerTableContainer,
  });

  const handleReportChange = () => {
    if (reportType !== 'agedAnalysis') {
      setValue('period', undefined);
    }
    resetField('customers');
    resetField('dates');
    resetField('info');
    resetField('contracts');
  };

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      resetField('period', { defaultValue: null });
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-3 flex gap-2 flex-wrap justify-content-end">
          {getSource(selectedCompany) === CompanySource.ViewPoint && <ARSummary />}
          {getSource(selectedCompany) === CompanySource.Yardi && (
            <DashboardGeneralLedgerReceivable />
          )}
        </div>
        <div className="flex flex-column  gap-2 mx-3  mt-3">
          <div className="text-standard mb-1">Select Report</div>
          <div className="flex flex-row gap-2 justify-content-between">
            <ReportSelector
              protectedReports={protectedReports}
              onChange={() => {
                handleReportChange();
              }}
            />
            <div className="flex w-full justify-content-between  gap-2">
              <FiltersMenu>
                {getSource(selectedCompany)== CompanySource.ViewPoint ? (
                  <ARFilters />
                ) : (
                  <ARFiltersYardi />
                )}
              </FiltersMenu>
              <ARReport elementRef={tableRef} />
            </div>
          </div>
        </div>

        <div className="mb-3 printBackground pdfDivFitContent" ref={tableRef}>
          <ARReportHeader />
          {reportType === 'agedAnalysis' && <Aged />}
          {reportType === 'receiptsHistory' && <Receipts />}
          {reportType === 'contractBAR' && <BillReceipts />}
          {reportType === 'customerList' && <Customer />}
        </div>
        <ARParams />
      </FormProvider>
    </div>
  );
};

export default AccountsReceivables;
