import {
  AgedPayable,
  Payable,
  DraftBatchPayment,
  BatchType,
  PerJobPayableType,
} from '../../Interfaces/Accounting/AccountsPayables.interfaces';
import { Company, CompanySource } from '../../Interfaces/User.interfaces';

import {
  fetchOpenVendors,
  fetchUnapprovedVendors,
  fetchVendors,
} from '../../services/vendorsService';

export function getVendorFetch(
  reportType: string,
  company: Company,
  signal: AbortSignal | undefined
) {
  switch (reportType) {
    case 'openPayables':
      return fetchOpenVendors(company, signal);
    case 'agedPayables':
      return fetchOpenVendors(company, signal);
    case 'unapprovedInvoices':
      return fetchUnapprovedVendors(company, signal);
    case 'openPayablesPerJob':
      return fetchOpenVendors(company, signal, true);
    default:
      return fetchVendors({ company }, signal);
  }
}

export function processPayableList(data: Payable[]) {
  const newData: Payable[] = [];
  let currentVendor = data[0]?.VendorCode;
  let currentVendorName = data[0]?.Vendor;
  let acumulator = {
    gross: 0,
    amount: 0,
    tax: 0,
    discount: 0,
    net: 0,
    batchDisabled: true,
    retainage: 0,
  };

  data.forEach((payable: Payable, idx: number) => {
    if (currentVendor === payable.VendorCode) {
      acumulator.gross += payable.Gross;
      acumulator.amount += payable.PayableAmount ?? 0;
      acumulator.tax += payable.Tax;
      acumulator.discount += payable.DiscountOffered ?? 0;
      acumulator.net += payable.NetAmount;
      acumulator.retainage += payable.Retainage ?? 0;
      acumulator.batchDisabled =
        acumulator.batchDisabled && !!payable.BatchDisabled;
    } else {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Gross: acumulator.gross,
        PayableAmount: acumulator.amount,
        Tax: acumulator.tax,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
        BatchDisabled: acumulator.batchDisabled,
        Retainage: acumulator.retainage,
      } as Payable);

      currentVendor = payable.VendorCode;
      currentVendorName = payable.Vendor;
      acumulator = {
        gross: payable.Gross,
        amount: payable.PayableAmount ?? 0,
        tax: payable.Tax,
        discount: payable.DiscountOffered ?? 0,
        net: payable.NetAmount,
        batchDisabled: !!payable.BatchDisabled,
        retainage: payable.Retainage ?? 0,
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Gross: acumulator.gross,
        PayableAmount: acumulator.amount,
        Tax: acumulator.tax,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
        BatchDisabled: acumulator.batchDisabled,
        Retainage: acumulator.retainage,
      } as Payable);
    }
  });

  return newData;
}

export function processPayableListPerJob(data: Payable[]) {
  const newData: Payable[] = [];
  let currentJob = data[0]?.Job;
  let currentJobName = data[0]?.Description;
  let acumulator = {
    gross: 0,
    discount: 0,
    net: 0,
    retainage: 0,
    batchDisabled: true,
    retainageBatchDisabled: true,
  };

  data.forEach((payable: Payable, idx: number) => {
    if (currentJob === payable.Job) {
      acumulator.gross += payable.Gross;
      acumulator.retainage += payable.Retainage ?? 0;
      acumulator.discount += payable.DiscountOffered ?? 0;
      acumulator.net += payable.NetAmount;
      acumulator.batchDisabled =
        acumulator.batchDisabled && !!payable.BatchDisabled;
      acumulator.retainageBatchDisabled =
        acumulator.retainageBatchDisabled && !!payable.RetainageBatchDisabled;
    } else {
      newData.push({
        Job: currentJob,
        Description: currentJobName,
        Vendor: 'Job Total:',
        Gross: acumulator.gross,
        Retainage: acumulator.retainage,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
        BatchDisabled: acumulator.batchDisabled,
        RetainageBatchDisabled: acumulator.retainageBatchDisabled,
      } as Payable);

      currentJob = payable.Job;
      currentJobName = payable.Description;
      acumulator = {
        gross: payable.Gross,
        retainage: payable.Retainage ?? 0,
        discount: payable.DiscountOffered ?? 0,
        net: payable.NetAmount,
        batchDisabled: !!payable.BatchDisabled,
        retainageBatchDisabled: !!payable.RetainageBatchDisabled,
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Job: currentJob,
        Description: currentJobName,
        Vendor: 'Job Total:',
        Gross: acumulator.gross,
        Retainage: acumulator.retainage,
        DiscountOffered: acumulator.discount,
        NetAmount: acumulator.net,
        BatchDisabled: acumulator.batchDisabled,
        RetainageBatchDisabled: acumulator.retainageBatchDisabled,
      } as Payable);
    }
  });

  return newData;
}

export function processAgedPayablePerVendor(data: AgedPayable[]) {
  const newData: AgedPayable[] = [];
  let currentVendor = data[0]?.VendorCode;
  let currentVendorName = data[0]?.Vendor;
  let acumulator = { current: 0, over30: 0, over60: 0, over90: 0, holdRet: 0 };

  data.forEach((payable, idx: number) => {
    if (currentVendor === payable.VendorCode) {
      acumulator.current += payable.Current;
      acumulator.over30 += payable.Over30;
      acumulator.over60 += payable.Over60;
      acumulator.over90 += payable.Over90;
      acumulator.holdRet += payable.HoldRet;
    } else {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Current: acumulator.current,
        Over30: acumulator.over30,
        Over60: acumulator.over60,
        Over90: acumulator.over90,
        HoldRet: acumulator.holdRet,
      } as AgedPayable);

      currentVendor = payable.VendorCode;
      currentVendorName = payable.Vendor;
      acumulator = {
        current: payable.Current,
        over30: payable.Over30,
        over60: payable.Over60,
        over90: payable.Over90,
        holdRet: payable.HoldRet,
      };
    }

    newData.push(payable);

    if (idx + 1 === data.length) {
      newData.push({
        Vendor: currentVendorName,
        VendorCode: currentVendor,
        Description: 'Vendor Total:',
        Current: acumulator.current,
        Over30: acumulator.over30,
        Over60: acumulator.over60,
        Over90: acumulator.over90,
        HoldRet: acumulator.holdRet,
      } as AgedPayable);
    }
  });

  return newData;
}

export const getVendorKeyPerCompany = (companySource?: CompanySource) => {
  if (companySource === CompanySource.ViewPoint) {
    return 'code';
  }

  return 'id';
};

export const buildDefaultBatch = (
  payables: Payable[],
  batchPayments: DraftBatchPayment[]
) => {
  const vendorTotals = [];
  const vendorCounter: Record<number, number> = {};
  let hasOutdated = false;

  const batchJson = batchPayments.reduce((acc, payment) => {
    const amount = vendorCounter[payment.vendor ?? 0] ?? 0;
    vendorCounter[payment.vendor ?? 0] = amount + 1;

    acc[`${payment.vendor}-${payment.apref}-${payment.payType}`] = payment;

    return acc;
  }, {} as Record<string, DraftBatchPayment>);

  let currentVendor = payables[0]?.VendorCode;
  const vendorDisabledCounter: Record<number, number> = {};
  const vendorPayablesCounter: Record<number, number> = {};

  for (const payable of payables) {
    const payableVendor = payable.VendorCode ?? 0;
    const totalPayables = vendorPayablesCounter[payableVendor] ?? 0;
    const disabledPayables = vendorDisabledCounter[payableVendor] ?? 0;

    if (!payable.Reference) {
      const payablesInBatch = disabledPayables + vendorCounter[payableVendor];
      if (totalPayables === payablesInBatch) {
        vendorTotals.push(transformPayableToPayment(payable));
      }
    } else {
      const batchKey = `${payable.VendorCode}-${payable.Reference}-${payable.PayType}`;
      if (batchJson[batchKey]) {
        hasOutdated = hasOutdated || !!payable.BatchDisabled;
        delete batchJson[batchKey];
        vendorTotals.push(transformPayableToPayment(payable));
      }

      if (currentVendor === payableVendor) {
        vendorPayablesCounter[payableVendor] = totalPayables + 1;
        vendorDisabledCounter[payableVendor] =
          disabledPayables + (payable.BatchDisabled ? 1 : 0);
      } else {
        currentVendor = payableVendor;
        vendorPayablesCounter[payableVendor] = 1;
        vendorDisabledCounter[payableVendor] = payable.BatchDisabled ? 1 : 0;
      }
    }
  }

  Object.values(batchJson).forEach((val) => {
    delete val.job;
    vendorTotals.push(val);
  });

  return { vendorTotals, hasOutdated };
};

export const buildDefaultBatchValues = (
  accountsPayables: Payable[],
  batchPayments: DraftBatchPayment[]
) => {
  const { vendorTotals, hasOutdated } = buildDefaultBatch(
    accountsPayables,
    batchPayments ?? []
  );

  const totals = batchPayments?.reduce(
    (acc, payment) => {
      const openPayable = accountsPayables.find((payable) => {
        return comparePayableWithPayment(payable, payment, BatchType.payType);
      });

      if (!openPayable) {
        return acc;
      }

      if (openPayable.PayType === 5) {
        acc.retainage += openPayable.Retainage ?? 0;
      } else {
        acc.open += openPayable.NetAmount;
      }

      return acc;
    },
    { open: 0, retainage: 0 }
  ) ?? { open: 0, retainage: 0 };

  return {
    selectedPayables: vendorTotals,
    hasOutdated,
    openAmountTotal: totals.open,
    retainageTotal: totals.retainage,
  };
};

export const buildDefaultBatchByJob = (
  payables: Payable[],
  batchPayments: DraftBatchPayment[]
) => {
  const jobTotals = [];
  const jobCounter: Record<string, number> = {};
  let hasOutdated = false;

  const batchJson = batchPayments.reduce((acc, payment) => {
    const amount = jobCounter[`${payment.job}-${payment.type}`] ?? 0;
    jobCounter[`${payment.job}-${payment.type}`] = amount + 1;

    acc[`${payment.vendor}-${payment.apref}-${payment.job}-${payment.type}`] =
      payment;

    return acc;
  }, {} as Record<string, DraftBatchPayment>);

  let currentJob = payables[0]?.Job;
  const jobDisabledCounter: Record<string, number> = {};
  const jobPayablesCounter: Record<string, number> = {};

  for (const payable of payables) {
    const payableJob = payable.Job ?? '';
    const retkey = `${payableJob}-${PerJobPayableType.retainage}`;
    const amountKey = `${payableJob}-${PerJobPayableType.amount}`;
    const totalPayables = jobPayablesCounter[payableJob] ?? 0;
    const disabledPayables = jobDisabledCounter[amountKey] ?? 0;
    const disabledRetPayables = jobDisabledCounter[retkey] ?? 0;

    if (!payable.Reference) {
      const payablesInBatch = disabledPayables + jobCounter[amountKey];
      const payablesInBatchRet = disabledRetPayables + jobCounter[retkey];
      if (totalPayables === payablesInBatch) {
        const payment = transformPayableToPayment(payable);
        payment.type = PerJobPayableType.amount;
        jobTotals.push(payment);
      }

      if (totalPayables === payablesInBatchRet) {
        const payment = transformPayableToPayment(payable);
        payment.type = PerJobPayableType.retainage;
        jobTotals.push(payment);
      }
    } else {
      const batchKey = `${payable.VendorCode}-${payable.Reference}-${payable.Job}`;
      if (batchJson[`${batchKey}-${PerJobPayableType.amount}`]) {
        delete batchJson[`${batchKey}-${PerJobPayableType.amount}`];
        const payment = transformPayableToPayment(payable);
        payment.type = PerJobPayableType.amount;
        jobTotals.push(payment);
        hasOutdated = hasOutdated || !!payable.BatchDisabled;
      }

      if (batchJson[`${batchKey}-${PerJobPayableType.retainage}`]) {
        delete batchJson[`${batchKey}-${PerJobPayableType.retainage}`];
        const payment = transformPayableToPayment(payable);
        payment.type = PerJobPayableType.retainage;
        jobTotals.push(payment);
        hasOutdated = hasOutdated || !!payable.RetainageBatchDisabled;
      }

      if (currentJob === payableJob) {
        jobPayablesCounter[payableJob] = totalPayables + 1;
        jobDisabledCounter[amountKey] =
          disabledPayables + (payable.BatchDisabled ? 1 : 0);
        jobDisabledCounter[retkey] =
          disabledRetPayables + (payable.RetainageBatchDisabled ? 1 : 0);
      } else {
        currentJob = payableJob;
        jobPayablesCounter[payableJob] = 1;
        jobDisabledCounter[amountKey] = payable.BatchDisabled ? 1 : 0;
        jobDisabledCounter[retkey] = payable.RetainageBatchDisabled ? 1 : 0;
      }
    }
  }

  Object.values(batchJson).forEach((val) => {
    delete val.vendor;
    jobTotals.push(val);
  });

  return { jobTotals, hasOutdated };
};

export const buildDefaultValuesByJob = (
  accountsPayables: Payable[],
  defaultBatchPayables?: DraftBatchPayment[]
) => {
  const { jobTotals, hasOutdated } = buildDefaultBatchByJob(
    accountsPayables,
    defaultBatchPayables ?? []
  );

  const amounts = defaultBatchPayables?.reduce(
    (acc, payment) => {
      const openPayable = accountsPayables.find((payable) => {
        return comparePayableWithPayment(payable, payment, BatchType.job);
      });

      if (!openPayable) {
        return acc;
      }

      switch (payment.type) {
        case PerJobPayableType.amount:
          acc.open += openPayable.NetAmount;
          break;
        case PerJobPayableType.retainage:
          acc.retainage += openPayable.Retainage ?? 0;
          break;
        default:
          break;
      }

      return acc;
    },
    { open: 0, retainage: 0 }
  ) ?? { open: 0, retainage: 0 };

  return {
    selectedPayables: jobTotals,
    openAmountTotal: amounts.open,
    retainageTotal: amounts.retainage,
    hasOutdated,
  };
};

export const transformPayableToPayment = (
  payable: Payable
): DraftBatchPayment => {
  const payment = {
    apref: payable.Reference,
    aptrans: payable.TransCode,
    vendor: payable.VendorCode,
    payType: payable.PayType,
    amount: payable.NetAmount,
    retainage: payable.Retainage ?? 0,
    job: payable.Job,
  };

  return payment;
};

const COMPARISON_PER_TYPE = {
  [BatchType.job]: (payable: Payable, batchPayment: DraftBatchPayment) =>
    batchPayment.job === payable.Job,
  [BatchType.payType]: (payable: Payable, batchPayment: DraftBatchPayment) =>
    batchPayment.payType === payable.PayType,
};

export const comparePayableWithPayment = (
  payable: Payable,
  batchPayment: DraftBatchPayment,
  type: BatchType
) => {
  const comparison = COMPARISON_PER_TYPE[type];

  return (
    batchPayment.apref === payable.Reference &&
    batchPayment.aptrans === payable.TransCode &&
    batchPayment.vendor === payable.VendorCode &&
    (comparison ? comparison(payable, batchPayment) : false)
  );
};

const PAYMENT_COMPARISON_PER_TYPE = {
  [BatchType.job]: (p1: DraftBatchPayment, p2: DraftBatchPayment) =>
    p1.job === p2.job && p1.type === p2.type,
  [BatchType.payType]: (p1: DraftBatchPayment, p2: DraftBatchPayment) =>
    p1.payType === p2.payType,
};

export const comparePayments = (
  batchPayment1: DraftBatchPayment,
  batchPayment2: DraftBatchPayment,
  type: BatchType
) => {
  const comparison = PAYMENT_COMPARISON_PER_TYPE[type];

  return (
    batchPayment1.apref === batchPayment2.apref &&
    batchPayment1.aptrans === batchPayment2.aptrans &&
    batchPayment1.vendor === batchPayment2.vendor &&
    (comparison ? comparison(batchPayment1, batchPayment2) : false)
  );
};
