import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { formatUTCDate, formatCurrency } from '../../../../utils/formatUtils';
import { nextSortState, sortByField } from '../../../../utils/sortUtils';
import {
  BatchType,
  DraftBatchPayment,
  Payable,
  PaymentBatch,
} from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import {
  DataTablePFSEvent,
  DataTableRowClickEventParams,
  DataTableRowMouseEventParams,
  DataTableSortOrderType,
} from 'primereact/datatable';
import APDrilldown from '../APDrilldown/APDrilldown';
import { animateCellText } from '../../../../utils/htmlUtils';
import PaymentBatchProcess from './PaymentBatchProcess/PaymentBatchProcess';
import { useRolesAccessContext } from '../../../context/RolesAccessContext';
import {
  comparePayableWithPayment,
  comparePayments,
  transformPayableToPayment,
  buildDefaultBatchValues,
} from '../apUtil';
import PaymentBatchCheckbox from './PaymentBatchProcess/PaymentBatchCheckbox';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { Toast } from 'primereact/toast';

export type APOpenTableProps = {
  accountsPayables: Payable[];
  isArchive?: boolean;
  createBatch?: boolean;
  changeCreateBatch?: (data: boolean) => void;
  draftID?: PaymentBatch['id'];
  defaultBatchPayables?: DraftBatchPayment[];
};

const APOpenTable = React.forwardRef<HTMLDivElement, APOpenTableProps>(
  (
    {
      accountsPayables,
      isArchive,
      createBatch,
      changeCreateBatch,
      draftID,
      defaultBatchPayables,
    },
    ref
  ) => {
    const { rolesAcess } = useRolesAccessContext();
    const access = rolesAcess?.find(
      (access) => access.report === 'create_payment_batch'
    );
    const [openPayables] = useState(accountsPayables);
    const defaults = useMemo(
      () =>
        buildDefaultBatchValues(accountsPayables, defaultBatchPayables ?? []),
      [accountsPayables, defaultBatchPayables]
    );
    const [defaultValues, setDefaultValues] = useState({
      ...defaults,
      draftID,
    });
    const [selectedPayables, setSelectedPayables] = useState<
      DraftBatchPayment[]
    >(defaultValues.selectedPayables);
    const [sortState, setSortState] = React.useState<{
      field: string;
      order: DataTableSortOrderType;
    }>({ field: '', order: null });
    const [sortedTransactions, setSortedTransactions] = React.useState([
      ...openPayables,
    ]);
    const [showDrilldown, setShowDrilldown] = useState(false);
    const [selectedPayable, setSelectedPayable] = useState<Payable>();
    const [openAmountTotal, setOpenAmountTotal] = useState(
      defaultValues.openAmountTotal
    );
    const [retainageTotal, setRetainageTotal] = useState(
      defaultValues.retainageTotal
    );
    const toast = useRef<Toast>(null);

    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 54;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    let payableAmountTotal = 0;
    let taxTotal = 0;
    let discTotal = 0;
    let gross = 0;
    openPayables.forEach((payable: Payable) => {
      if (payable.Reference) {
        payableAmountTotal += payable.PayableAmount ?? 0;
        taxTotal += payable.Tax ?? 0;
        discTotal += payable.DiscountOffered ?? 0;
        gross += payable.Gross;
      }
    });

    const onRowClick = (e: DataTableRowClickEventParams) => {
      if (e.data.Reference) {
        setSelectedPayable(e.data);
        setShowDrilldown(true);
      }
    };

    const rowClassName = (data: Payable) => {
      let className = data.Reference ? 'cursor-pointer' : '';
      if (!data.Reference) {
        className = `${className} font-bold surface-300`;
      }

      return className;
    };

    const handleSort = (event: DataTablePFSEvent) => {
      const { reset, field, order } = nextSortState(
        event.sortField,
        sortState.field
      );
      setSortState({ field, order });

      if (reset) {
        setSortedTransactions([...openPayables]);
        return;
      }

      const sortedData = [...openPayables].sort((a, b) => {
        switch (event.sortField) {
          case 'Net': {
            const aValue = a.NetAmount + (a.Retainage ?? 0);
            const bValue = b.NetAmount + (b.Retainage ?? 0);
            return (order ?? 0) * (aValue - bValue);
          }
          default: {
            const key = event.sortField as keyof Payable;
            const aValue = a[key];
            const bValue = b[key];
            return sortByField(aValue, bValue, order ?? 0);
          }
        }
      });

      setSortedTransactions(sortedData);
    };

    const removePayable = (paymentToRemove: DraftBatchPayment) => {
      const newList = selectedPayables.filter(
        (payment) =>
          !comparePayments(paymentToRemove, payment, BatchType.payType) &&
          !(payment.vendor === paymentToRemove.vendor && !payment.apref)
      );
      setSelectedPayables(newList);

      if (paymentToRemove.payType === 5) {
        setRetainageTotal((amount) => amount - paymentToRemove.retainage);
      } else {
        setOpenAmountTotal((amount) => amount - paymentToRemove.amount);
      }
    };

    const removeVendorFromDraft = (payment: DraftBatchPayment) => {
      setSelectedPayables((currentList) => {
        let open = 0;
        let retainage = 0;
        const newList = currentList.filter((current) => {
          if (current.vendor === payment.vendor) {
            if (current.payType === 5) {
              retainage += current.apref ? current.retainage : 0;
            } else {
              open += current.apref ? current.amount : 0;
            }

            return false;
          }

          return true;
        });

        setRetainageTotal((amount) => amount - retainage);
        setOpenAmountTotal((amount) => amount - open);

        return newList;
      });
    };

    const addPayable = (
      payable: DraftBatchPayment,
      vendorAmount: number,
      vendorTotal: DraftBatchPayment
    ) => {
      setSelectedPayables((list) => {
        const currentVendorAmount = list.filter(
          (current) => current.vendor === payable.vendor
        ).length;
        const newList = [...list, payable];

        if (currentVendorAmount === vendorAmount) {
          newList.push(vendorTotal);
        }

        return newList;
      });

      if (payable.payType === 5) {
        setRetainageTotal((amount) => amount + payable.retainage);
      } else {
        setOpenAmountTotal((amount) => amount + payable.amount);
      }
    };

    const addVendorToBatch = (
      payment: DraftBatchPayment,
      allPayables: Payable[]
    ) => {
      setSelectedPayables((payments) => {
        const currentVendorJson = payments.reduce((acc, current) => {
          if (current.vendor === payment.vendor) {
            acc[`${current.vendor}-${current.apref}-${current.payType}`] = true;
          }

          return acc;
        }, {} as Record<string, boolean>);
        const vendorPayments: DraftBatchPayment[] = [];
        let open = 0;
        let retainage = 0;

        allPayables.forEach((value: Payable) => {
          if (
            value.VendorCode === payment.vendor &&
            value.Reference &&
            !currentVendorJson[
              `${value.VendorCode}-${value.Reference}-${value.PayType}`
            ] &&
            !value.BatchDisabled
          ) {
            const vendorPayment = transformPayableToPayment(value);
            vendorPayments.push(vendorPayment);

            if (vendorPayment.payType === 5) {
              retainage += vendorPayment.retainage;
            } else {
              open += vendorPayment.amount;
            }
          }
        });

        setRetainageTotal((amount) => amount + retainage);
        setOpenAmountTotal((amount) => amount + open);

        return [...payments, ...vendorPayments, payment];
      });
    };

    const afterSaving = (draftID: string) => {
      setDefaultValues({
        selectedPayables,
        openAmountTotal,
        retainageTotal,
        draftID,
        hasOutdated: false,
      });
    };

    const afterSubmitting = () => {
      selectedPayables.forEach((payment) => {
        const openPayable = openPayables.find(
          (payable) =>
            comparePayableWithPayment(payable, payment, BatchType.payType) ||
            (!payment.apref &&
              !payable.Reference &&
              payment.vendor === payable.VendorCode)
        );

        if (openPayable) {
          openPayable.BatchDisabled = true;
        }
      });

      setOpenAmountTotal(0);
      setRetainageTotal(0);
      setSelectedPayables([]);
      setDefaultValues({
        selectedPayables: [],
        openAmountTotal: 0,
        retainageTotal: 0,
        draftID: undefined,
        hasOutdated: false,
      });

      if (changeCreateBatch) {
        changeCreateBatch(false);
      }

      showToast(
        'success',
        toast,
        'Submit Payment Batch',
        'The data was submitted successfully!',
        3000
      );
    };

    const cleanDraft = () => {
      setOpenAmountTotal(0);
      setRetainageTotal(0);
      setSelectedPayables([]);
    };

    useEffect(() => {
      if (!createBatch && access) {
        setSelectedPayables(defaultValues.selectedPayables);
        setOpenAmountTotal(defaultValues.openAmountTotal);
        setRetainageTotal(defaultValues.retainageTotal);
      }
    }, [createBatch, defaultBatchPayables, defaultValues]);

    useEffect(() => {
      if (defaultValues.hasOutdated && createBatch) {
        showToast(
          'warn',
          toast,
          'Payment Batch',
          'The draft has some payables already in a batch!',
          3000
        );
      }
    }, [defaultValues.hasOutdated, createBatch]);

    return (
      <div>
        {selectedPayable && (
          <APDrilldown
            visible={showDrilldown}
            setVisible={setShowDrilldown}
            payable={selectedPayable}
            extendedInfo={isArchive}
          />
        )}
        <Table
          id="open-table"
          ref={ref}
          data={sortedTransactions}
          className={`mx-3 dashboardOptionShadow tableFirstLineStyled dobleHeader`}
          calcHeight={useCallback(calcHeight, [])}
          onRowClick={useCallback(onRowClick, [])}
          rowClassName={useCallback(rowClassName, [])}
          onRowMouseEnter={useCallback(onRowHover, [])}
          sortField={sortState.field}
          sortOrder={sortState.order}
          onSort={useCallback(handleSort, [sortState.field, openPayables])}
          hideColumns={true}
          selection={selectedPayables}
          nonHideableColumns={['Select']}
        >
          <Column
            field="Vendor"
            header="Vendor"
            body={(ap) => <div className="scroll-text">{ap.Vendor}</div>}
            headerClassName={`tableHeader font-normal justify-content-center`}
            style={{ minWidth: '300px' }}
            className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Reference"
            header="Inv. Number"
            style={{ minWidth: '150px' }}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell text-center word-break-all"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="InvoiceDate"
            header="Inv. Date"
            style={{ minWidth: '120px' }}
            body={(ap) => formatUTCDate(ap.InvoiceDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName="tableFooter"
            sortable
          />
          <Column
            field="Description"
            header="Description"
            style={{ minWidth: '230px' }}
            body={(ap) => <div className="scroll-text">{ap.Description}</div>}
            headerClassName="tableHeader font-normal justify-content-center"
            className="text-standard blackText tableCell title overflow-x-hidden white-space-nowrap checkOverflow"
            footerClassName={`tableFooter`}
            sortable
          />
          <Column
            field="DueDate"
            header="Due Date"
            style={{ minWidth: '120px' }}
            body={(ap) => formatUTCDate(ap.DueDate)}
            headerClassName="tableHeader font-normal"
            className="justify-content-center text-standard blackText tableCell"
            footerClassName={`tableFooter ${
              isArchive &&
              'block text-right border-top-2 border-transparent mt-4 limitBorder relative'
            }`}
            footer={isArchive && 'Total:'}
            sortable
          />
          <Column
            field="Gross"
            header="Gross"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.Gross)}
            headerClassName="tableHeader font-normal justify-content-center"
            className="justify-content-end text-standard blackText tableCell"
            footerClassName={`tableFooter ${
              isArchive && 'block text-right border-top-2 mt-4'
            }`}
            footer={isArchive && formatCurrency(gross)}
            sortable
          />
          <Column
            field="HoldCode"
            header="Hold Code"
            style={{ minWidth: '80px', maxWidth: '80px' }}
            body={(ap) => <div className="scroll-text">{ap.HoldCode}</div>}
            headerClassName="tableHeader font-normal justify-content-center text-center overflow-x-visible white-space-normal"
            className="text-standard blackText tableCell overflow-x-hidden whiteSpace-nowrap checkOverflow"
            footerClassName={`tableFooter ${
              isArchive && 'block text-right border-top-2 mt-4'
            }`}
            sortable
          />
          {!isArchive && (
            <Column
              field="PayType"
              header="Pay Type"
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName="tableHeader font-normal"
              className="justify-content-center text-standard blackText tableCell"
              footer="Total:"
              footerClassName="tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
              sortable
            />
          )}
          <Column
            field="PayableAmount"
            header={'Payable Amount'}
            style={{ minWidth: '130px' }}
            body={(ap) => formatCurrency(ap.PayableAmount)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(payableAmountTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="Tax"
            header="Tax"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.Tax)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(taxTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="DiscountOffered"
            header="Discount Offered"
            style={{ minWidth: '120px' }}
            body={(ap) => formatCurrency(ap.DiscountOffered)}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(discTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          <Column
            field="NetAmount"
            header={'Net Amount'}
            style={{ minWidth: '140px' }}
            body={(ap) => formatCurrency(ap.NetAmount + (ap.Retainage ?? 0))}
            headerClassName="tableHeader font-normal justify-content-center text-center"
            className="justify-content-end text-standard blackText tableCell"
            footer={formatCurrency(payableAmountTotal + taxTotal - discTotal)}
            footerClassName="tableFooter block text-right border-top-2 mt-4"
            sortable
          />
          {createBatch && access && (
            <Column
              header="Select"
              body={(ap, options) => {
                if (ap.BatchDisabled) {
                  return;
                }

                return (
                  <PaymentBatchCheckbox
                    isSelected={selectedPayables.some((payment) =>
                      comparePayableWithPayment(ap, payment, BatchType.payType)
                    )}
                    openPayable={ap}
                    allPayables={options.props.value}
                    addPayableToBatch={(payment) => {
                      const vendorList = options.props.value.filter(
                        (value: Payable) => {
                          return (
                            payment.vendor === value.VendorCode &&
                            !value.BatchDisabled
                          );
                        }
                      );
                      const vendorTotalRow = vendorList[vendorList.length - 1];
                      const vendorPayment =
                        transformPayableToPayment(vendorTotalRow);
                      addPayable(payment, vendorList.length - 2, vendorPayment);
                    }}
                    removePayableFromBatch={removePayable}
                    addTotalToBatch={addVendorToBatch}
                    removeTotalFromBatch={removeVendorFromDraft}
                  />
                );
              }}
              style={{ minWidth: '70px', maxWidth: '70px' }}
              headerClassName={`tableHeader font-normal`}
              className={`justify-content-center text-standard blackText tableCell p-0 printHide`}
              footerClassName="tableFooter"
            />
          )}
        </Table>
        <ErrorToast toastRef={toast} />
        {createBatch && access && (
          <div className="w-full px-3 mr-3 my-5 text-standard printHide">
            <div className="ml-auto w-24rem my-5">
              <div className="flex justify-content-between">
                <span>Open Amount Total:</span>
                <span id="openAmountTotal">
                  {formatCurrency(openAmountTotal)}
                </span>
              </div>
              <div className="flex justify-content-between mt-2">
                <span>Retainage Total:</span>
                <span id="retainageTotal">
                  {formatCurrency(retainageTotal)}
                </span>
              </div>
              <div className="flex justify-content-between mt-1 border-top-1 border-bottom-2">
                <span className="font-bold">Payment Batch Total</span>
                <span id="batchTotal">
                  {formatCurrency(openAmountTotal + retainageTotal)}
                </span>
              </div>
            </div>
            <div className="mt-3 flex flex-wrap gap-4 justify-content-center printHide">
              <PaymentBatchProcess
                batch={selectedPayables}
                afterSubmitting={afterSubmitting}
                afterSaving={afterSaving}
                defaultDraftID={defaultValues.draftID}
                type={BatchType.payType}
                resetDraft={cleanDraft}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<APOpenTableProps>,
  nextTransactions: Readonly<APOpenTableProps>
) => {
  return (
    prevTransactions.accountsPayables === nextTransactions.accountsPayables
  );
};

export default React.memo(APOpenTable, transactionsAreEqual);
