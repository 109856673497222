import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import APDrilldownContent from './APDrilldownContent';
import { Payable } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../context/CompanyContext';
import { fetchPayableDetails } from '../../../services/AccountsPayablesService';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { CompanySource } from '../../../Interfaces/User.interfaces';
import YardiAPDrilldownContent from './YardiAPDrilldownContent';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

type APDrilldownProps = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  payable: Payable;
  extendedInfo?: boolean;
};

const APDrilldown = ({
  visible,
  setVisible,
  payable,
  extendedInfo,
}: APDrilldownProps) => {
  const { selectedCompany } = useCompanyContext();
  const isEnabled =
    payable.VendorCode && payable.TransCode && payable.Reference;
  const { data, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['getAPDetails', selectedCompany, payable],
    queryFn: ({ signal }) =>
      fetchPayableDetails(
        {
          company: selectedCompany!,
          vendor: payable.VendorCode ?? 0,
          aptrans: payable.TransCode ?? 0,
          apref: payable.Reference ?? '',
          invDate: payable.InvoiceDate,
        },
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={() => setVisible(false)}
      className="w-full max-w-800px"
    >
      {isFetching && (
        <div className="mx-8 h-full flex align-items-center">
          <ProgressSpinner />
        </div>
      )}
      {isError && !isFetching && (
        <div className="h-full flex align-items-center">
          <ErrorMessage
            content={'Failed to obtain data! Please try again later.'}
          />
        </div>
      )}
      {isSuccess &&
        !isFetching &&
        (getSource(selectedCompany) == CompanySource.ViewPoint ? (
          <APDrilldownContent
            payable={payable}
            invoices={data.invoices}
            approvals={data.approvals}
            uniqueAttchID={data.uniqueAttchID}
            extendedInfo={extendedInfo}
          />
        ) : (
          <YardiAPDrilldownContent
            payable={payable}
            invoices={data.invoices}
            approvals={data.approvals}
            uniqueAttchID={data.uniqueAttchID}
            extendedInfo={extendedInfo}
          />
        ))}
    </Sidebar>
  );
};

export default APDrilldown;
