import { InputNumber } from 'primereact/inputnumber';

type AmountProps = {
  value?: number;
  disabled?: boolean;
  changeValue: (amount?: number) => void;
};

const Amount = ({ value, disabled, changeValue }: AmountProps) => {
  return (
    <span className="text-center w-full">
      <InputNumber
        className="printHide"
        locale="en-US"
        inputClassName="w-full blackText text-standard text-center"
        value={value}
        onBlur={(e) => {
          let amount = undefined;
          if (e.target.value) {
            amount = parseFloat(e.target.value?.replace(/,/g, ''));
          }
          changeValue(amount);
        }}
        disabled={disabled}
        maxFractionDigits={2}
        minFractionDigits={2}
      />
      <span className="printMedia printShow">{value}</span>
    </span>
  );
};

export default Amount;
