import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  fetchAccountingDashboardAccounts,
  fetchAccountingDashboardContracts,
} from '../../services/DataService';
import { ProgressSpinner } from 'primereact/progressspinner';
import DashboardCashManagement from '../../features/accounting-dashboard/DashboardCashManagement';
import { useCompanyContext } from '../../context/CompanyContext';
import DashboardAccountsPayables from '../../features/accounting-dashboard/DashboardAccountablePayables';
import DashboardAccountsReceivables from '../../features/accounting-dashboard/DashboardAccountsReceivables';
import DashboardContractBillings from '../../features/accounting-dashboard/DashboardContractBillings';
import DashboardJobsAndContracts from '../../features/accounting-dashboard/DashboardJobsAndContracts';
import DashboardPayroll from '../../features/accounting-dashboard/DashboardPayroll';
import { fetchPRPeriodDates } from '../../services/PayrollService';
import { fetchSCScheduleDates } from '../../services/SubcontractsService';
import DashboardSubcontracts from '../../features/accounting-dashboard/DashboardSubcontracts';
import { CompanySource } from '../../Interfaces/User.interfaces';
import checkPermissions from '../../HOCs/CheckPermissions';
import { checkAccess } from '../../../utils/roleUtils';
import { useRolesAccessContext } from '../../context/RolesAccessContext';
import { reportsPerSection } from '../../../utils/rolesConst';
import DashboardGeneralLedgerPayable from '../../features/accounting-dashboard/DashboardGeneralLedgerPayable';
import DashboardGeneralLedgerReceivable from '../../features/accounting-dashboard/DashboardGeneralLedgerReceivable';
import DashboardJobCostBudget from '../../features/accounting-dashboard/DashboardJobCostBudget';
import { fecthLatestJobCostBudget } from '../../services/JobCostBudget';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

const AccountingDashboard = () => {
  const { selectedCompany } = useCompanyContext();
  const { rolesAcess, isAdmin } = useRolesAccessContext();

  const CMSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['cash management'],
    Children: () => (
      <div className="xl:flex-grow-1 max-w-500px">
        <DashboardCashManagement />
      </div>
    ),
  });

  const APSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['accounts payables'],
    Children: () => (
      <div className="xl:flex-grow-1 max-w-500px">
        <DashboardAccountsPayables />
      </div>
    ),
  });

  const ARSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['accounts receivables'],
    Children: () => (
      <div className="xl:flex-grow-1 max-w-500px">
        <DashboardAccountsReceivables />
      </div>
    ),
  });

  const CBSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['contract billings'],
    Children: () => (
      <div className="w-full max-w-550px">
        <DashboardContractBillings />
      </div>
    ),
  });

  const JCSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['jobs & contracts'],
    Children: () => (
      <div className="w-full max-w-900px lg:w-fit xl:w-auto xl:flex-shrink-1 xl:min-w-0 xl:flex-grow-1">
        <DashboardJobsAndContracts />
      </div>
    ),
  });

  const PayrollSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['payroll'],
    Children: () => (
      <div className="w-full max-w-480px">
        <DashboardPayroll />
      </div>
    ),
  });

  const SCSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['subcontracts'],
    Children: () => (
      <div className="w-full sm:w-fit">
        <DashboardSubcontracts />
      </div>
    ),
  });

  const { isFetching } = useQuery({
    queryKey: ['getAccountingDashboardAccountsData', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountingDashboardAccounts(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled:
      checkAccess(rolesAcess ?? [], [
        ...reportsPerSection['cash management'],
        ...reportsPerSection['accounts payables'],
        ...reportsPerSection['accounts receivables'],
      ]) || isAdmin,
  });

  const contractData = useQuery({
    queryKey: ['getAccountingDashboardContractsData', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountingDashboardContracts(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled:
      checkAccess(rolesAcess ?? [], [
        ...reportsPerSection['contract billings'],
        ...reportsPerSection['jobs & contracts'],
      ]) || isAdmin,
  });

  const payrollData = useQuery({
    queryKey: ['getPayrollPeriodDates', selectedCompany],
    queryFn: ({ signal }) => fetchPRPeriodDates(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled:
      (checkAccess(rolesAcess ?? [], reportsPerSection['payroll']) ||
        isAdmin) &&
        getSource(selectedCompany) === CompanySource.ViewPoint,
  });

  const subcontractData = useQuery({
    queryKey: ['getSCScheduleDates', selectedCompany],
    queryFn: ({ signal }) => fetchSCScheduleDates(selectedCompany!.id, signal),
    refetchOnWindowFocus: false,
    enabled:
      (checkAccess(rolesAcess ?? [], reportsPerSection['subcontracts']) ||
        isAdmin) &&
        getSource(selectedCompany) === CompanySource.ViewPoint,
  });

  const latestJobCostBudget = useQuery({
    queryKey: ['getLatestJobCostBudget', selectedCompany],
    queryFn: ({ signal }) =>
      fecthLatestJobCostBudget(
        selectedCompany!.id,
        selectedCompany!.source,
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: getSource(selectedCompany) === CompanySource.Yardi,
  });

  if (
    isFetching ||
    contractData.isFetching ||
    payrollData.isFetching ||
    subcontractData.isFetching ||
    latestJobCostBudget.isFetching
  ) {
    return <ProgressSpinner className="mx-auto flex" />;
  }

  return (
    <div className="p-4 pt-3 mx-auto max-w-1400px flex flex-wrap gap-4 xl:row-gap-3 justify-content-center xl:justify-content-start">
      <div className="flex flex-wrap justify-content-center xl:justify-content-start w-full gap-4">
        <CMSummary />
        {getSource(selectedCompany) === CompanySource.ViewPoint && (
          <>
            <APSummary />
            <ARSummary />
          </>
        )}
        {getSource(selectedCompany) === CompanySource.Yardi && (
          <>
            <DashboardGeneralLedgerPayable />
            <DashboardGeneralLedgerReceivable />
          </>
        )}
      </div>
      {getSource(selectedCompany) === CompanySource.ViewPoint && (
        <>
          <div className="flex flex-wrap xl:flex-nowrap w-full gap-4 justify-content-center xl:justify-content-start">
            <CBSummary />
            <JCSummary />
          </div>
          <PayrollSummary />
          <SCSummary />
        </>
      )}
      {getSource(selectedCompany)=== CompanySource.Yardi && (
        <div className="flex flex-wrap xl:flex-nowrap w-full gap-4 justify-content-center xl:justify-content-start">
          <DashboardJobCostBudget />
        </div>
      )}
    </div>
  );
};

export default React.memo(AccountingDashboard);
