import { Company } from '../app/Interfaces/User.interfaces';

export const getSource = (company?: Company) => {
  if (!company) {
    return;
  }

  if (company.mask) {
    return company.mask.source;
  }

  return company.source;
};
