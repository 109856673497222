export type Payable = {
  Vendor: string;
  VendorCode?: number;
  TransCode?: number;
  Reference: string;
  InvoiceDate: Date;
  Description: string;
  DueDate?: Date;
  PayType?: number;
  NetAmount: number;
  DiscountOffered?: number;
  Gross: number;
  Tax: number;
  PayableAmount?: number;
  Job?: string;
  HoldCode?: string;
  Retainage?: number;
  Notes?: string;
  Reviewer?: string;
  Rejected?: string;
  UniqueAttchID?: string;
  BatchDisabled?: boolean;
  RetainageBatchDisabled?: boolean;
};

export type AgedPayable = {
  Vendor: string;
  VendorCode: number;
  TransCode: number;
  Reference: string;
  InvoiceDate: Date;
  Description: string;
  DueDate: Date;
  DiscountOffered: number;
  Current: number;
  Over30: number;
  Over60: number;
  Over90: number;
  HoldRet: number;
};

export type PayableDetail = {
  Line: number;
  Sequence: number;
  Status: number;
  Amount: number;
  JobCode: string;
  JobName: string;
  PhaseCode: string;
  PhaseDescription: string;
  Account: string;
  AccountName: string;
  CostType: number;
  CostName: string;
  PaidDate: Date;
  CMRef: string;
  ClearDate: Date;
  CMAcct: number;
  Details?: any;
};

export type PayableApproval = {
  Reviewer: string;
  DateApproved: Date;
  Memo: string;
};

export type VendorPayments = {
  uuid: string;
  vendor: APVendor;
  CMAcct: number;
  payMethod: string;
  CMRef: string;
  paidDate: Date;
  amount: number;
  discount: number;
  deduct: number;
  netAmount: number;
};

export type APVendorPaymentDetails = {
  Reference: string;
  InvoiceDate: Date;
  Description: string;
  Amount: number;
  Discount: number;
  Deduct: number;
  NetAmount: number;
};

export type APVendor = {
  id: string;
  code: string;
  name: string;
  sortName: string;
  type: string;
  tempYN: string;
  contact: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  activeYN: string;
  v1099YN: string;
  payTerms: string;
  v1099Type: string;
  v1099Box: string;
  taxId: string;
  payMethod: string;
  vendorAcctNumber: string;
  vendorGroup?: string;
};

export type APVendorSummary = {
  id: number;
  code: string;
  sortName: string;
  name: string;
};

export type JobSummary = {
  Job: string;
  JobName: string;
};

export type GLAccount = {
  GLAcct: string;
  Description: string;
};
export type PaymentBatchPayable = {
  apref: string;
  aptrans: number;
  vendor: number;
  payType?: number;
  job?: string;
  type?: PerJobPayableType;
};

export type PaymentBatch = {
  id: string;
  payments: PaymentBatchPayable[];
  submitted: boolean;
  updatedAt: Date;
};

export type DraftBatchPayment = {
  apref?: string;
  aptrans?: number;
  vendor?: number;
  payType?: number;
  job?: string;
  amount: number;
  retainage: number;
  type?: PerJobPayableType;
};

export enum BatchType {
  payType = 'payType',
  job = 'job',
}

export enum PerJobPayableType {
  amount = 'amount',
  retainage = 'retainage',
}
