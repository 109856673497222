import {
  DataTable,
  DataTableColumnResizeEndParams,
  DataTableProps,
  DataTableRowClassNameOptions,
  DataTableRowExpansionTemplate,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MultipleSelectStyled from '../inputs/StyledInputs/MultipleSelectStyled';
import { compareReactElements } from 'apps/tmr-frontend/src/utils/utils';
import RowSelection from './RowSelection';
import TableContextMenu from './TableContextMenu';
import useHideColumns from '../../hooks/useHideColumns';
import { MultiSelectChangeParams } from 'primereact/multiselect';

type TableProps = DataTableProps & {
  children: React.ReactNode;
  data: unknown[];
  className?: string;
  calcHeight?: (rows: number) => number;
  rowClass?: (
    data: unknown,
    options?: DataTableRowClassNameOptions
  ) => object | string;
  onRowsAmountChange?: (rows: number) => void;
  defaultRowAmount?: number;
  fixedDefaultHeight?: number;
  selectionKey?: string;
  hideColumns?: boolean;
  nonHideableColumns?: string[];
  alwaysHiddenColumns?: string[];
  customRowExpansionTemplate?: (
    data: unknown,
    options: DataTableRowExpansionTemplate,
    hiddenColumns?: string[]
  ) => React.ReactNode;
  hideColumnsDropdown?: boolean;
  persistHiddenColumns?: boolean;
  headerContent?: React.ReactNode;
  afterHiddenColumnsUpdate?: (
    showColumns: string[],
    columnOptions: string[]
  ) => void;
  columnsDefault?: {
    showColumns: string[];
    columnOptions: string[];
  };
};

const Table = React.forwardRef<HTMLDivElement, TableProps>(
  (
    {
      id,
      children,
      data,
      className,
      calcHeight,
      rowClass,
      onRowsAmountChange,
      defaultRowAmount,
      fixedDefaultHeight,
      selectionKey,
      hideColumns,
      headerColumnGroup,
      nonHideableColumns,
      alwaysHiddenColumns,
      rowExpansionTemplate,
      customRowExpansionTemplate,
      hideColumnsDropdown,
      persistHiddenColumns,
      headerContent,
      afterHiddenColumnsUpdate,
      columnsDefault,
      ...otherProps
    },
    ref
  ) => {
    const [showRows, setShowRows] = useState(
      defaultRowAmount ? defaultRowAmount : 15
    );
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>(
      {}
    );
    const {
      columnOptions,
      columns,
      headerColumns,
      showColumns,
      updateVisibleColumns,
      hideColumn,
    } = useHideColumns({
      children,
      id,
      persistHiddenColumns,
      alwaysHiddenColumns,
      nonHideableColumns,
      headerColumnGroup,
      afterColumnsUpdate: afterHiddenColumnsUpdate,
      defaultValues: columnsDefault,
    });
    const dt = useRef<DataTable>(null);
    const columnWidth = useRef<
      Record<number, { width: number; origMax?: number }>
    >({});

    const selectRow = useCallback(
      (e: DataTableSelectionChangeParams) => {
        const key = selectionKey as string;
        const isSelected = selectedRows[e.value[key]];
        selectedRows[e.value[key]] = true;
        if (isSelected) {
          selectedRows[e.value[key]] = false;
        }
        setSelectedRows({ ...selectedRows });
      },
      [selectedRows, selectionKey]
    );

    const removeTableResizeStyleTag = () => {
      const table = dt.current?.getElement();
      if (!table) {
        return;
      }

      const prIdAttr = Array.from(table.attributes).find((attr) =>
        /^pr_id_\d+$/.test(attr.name)
      )?.name;

      if (!prIdAttr) {
        return;
      }

      const styleTags = Array.from(document.querySelectorAll('style'));

      for (const tag of styleTags) {
        if (tag.textContent?.includes(`.p-datatable[${prIdAttr}]`)) {
          tag.remove();
          return;
        }
      }
    };

    const onVisibleColumnChange = (e: MultiSelectChangeParams) => {
      updateVisibleColumns(e.value);
      removeTableResizeStyleTag();
      columnWidth.current = {};
    };

    const handleResizeEnd = (e: DataTableColumnResizeEndParams) => {
      const colEl = e.element;
      const table = dt.current?.getElement();
      if (!table) return;

      const colIndex = Array.from(e.element.parentElement!.children).indexOf(
        e.element
      );
      if (colIndex === -1) return;

      if (!columnWidth.current[colIndex]) return;

      const newWidth = columnWidth.current[colIndex].width + e.delta;
      let newMax = columnWidth.current[colIndex].origMax;

      if (newMax && newWidth > newMax) {
        newMax = newWidth;
      }

      if (newMax) {
        colEl.style.maxWidth = `${newMax}px`;

        const bodyRows = table.querySelectorAll('.p-datatable-tbody > tr');
        bodyRows.forEach((row) => {
          const cell = row.children[colIndex] as HTMLElement;
          if (cell) {
            cell.style.maxWidth = `${newMax}px`;
          }
        });
      }
    };

    useEffect(() => {
      const table = dt.current?.getElement();
      if (!table) return;
      const resizerHandles = table.querySelectorAll('.p-column-resizer');

      const functions: Record<number, (e: Event) => void> = {};
      resizerHandles.forEach((resizer, index) => {
        const listener = (e: Event) => {
          const mouseEvent = e as MouseEvent;
          const th = (mouseEvent.target as HTMLElement).closest('th');

          if (th) {
            const colIndex = Array.from(th.parentElement!.children).indexOf(th);
            const current = columnWidth.current[colIndex];

            if (current) {
              current.width = th.offsetWidth;
            } else {
              columnWidth.current[colIndex] = {
                width: th.offsetWidth,
                origMax: th.style.maxWidth
                  ? parseInt(th.style.maxWidth)
                  : undefined,
              };
            }
          }
        };
        resizer.addEventListener('mousedown', listener);
        functions[index] = listener;
      });

      return () => {
        resizerHandles.forEach((resizer, index) => {
          resizer.removeEventListener('mousedown', functions[index]);
        });
      };
    }, [columns]);

    return (
      <React.Fragment>
        {((hideColumns && !hideColumnsDropdown) ||
          calcHeight ||
          headerContent) && (
          <div className="printHide flex flex-wrap  mx-3 mb-2 mt-3 gap-2">
            {hideColumns && !hideColumnsDropdown && (
              <div className="max-w-7 w-7">
                <MultipleSelectStyled
                  resetFilterOnHide={true}
                  showSelectAll={true}
                  filter={true}
                  options={columnOptions}
                  placeholder={'Select columns to show'}
                  value={showColumns}
                  onChange={onVisibleColumnChange}
                  display="chip"
                />
              </div>
            )}
            {headerContent}
            {calcHeight && (
              <RowSelection
                showRows={showRows}
                setShowRows={setShowRows}
                onRowsAmountChange={onRowsAmountChange}
              />
            )}
          </div>
        )}
        {hideColumns && (
          <TableContextMenu
            dt={dt}
            afterContextMenuSelected={hideColumn}
            noMenuColumns={nonHideableColumns}
          />
        )}
        <DataTable
          id={id}
          ref={dt}
          rows={showRows}
          removableSort
          value={data}
          scrollable
          scrollHeight={`${
            calcHeight ? calcHeight(showRows) : fixedDefaultHeight ?? 400
          }px`}
          className={`tableStyled tablePrint ${className}`}
          stripedRows
          rowClassName={rowClass}
          onSelectionChange={selectionKey ? selectRow : undefined}
          selection={selectedRows}
          headerColumnGroup={headerColumns}
          rowExpansionTemplate={(data, options) => {
            if (customRowExpansionTemplate) {
              const hiddenCols = columnOptions.filter(
                (header) => !showColumns.includes(header)
              );

              return customRowExpansionTemplate(data, options, hiddenCols);
            }

            if (rowExpansionTemplate) {
              return rowExpansionTemplate(data, options);
            }

            return null;
          }}
          resizableColumns
          columnResizeMode="expand"
          onColumnResizeEnd={handleResizeEnd}
          {...otherProps}
        >
          {hideColumns ? columns : children}
        </DataTable>
      </React.Fragment>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<TableProps>,
  nextTransactions: Readonly<TableProps>
) => {
  let isEquals = true;
  Object.keys(prevTransactions).forEach(function (key) {
    if (
      prevTransactions[key as keyof TableProps] !==
        nextTransactions[key as keyof TableProps] &&
      key !== 'children'
    ) {
      isEquals = false;
    }
  });

  const prevChildren = prevTransactions.children;
  const nextChildren = nextTransactions.children;

  if (Array.isArray(prevChildren) && Array.isArray(nextChildren)) {
    if (prevChildren.length !== nextChildren.length) {
      isEquals = false;
    } else {
      for (let i = 0; i < prevChildren.length; i++) {
        const prevChild = prevChildren[i];
        const nextChild = nextChildren[i];

        if (
          React.isValidElement(prevChild) &&
          React.isValidElement(nextChild)
        ) {
          isEquals = isEquals && compareReactElements(prevChild, nextChild);
        } else {
          isEquals = isEquals && prevChild === nextChild;
        }
      }
    }
  } else {
    isEquals = isEquals && prevChildren === nextChildren;
  }

  return isEquals;
};

export default React.memo(Table, transactionsAreEqual);
