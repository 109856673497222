import React, { useCallback } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import { APVendor } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';
import TooltipResume from '../../../components/messages/TooltipResume';
import { PaymentMethod, VendorType } from '../../../../utils/apConst';
import {
  DataTableRowClassNameOptions,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { getVendorKeyPerCompany } from '../apUtil';
import { useCompanyContext } from '../../../context/CompanyContext';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

type APVendorTableProps = {
  vendors: APVendor[];
};

const APVendorTable = React.forwardRef<HTMLDivElement, APVendorTableProps>(
  ({ vendors }, ref) => {
    const { selectedCompany } = useCompanyContext();
    const vendorKey = getVendorKeyPerCompany(getSource(selectedCompany));
    const calcHeight = (rows: number) => {
      const headerHeight = 49;
      const footerHeight = 8;
      const rowHeight = 31;
      return headerHeight + footerHeight + rows * rowHeight + 3;
    };

    const onRowHover = (e: DataTableRowMouseEventParams) => {
      const cell = e.originalEvent.target as HTMLElement;
      const row = cell.closest('tr') as HTMLElement;
      animateCellText(row);
    };

    const vendorStatus = (status: string, tempStatus: string) => {
      if (status === 'Y' && tempStatus === 'Y') {
        return 'Temporary';
      } else if (status === 'Y' && tempStatus !== 'Y') {
        return 'Active';
      } else {
        return 'Innactive';
      }
    };

    return (
      <Table
        id="vendor-table"
        ref={ref}
        data={vendors}
        className={`mx-3 dashboardOptionShadow tableFirstLineStyled noFooter dobleHeader`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        selectionKey={vendorKey}
        rowClass={useCallback(
          (data: unknown, options?: DataTableRowClassNameOptions) => {
            const vendor = data as APVendor;
            const selectedRows = options?.props.selection;
            return selectedRows[vendor[vendorKey]] ? 'focus-row' : '';
          },
          []
        )}
        selectionMode="single"
        hideColumns={true}
      >
        <Column
          field="name"
          header="Name"
          body={(ap) => {
            return <div className="scroll-text">{ap.name}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '300px', maxWidth: '300px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="sortName"
          header="Sort Name"
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '250px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="contact"
          header="Contact"
          body={(ap) => {
            return <div className="scroll-text">{ap.contact}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center`}
          style={{ minWidth: '150px', maxWidth: '150px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="phone"
          header="Phone"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="address"
          header="Address"
          body={(ap) => {
            return <div className="scroll-text">{ap.address}</div>;
          }}
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '200px', maxWidth: '200px' }}
          className={`text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="city"
          header="City"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '200px' }}
          className={`text-standard blackText tableCell justify-content-center text-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="state"
          header="State"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '90px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="zip"
          header="Zip Code"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="email"
          header="Email"
          body={(ap) => (
            <React.Fragment>
              <div className="printHide">
                <TooltipResume
                  text={ap.email}
                  maximum={25}
                  id={ap[vendorKey]}
                />
              </div>
              <div className="hidden printShow word-break-word w-fit">
                {ap.EMail ? ap.EMail.replace(/;/g, ' ') : ''}
              </div>
            </React.Fragment>
          )}
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '250px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="vendorAcctNumber"
          header="Account Number"
          headerClassName={`tableHeader font-normal justify-content-center text-center`}
          style={{ minWidth: '100px' }}
          className={`text-standard blackText tableCell`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="type"
          header="Type"
          body={(ap) =>
            VendorType[ap.type as keyof typeof VendorType] || ap.type
          }
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '100px' }}
          className={`text-standard blackText tableCell  justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="activeYN"
          header="Status"
          body={(ap) => vendorStatus(ap.activeYN, ap.tempYN)}
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '100px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="payTerms"
          header="Pay Terms"
          headerClassName={`tableHeader font-normal text-center`}
          style={{ minWidth: '100px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="payMethod"
          header="Payment Method"
          body={(ap) =>
            PaymentMethod[ap.payMethod as keyof typeof PaymentMethod] ||
            ap.PayMethod
          }
          headerClassName={`tableHeader font-normal text-center`}
          style={{ minWidth: '120px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="v1099YN"
          header="Subject to 1099"
          body={(ap) => (ap.v1099YN === 'Y' ? 'Yes' : 'No')}
          headerClassName={`tableHeader font-normal text-center`}
          style={{ minWidth: '100px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="v1099Type"
          header="1099 Type"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '90px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="v1099Box"
          header="Box"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '40px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
        <Column
          field="taxId"
          header="Tax ID"
          headerClassName={`tableHeader font-normal`}
          style={{ minWidth: '150px' }}
          className={`text-standard blackText tableCell justify-content-center`}
          footerClassName="tableFooter"
          sortable
        />
      </Table>
    );
  }
);

const transactionsAreEqual = (
  prevTransactions: Readonly<APVendorTableProps>,
  nextTransactions: Readonly<APVendorTableProps>
) => {
  return prevTransactions.vendors === nextTransactions.vendors;
};

export default React.memo(APVendorTable, transactionsAreEqual);
