import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { navOption } from '../../Interfaces/NavBar.interfaces';
import NavLink from './NavLink';

type NavbarProps = {
  options: navOption[];
  onOptionClick?: () => void;
};

const Navbar = ({ options, onOptionClick }: NavbarProps) => {
  const [activeOption, setActiveOption] = useState('');
  const navigate = useNavigate();
  const path = window.location.pathname;

  const onClick = (option: navOption) => {
    setActiveOption(option.label);
    if (onOptionClick) {
      onOptionClick();
    }
  };

  useEffect(() => {
    options.forEach((option) => {
      if (path.includes(option.url) && option.url) {
        setActiveOption(option.label);
      }
    });
  }, [options, path]);

  useEffect(() => {
    const selectedOption = options.find((option) => {
      return option.label === activeOption;
    });

    if (!selectedOption && options[0] && !!activeOption) {
      navigate(options[0].url);
    }
  }, [options, activeOption]);

  return (
    <div className="w-fit mx-auto text-center flex justify-content-center align-items-center gap-6 text-lg border-bottom-2 navbarBorder height-54px">
      {options.map((option) => {
        return (
          <NavLink
            key={option.label}
            option={option}
            onClick={onClick}
            isActive={activeOption === option.label}
          />
        );
      })}
    </div>
  );
};

export default Navbar;
