import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import ResumeCard from '../../components/cards/ResumeCard';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { formatCurrency } from '../../../utils/formatUtils';
import ErrorMessage from '../../components/messages/ErrorMessage';
import { BACKGROUND_COLORS, BORDER_COLORS } from '../../../utils/graphConst';
import ColorIndicator from '../../components/messages/ColorIndicator';
import { CompanySource } from '../../Interfaces/User.interfaces';
import { useCompanyContext } from '../../context/CompanyContext';
import { useQuery } from '@tanstack/react-query';
import { fetchAccountingDashboardAccounts } from '../../services/DataService';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const DashboardAccountsPayables = () => {
  const { selectedCompany } = useCompanyContext();
  const [hidden, setHidden] = useState(false);
  const [opTotal, setOpTotal] = useState(0);
  const [uapTotal, setUapTotal] = useState(0);

  const pieData = {
    labels: ['Open Payables', 'Unapproved Payables'],
    datasets: [
      {
        data: [opTotal, uapTotal],
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleMouseEnter = () => {
    if (getSource(selectedCompany) === CompanySource.ViewPoint) setHidden(true);
  };

  const handleMouseLeave = () => {
    if (getSource(selectedCompany) === CompanySource.ViewPoint)
      setHidden(false);
  };

  const { data, isError } = useQuery({
    queryKey: ['getAccountingDashboardAccountsData', selectedCompany],
    queryFn: ({ signal }) =>
      fetchAccountingDashboardAccounts(selectedCompany!, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (data?.accountsPayableResume) {
      setOpTotal(data.accountsPayableResume.openPayables);
      setUapTotal(data.accountsPayableResume.unapprovedPayables ?? 0);
    }
  }, [data]);

  if (isError) {
    return (
      <div className="w-fit max-w-350px">
        <ResumeCard>
          <div className="relative p-3 px-4 flex flex-column h-full">
            <Link
              to={`/accounting-dashboard/account-payables`}
              className="w-fit"
            >
              <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
                Accounts Payables
              </div>
            </Link>
            <div className="my-8 py-3">
              <ErrorMessage
                content={'Failed to obtain data! Please try again later.'}
              />
            </div>
          </div>
        </ResumeCard>
      </div>
    );
  }

  return (
    <ResumeCard>
      <div
        className="relative p-3 px-4 flex flex-column"
        onMouseEnter={() => {
          handleMouseEnter();
        }}
        onMouseLeave={() => {
          handleMouseLeave();
        }}
        role="button"
        tabIndex={0}
      >
        <Link to={`/accounting-dashboard/account-payables`} className="w-fit">
          <div className="text-24px font-bold cursor-pointer totalColor hover:underline">
            Accounts Payables
          </div>
        </Link>
        <div
          className={`flex flex-wrap justify-content-around gap-5 ${
            hidden && 'visibility-hidden'
          }`}
        >
          <div className="mt-auto mb-3">
            <div className="mb-2 totalColor text-25px word-break-all">
              {formatCurrency(opTotal + uapTotal)}
            </div>
            <div className="w-fit mt-1 text-18px text-white totalBG radius-5px p-2">
              Total Due
            </div>
          </div>
          <div>
            <Pie
              data={pieData}
              options={options}
              className="w-8rem h-8rem mx-auto"
            />
          </div>
        </div>
        {hidden && (
          <div className="absolute scrollbarStyled overflow-y-auto w-full my-3 px-4 left-0 bottom-0 height-75 flex flex-column justify-content-center gap-2">
            <div className="flex justify-content-between">
              <Link
                className="text-15px totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/account-payables?reportType=openPayables`}
              >
                <ColorIndicator colorIndex={0} />
                Open Payables
              </Link>
              <div className="text-15px">{formatCurrency(opTotal)}</div>
            </div>
            <div className="flex justify-content-between">
              <Link
                className="text-15px totalColor cursor-pointer hover:underline flex gap-1 align-items-center"
                to={`/accounting-dashboard/account-payables?reportType=unapprovedInvoices`}
              >
                <ColorIndicator colorIndex={1} />
                Unapproved Payables
              </Link>
              <div className="text-15px">{formatCurrency(uapTotal)}</div>
            </div>
            <div className="flex justify-content-between border-top-2 border-top-solid totalBorder pt-3">
              <div className="text-15px">Total Due</div>
              <div className="text-15px">
                {formatCurrency(opTotal + uapTotal)}
              </div>
            </div>
          </div>
        )}
      </div>
    </ResumeCard>
  );
};

export default DashboardAccountsPayables;
