import { useCallback, useEffect, useMemo, useRef } from 'react';
import CashManagementFilters, {
  CashManagementFilterFields,
} from '../../features/cashManagement/CashManagementFilters';
import CashManagementBanks from '../../features/cashManagement/BanksResume/CashManagementBanksContainer';
import { FormProvider, useForm } from 'react-hook-form';
import CMTransactionsTableContainer from '../../features/cashManagement/CMTable/CMTransactionsTableContainer';
import CashManagementReports from '../../features/cashManagement/CMReport/CashManagementReports';
import CMReportHeader from '../../features/cashManagement/CMReport/CMReportHeader';
import checkPermissions from '../../HOCs/CheckPermissions';
import CMParams from '../../features/cashManagement/CMParams';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { useQuery } from '@tanstack/react-query';
import { fetchCashManagementAccounts } from '../../services/CashManagementService';
import { useCompanyContext } from '../../context/CompanyContext';
import { reportsPerSection } from '../../../../src/utils/rolesConst';
import { CompanySource } from '../../Interfaces/User.interfaces';
import CMTransactionYardiTableContainer from '../../features/cashManagement/CMTable/CMTransactionBluwaiTableContainer';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

const CashManagement = () => {
  const prevSelectedCompanyRef = useRef<string | null>(null);
  const { selectedCompany } = useCompanyContext();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<CashManagementFilterFields>();

  const CMTransactions = checkPermissions({
    sectionsToAccess: ['cash'],
    Children: CMTransactionsTableContainer,
  });

  const CMTransactionYardi = checkPermissions({
    sectionsToAccess: ['cash management'],
    Children: CMTransactionYardiTableContainer,
  });

  const CMSummary = checkPermissions({
    sectionsToAccess: reportsPerSection['cash management'],
    Children: CashManagementBanks,
  });

  const {
    data: accounts,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getCashManagementAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fetchCashManagementAccounts(
        selectedCompany!.source,
        selectedCompany!.id,
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany,
    onSuccess: (data) => {
      const accountValue = methods.getValues('account');
      if (!accountValue && data.length > 0) {
        methods.setValue('account', data[0]['id']);
      }
    },
  });

  const CMReport = () => {
    switch (getSource(selectedCompany)) {
      case CompanySource.ViewPoint:
        return <CMTransactions />;
      case CompanySource.Yardi:
        return <CMTransactionYardi />;
      default:
        return null;
    }
  };

  const CMReportCB = useMemo(CMReport, [getSource(selectedCompany)]);

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="h-full flex flex-column">
      <FormProvider {...methods}>
        <div className="mt-6 mx-3 flex gap-2 flex-wrap justify-content-start">
          <CMSummary />
        </div>
        <div className="flex flex-column  gap-2 mx-3  mt-3">
          <div className="flex flex-row gap-2 justify-content-between">
            <div>
              <FiltersMenu>
                <CashManagementFilters
                  isError={isError}
                  isLoading={isLoading}
                  accounts={accounts}
                />
              </FiltersMenu>
            </div>
            <div className="flex mt-3   gap-2">
              <CashManagementReports elementRef={tableRef} />
            </div>
          </div>
        </div>
        <div className="mb-3 printBackground pdfDivFitContent" ref={tableRef}>
          <CMReportHeader />
          {CMReportCB}
        </div>
        <CMParams />
      </FormProvider>
    </div>
  );
};

export default CashManagement;
