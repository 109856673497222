import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FiltersMenu from '../../../components/filters-menu/FiltersMenu';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ProcessContextProvider } from '../../../context/ProcessContext';
import PayPeriodDates from '../../../features/acForms/PayrollTimesheet/PayPeriodDates';
import PRTimesheetParams from '../../../features/acForms/PayrollTimesheet/PRTimesheetParams';
import TimesheetFilters, {
  PRTimesheetFilters,
} from '../../../features/acForms/PayrollTimesheet/TimesheetFilters';
import TimesheetReport from '../../../features/acForms/PayrollTimesheet/TimesheetReport';
import TimesheetReportHeader from '../../../features/acForms/PayrollTimesheet/TimesheetReportHeader';
import TimeSheetTableContainer from '../../../features/acForms/PayrollTimesheet/TimesheetTableContainer';
import TimesheetTemplate from '../../../features/acForms/PayrollTimesheet/TimesheetTemplate';
import { TimeSheetArgs } from '../../../Interfaces/Accounting/ACForms.interfaces';

const PayrollTimesheet = () => {
  const { selectedCompany } = useCompanyContext();
  const [periodStart, setPeriodStart] = useState<Date>();
  const [periodEnd, setPeriodEnd] = useState<Date>();
  const [timesheetTemplate, setTimesheetTemplate] = useState<TimeSheetArgs>();
  const [defaultLoading, setDefaultLoading] = useState<boolean>(false);
  const methods = useForm<PRTimesheetFilters>();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const prevSelectedCompanyRef = useRef<string | null>(null);

  const changeDates = (periodStart: Date, periodEnd: Date) => {
    setPeriodStart(periodStart);
    setPeriodEnd(periodEnd);
  };

  useEffect(() => {
    if (prevSelectedCompanyRef.current === null) {
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    } else if (prevSelectedCompanyRef.current !== selectedCompany?.id) {
      methods.reset();
      prevSelectedCompanyRef.current = selectedCompany?.id || null;
    }
  }, [selectedCompany, methods]);

  return (
    <div className="flex flex-column justify-content-center gap-2 pt-4">
      <div className="text-35px font-bold text-center mb-2">
        New Payroll Timesheet
      </div>
      <div>
        <PayPeriodDates storeDates={changeDates} />
      </div>
      <div className="mx-3 mt-3">
        <TimesheetTemplate
          storeTemplate={(value) => {
            setTimesheetTemplate(value);
          }}
          updateLoading={(value) => {
            setDefaultLoading(value);
          }}
        />
      </div>
      <FormProvider {...methods}>
        <div className="mt-3 px-3 flex w-full justify-content-between">
          <FiltersMenu>
            <TimesheetFilters />
          </FiltersMenu>
          <TimesheetReport
            elementRef={tableRef}
            periodStart={periodStart!}
            periodEnd={periodEnd!}
          />
        </div>
        <div className="mt-1 printBackground pdfDivFitContent" ref={tableRef}>
          {
            <TimesheetReportHeader
              periodStart={periodStart!}
              periodEnd={periodEnd!}
            />
          }
          <ProcessContextProvider>
            <TimeSheetTableContainer
              periodStart={periodStart!}
              periodEnd={periodEnd!}
              defaultTimesheet={timesheetTemplate}
              defaultLoading={defaultLoading}
            />
          </ProcessContextProvider>
        </div>
        <PRTimesheetParams />
      </FormProvider>
    </div>
  );
};

export default PayrollTimesheet;
