import React, { useCallback, useState } from 'react';
import { Column } from 'primereact/column';
import Table from '../../../components/Table/Table';
import {
  formatCurrency,
  formatNamesToInitials,
  formatPercentage,
} from '../../../../utils/formatUtils';
import {
  DataTableRowClassNameOptions,
  DataTableRowMouseEventParams,
} from 'primereact/datatable';
import { animateCellText } from '../../../../utils/htmlUtils';
import { JCContractResume } from '../../../Interfaces/Accounting/JobsContracts.interface';
import JCBillReceiptsDrilldownContainer from '../JCBillReceiptsDrilldown/JCBillReceiptsDrilldownContainer';
import JCCostDrilldownContainer from '../JCCostDrilldown/JCCostDrilldownContainer';
import CIDrilldownContainer from '../ContractItems/CIDrilldownContainer';
import JCCEDrilldownContainer from '../JCCostEstimate/JCCEDrilldownContainer';
import JCPayablePerJobDrilldownContainer from '../JCPayablePerJob/JCPayablePerJobDrilldownContainer';
import WrapperButton from '../../../components/buttons/WrapperButton';
import { useScreenWidthDetector } from '../../../hooks/useScreenWidthDetector';
import Attachment from '../../../components/attachment/Attachment';
import TooltipContent from '../../../components/messages/TooltipContent';

type JCContractAnalysisProps = {
  contracts: JCContractResume[];
};

const JCContractAnalysis = React.forwardRef<
  HTMLDivElement,
  JCContractAnalysisProps
>(({ contracts }, ref) => {
  const { isDesktop } = useScreenWidthDetector();
  const [selectedContract, setSelectedContract] = useState<{
    contract: string;
    contractName: string;
  }>();
  const [selectedDrilldown, setSelectedDrilldown] = useState('');
  const [showPayable, setShowPayable] = useState('');

  const calcHeight = (rows: number) => {
    const headerHeight = 49;
    const footerHeight = 54;
    const rowHeight = 31;
    return headerHeight + footerHeight + rows * rowHeight + 3;
  };

  const onRowHover = (e: DataTableRowMouseEventParams) => {
    const cell = e.originalEvent.target as HTMLElement;
    const row = cell.closest('tr') as HTMLElement;
    animateCellText(row);
  };

  const formatPmNames = (names: string[]) => {
    return names
      .map((pm: string) => {
        return formatNamesToInitials(pm);
      })
      .join(',');
  };

  const onDrilldownCellClick = (
    drilldownType: string,
    contract: string,
    contractName: string,
    payableType?: string
  ) => {
    setSelectedDrilldown(drilldownType);
    if (drilldownType === 'payable' && payableType) {
      setShowPayable(payableType);
    }
    setSelectedContract({ contract, contractName });
  };

  const hideSelectedDrilldown = () => {
    setSelectedContract(undefined);
    setSelectedDrilldown('');
    setShowPayable('');
  };

  let contractAmnt = 0;
  let billed = 0;
  let pending = 0;
  let estimated = 0;
  let costToDate = 0;
  let estimatedProfit = 0;
  let jtd = 0;
  let outAR = 0;
  let retainageAR = 0;
  let outAP = 0;
  let retainageAP = 0;
  let net = 0;
  contracts.forEach((contract) => {
    contractAmnt = contractAmnt + contract.ContractAmount;
    billed = billed + contract.BilledAmount;
    pending = pending + contract.PendingAmount;
    estimated = estimated + contract.EstimatedCost;
    costToDate = costToDate + contract.CostToDate;
    estimatedProfit = estimatedProfit + contract.EstimatedProfit;
    jtd = jtd + contract.JTDProfit;
    outAR = outAR + contract.OutstandingAR;
    retainageAR = retainageAR + contract.RetainageAR;
    outAP = outAP + contract.OutstandingAP;
    retainageAP = retainageAP + contract.RetainageAP;
    net = net + contract.NetCashFlow;
  });

  return (
    <div className="pdfDivFitContent">
      {selectedDrilldown === 'details' && selectedContract && (
        <JCCostDrilldownContainer
          visible={true}
          hideDrilldown={hideSelectedDrilldown}
          costDetail={{
            job: selectedContract.contract,
            jobName: selectedContract.contractName,
          }}
        />
      )}
      {selectedDrilldown === 'b&r' && selectedContract && (
        <JCBillReceiptsDrilldownContainer
          visible={true}
          hideDrilldown={hideSelectedDrilldown}
          {...selectedContract}
        />
      )}
      {selectedDrilldown === 'items' && selectedContract && (
        <CIDrilldownContainer
          visible={true}
          hideDrilldown={hideSelectedDrilldown}
          {...selectedContract}
        />
      )}
      {selectedDrilldown === 'estimates' && selectedContract && (
        <JCCEDrilldownContainer
          visible={true}
          hideDrilldown={hideSelectedDrilldown}
          {...selectedContract}
        />
      )}
      {selectedDrilldown === 'payable' && selectedContract && (
        <JCPayablePerJobDrilldownContainer
          visible={true}
          hideDrilldown={hideSelectedDrilldown}
          showRetainage={showPayable === 'retainage'}
          showOpenAmount={showPayable === 'openAmount'}
          {...selectedContract}
        />
      )}
      <Table
        id="jc-contract-analysis"
        ref={ref}
        data={contracts}
        className={`mx-3 dashboardOptionShadow dobleHeader hoverEffect no-row-focus`}
        calcHeight={useCallback(calcHeight, [])}
        onRowMouseEnter={useCallback(onRowHover, [])}
        selectionKey="Contract"
        rowClass={useCallback(
          (data: unknown, options?: DataTableRowClassNameOptions) => {
            const contract = data as JCContractResume;
            const selectedRows = options?.props.selection;
            return selectedRows[contract.Contract] ? 'focus-row' : '';
          },
          []
        )}
        selectionMode="single"
        hideColumns={true}
      >
        <Column
          field="Contract"
          header="Contract #"
          style={{ minWidth: '110px' }}
          frozen
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell"
          footerClassName="tableFooter z-1"
          sortable
        />
        <Column
          field="ContractName"
          header="Contract Name"
          body={(jb) => {
            return <div className="scroll-text">{jb.ContractName}</div>;
          }}
          style={{ minWidth: '210px', maxWidth: '210px' }}
          frozen={isDesktop}
          headerClassName="tableHeader font-normal justify-content-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow"
          footerClassName="tableFooter z-1"
          sortable
        />
        <Column
          field="PM"
          header="PM"
          body={(jb) => {
            return (
              <div className="scroll-text">
                {jb.PM ? formatPmNames(jb.PM) : ''}
              </div>
            );
          }}
          style={{ minWidth: '80px' }}
          headerClassName="tableHeader font-normal text-center"
          className="text-standard blackText tableCell overflow-x-hidden white-space-nowrap checkOverflow justify-content-center"
          footer="Total"
          footerClassName="overflow-x-visible tableFooter block text-right border-top-2 border-transparent mt-4 limitBorder relative"
          sortable
        />
        <Column
          field="PBilled"
          header="% Billed"
          body={(jb) => formatPercentage(jb.PBilled)}
          style={{ minWidth: '100px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-blue-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatPercentage((billed / contractAmnt) * 100)}
          sortable
        />
        <Column
          field="ContractAmount"
          header="Contract Amount"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick('items', jc.Contract, jc.ContractName)
              }
            >
              {formatCurrency(jc.ContractAmount)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-blue-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(contractAmnt)}
          sortable
        />
        <Column
          field="BilledAmount"
          header="Billed To Date"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick('b&r', jc.Contract, jc.ContractName)
              }
            >
              {formatCurrency(jc.BilledAmount)}
            </WrapperButton>
          )}
          style={{ minWidth: '135px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-blue-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(billed)}
          sortable
        />
        <Column
          field="PendingAmount"
          header="Remaining to be Billed"
          body={(jb) => formatCurrency(jb.PendingAmount)}
          style={{ minWidth: '135px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-blue-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(pending)}
          sortable
        />
        <Column
          field="EstimatedCost"
          header="Estimated Total Cost"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick('estimates', jc.Contract, jc.ContractName)
              }
            >
              {formatCurrency(jc.EstimatedCost)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-teal-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(estimated)}
          sortable
        />
        <Column
          field="CostToDate"
          header="Job Cost To Date"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick('details', jc.Contract, jc.ContractName)
              }
            >
              {formatCurrency(jc.CostToDate)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-teal-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(costToDate)}
          sortable
        />
        <Column
          field="EstimatedProfit"
          header="Estimated Gross Profit"
          body={(jb) => formatCurrency(jb.EstimatedProfit)}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-orange-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(estimatedProfit)}
          sortable
        />
        <Column
          field="PEstimatedProfit"
          header="Estimated GP %"
          body={(jb) => formatPercentage(jb.PEstimatedProfit)}
          style={{ minWidth: '120px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-orange-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatPercentage((estimatedProfit / contractAmnt) * 100)}
          sortable
        />
        <Column
          field="JTDProfit"
          header="JTD Gross Profit"
          body={(jb) => (
            <div className={`${jb.PJTDProfit < 10 && 'text-red-500'}`}>
              {formatCurrency(jb.JTDProfit)}
            </div>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-red-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(jtd)}
          sortable
        />
        <Column
          field="PJTDProfit"
          header="JTD Gross Profit  %"
          body={(jb) => (
            <div className={`${jb.PJTDProfit < 10 && 'text-red-500'}`}>
              {formatPercentage(jb.PJTDProfit)}
            </div>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-red-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatPercentage((jtd / billed) * 100)}
          sortable
        />
        <Column
          field="NetCashFlow"
          header="Net Cash Flow"
          body={(jb) => (
            <div className={`${jb.NetCashFlow < 0 && 'text-red-500'}`}>
              {formatCurrency(jb.NetCashFlow)}
            </div>
          )}
          style={{ minWidth: '130px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-indigo-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(net)}
          sortable
        />
        <Column
          field="OutstandingAR"
          header="Outstanding A/R"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick('b&r', jc.Contract, jc.ContractName)
              }
            >
              {formatCurrency(jc.OutstandingAR)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-yellow-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(outAR)}
          sortable
        />
        <Column
          field="RetainageAR"
          header="A/R Retainage"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick('b&r', jc.Contract, jc.ContractName)
              }
            >
              {formatCurrency(jc.RetainageAR)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-yellow-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(retainageAR)}
          sortable
        />
        <Column
          field="OutstandingAP"
          header="Outstanding A/P"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick(
                  'payable',
                  jc.Contract,
                  jc.ContractName,
                  'openAmount'
                )
              }
            >
              {formatCurrency(jc.OutstandingAP)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-pink-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(outAP)}
          sortable
        />
        <Column
          field="RetainageAP"
          header="A/P Retainage"
          body={(jc) => (
            <WrapperButton
              className="totalColor w-full text-right printColor"
              onClick={() =>
                onDrilldownCellClick(
                  'payable',
                  jc.Contract,
                  jc.ContractName,
                  'retainage'
                )
              }
            >
              {formatCurrency(jc.RetainageAP)}
            </WrapperButton>
          )}
          style={{ minWidth: '140px' }}
          headerClassName="tableHeader font-normal text-center justify-content-center bg-pink-300"
          className="justify-content-end text-standard blackText tableCell"
          footerClassName="tableFooter block text-right border-top-2 mt-4"
          footer={formatCurrency(retainageAP)}
          sortable
        />
        <Column
          style={{ minWidth: '40px', maxWidth: '40px' }}
          body={(jc) =>
            jc.Attachment ? (
              <Attachment
                uniqueAttchID={jc.Attachment}
                zipFilename={jc.ContractName}
              />
            ) : (
              <div className="text-red-600 font-bold">
                <TooltipContent
                  label="!"
                  content={`No contract file was found!`}
                  id={jc.Contract.replace(/-/g, '')}
                />
              </div>
            )
          }
          headerClassName="tableHeader font-normal"
          className="justify-content-center text-standard blackText tableCell printHide"
          footerClassName="tableFooter"
        />
      </Table>
    </div>
  );
});

const transactionsAreEqual = (
  prevTransactions: Readonly<JCContractAnalysisProps>,
  nextTransactions: Readonly<JCContractAnalysisProps>
) => {
  return prevTransactions.contracts === nextTransactions.contracts;
};

export default React.memo(JCContractAnalysis, transactionsAreEqual);
