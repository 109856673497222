import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadFile } from '../../utils/fileUtil';
import { useCompanyContext } from '../context/CompanyContext';
import {
  fetchAttachmentFile,
  fetchAttachmentFiles,
  fetchAttachmentsIDs,
} from '../services/AttachmentService';

type useFetchAttachmentsArgs = {
  uniqueAttchID: string;
  zipFilename: string;
  latest?: boolean;
};

const useFetchAttachments = ({
  uniqueAttchID,
  zipFilename,
  latest,
}: useFetchAttachmentsArgs) => {
  const { selectedCompany } = useCompanyContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const attachID = useMutation({
    mutationFn: (args: { id: number; fileName: string }) => {
      return fetchAttachmentFile(args.id);
    },
    onSuccess: (response, vars) => {
      downloadFile(response, vars.fileName);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setError('An error ocurred while downloading the file! Try again later');
    },
  });

  const files = useMutation({
    mutationFn: (attachments: { id: number; name: string }[]) => {
      return fetchAttachmentFiles(attachments, zipFilename);
    },
    onSuccess: (data) => {
      downloadFile(data);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setError('An error ocurred while downloading the files! Try again later');
    },
  });

  const { mutate } = useMutation({
    mutationFn: (uniqueAttchID: string) => {
      return fetchAttachmentsIDs(selectedCompany!.id, uniqueAttchID, latest);
    },
    onSuccess: (data) => {
      if (data.length > 1) {
        const attachments = data.map(
          (element: { AttachmentID: number; FileName: string }) => ({
            id: element.AttachmentID,
            name: element.FileName,
          })
        );
        files.mutate(attachments);
      } else {
        attachID.mutate({
          id: data[0]?.AttachmentID,
          fileName: data[0]?.FileName,
        });
      }
    },
    onError: () => {
      setLoading(false);
      setError(
        'An error ocurred while searching for the file! Try again later'
      );
    },
  });

  const fetchAttachments = () => {
    setLoading(true);
    mutate(uniqueAttchID);
  };

  return { fetchAttachments, loading, error };
};

export default useFetchAttachments;
