import {
  EmployeeTimesheet,
  PayrollTimesheetDetail,
  PayrollTimesheetDetailArgs,
  PayrollTimesheetDetails,
  PRTimesheetSummary,
  TimeSheetDetailsArgs,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { formatUTCDate } from 'apps/tmr-frontend/src/utils/formatUtils';
import { transformUTCtoLocale } from 'apps/tmr-frontend/src/utils/dateUtils';

const resetTimesheetDetails = (data: PayrollTimesheetDetail) => {
  delete data.job;
  delete data.phaseCode;
  delete data.regularHrs;
  delete data.overTimeHrs;
  delete data.sickHrs;
  delete data.holidayHrs;
  delete data.perDiem;

  data.totalHrs = 0;
};

const subtractAmounts = (
  target: PayrollTimesheetDetail,
  removed: PayrollTimesheetDetail
) => {
  if (target.regularHrs && removed.regularHrs) {
    target.regularHrs -= removed.regularHrs;
    if (!target.regularHrs) {
      delete target.regularHrs;
    } else {
      target.regularHrs = roundHours(target.regularHrs);
    }
  }

  if (target.overTimeHrs && removed.overTimeHrs) {
    target.overTimeHrs -= removed.overTimeHrs;
    if (!target.overTimeHrs) {
      delete target.overTimeHrs;
    } else {
      target.overTimeHrs = roundHours(target.overTimeHrs);
    }
  }

  if (target.sickHrs && removed.sickHrs) {
    target.sickHrs -= removed.sickHrs;
    if (!target.sickHrs) {
      delete target.sickHrs;
    } else {
      target.sickHrs = roundHours(target.sickHrs);
    }
  }

  if (target.holidayHrs && removed.holidayHrs) {
    target.holidayHrs -= removed.holidayHrs;
    if (!target.holidayHrs) {
      delete target.holidayHrs;
    } else {
      target.holidayHrs = roundHours(target.holidayHrs);
    }
  }

  if (target.perDiem && removed.perDiem) {
    target.perDiem -= removed.perDiem;
    if (!target.perDiem) {
      delete target.perDiem;
    } else {
      target.perDiem = target.perDiem;
    }
  }

  if (target.totalHrs && removed.totalHrs) {
    target.totalHrs -= removed.totalHrs;
    if (!target.totalHrs) {
      delete target.totalHrs;
    } else {
      target.totalHrs = roundHours(target.totalHrs);
    }
  }
};

const addAmounts = (
  target: PayrollTimesheetDetail,
  data: PayrollTimesheetDetail
) => {
  if (data.regularHrs !== undefined) {
    target.regularHrs = (target.regularHrs ?? 0) + data.regularHrs;
    target.regularHrs = roundHours(target.regularHrs);
  }

  if (data.overTimeHrs !== undefined) {
    target.overTimeHrs = (target.overTimeHrs ?? 0) + data.overTimeHrs;
    target.overTimeHrs = roundHours(target.overTimeHrs);
  }

  if (data.sickHrs !== undefined) {
    target.sickHrs = (target.sickHrs ?? 0) + data.sickHrs;
    target.sickHrs = roundHours(target.sickHrs);
  }

  if (data.holidayHrs !== undefined) {
    target.holidayHrs = (target.holidayHrs ?? 0) + data.holidayHrs;
    target.holidayHrs = roundHours(target.holidayHrs);
  }

  if (data.perDiem !== undefined) {
    target.perDiem = (target.perDiem ?? 0) + data.perDiem;
  }

  if (data.totalHrs !== undefined) {
    target.totalHrs = (target.totalHrs ?? 0) + data.totalHrs;
    target.totalHrs = roundHours(target.totalHrs);
  }
};

const findEntities = (
  data: EmployeeTimesheet[],
  empID: string,
  detailID?: string,
  dateDetailID?: string
) => {
  const emp = data.find((emp) => emp.empID === empID);

  if (!emp) {
    return {};
  }

  if (detailID) {
    const details = emp.details ?? [];
    const detail = details.find((detail) => detail.id === detailID);
    if (!detail) {
      return { emp };
    }

    if (dateDetailID) {
      const dateDetails = detail.dateDetails ?? [];
      const dateDetail = dateDetails.find(
        (dateDet) => dateDet.id === dateDetailID
      );

      if (!dateDetail) {
        return { emp, detail };
      }

      return { emp, detail, dateDetail };
    }

    return { emp, detail };
  }

  return { emp };
};

const setEmployeeUseDetails = (emp: EmployeeTimesheet) => {
  const details = emp.details || [];
  details.forEach((detail) => {
    if (detail.date) {
      const dateDetails = detail.dateDetails || [];
      dateDetails.forEach((dateDet) => {
        if (
          dateDet.totalHrs ||
          dateDet.job ||
          dateDet.phaseCode ||
          dateDet.perDiem
        ) {
          detail.useDateDetails = true;
        }
      });
    }

    if (
      detail.totalHrs ||
      detail.date ||
      detail.job ||
      detail.phaseCode ||
      detail.perDiem
    ) {
      emp.useDetails = true;
    }
  });
};

const isEmployeeDetailActive = (detail: PayrollTimesheetDetail) => {
  return !!(
    detail.totalHrs ||
    detail.job ||
    detail.phaseCode ||
    detail.perDiem
  );
};

export const getEmployeeActiveDetailsNumber = (emp: EmployeeTimesheet) => {
  let counter = 0;
  const details = emp.details || [];

  details.forEach((detail) => {
    if (isEmployeeDetailActive(detail) || detail.date) {
      counter += 1;
    }
  });

  return counter;
};

export const updateTimesheetValue = (
  entity: PayrollTimesheetDetail,
  key: string,
  value?: string
) => {
  const typedKey = key as keyof PayrollTimesheetDetail;

  if (value) {
    Object.assign(entity, { [key]: value });
  } else if (entity[typedKey]) {
    delete entity[typedKey];
  } else {
    return false;
  }

  return true;
};

export const modifyTimesheetValue = (
  empID: string,
  data: EmployeeTimesheet[],
  key: keyof PayrollTimesheetDetail,
  value?: string | number,
  detailID?: string,
  dateDetailID?: string
) => {
  const empList = [...data];
  const isNumberKey = key !== 'job' && key !== 'phaseCode' && key !== 'id';
  const sumTotal = key !== 'perDiem';
  const numberValue = (value as number) ?? undefined;

  const { emp, detail, dateDetail } = findEntities(
    empList,
    empID,
    detailID,
    dateDetailID
  );

  if (!emp) {
    return { error: 'No employee was found!' };
  }

  if (detailID && !detail) {
    return { error: 'No employee detail was found!' };
  }

  if (dateDetailID && !dateDetail) {
    return { error: 'No employee date detail was found!' };
  }

  const entity = dateDetail ?? detail ?? emp;
  if (!isNumberKey && (typeof value === 'string' || value === undefined)) {
    const wasUpdated = updateTimesheetValue(entity, key, value);

    if (wasUpdated) {
      emp.useDetails = false;
      if (detail) {
        detail.useDateDetails = false;
      }

      setEmployeeUseDetails(emp);
    }

    return wasUpdated ? { data: empList } : { data: null };
  }

  const prevValue = (entity[key] as number) ?? 0;
  const newSum = numberValue - prevValue;

  if (!isNaN(numberValue)) {
    if (dateDetail) {
      subtractAmounts(emp, detail);
      if (!detail?.useDateDetails) {
        detail!.useDateDetails = true;
        emp.useDetails = true;
        resetTimesheetDetails(detail!);
      } else {
        subtractAmounts(detail, dateDetail);
      }

      Object.assign(dateDetail, { [key]: value });
      if (sumTotal) {
        dateDetail.totalHrs = (dateDetail.totalHrs ?? 0) + newSum;
        dateDetail.totalHrs = roundHours(dateDetail.totalHrs);
      }

      addAmounts(detail, dateDetail);
      addAmounts(emp, detail);

      return { data: empList };
    }

    if (detail) {
      if (!emp.useDetails) {
        emp.useDetails = true;
        resetTimesheetDetails(emp);
      } else {
        subtractAmounts(emp, detail);
      }

      Object.assign(detail, { [key]: value });
      if (sumTotal) {
        detail.totalHrs = (detail.totalHrs ?? 0) + newSum;
        detail.totalHrs = roundHours(detail.totalHrs);
      }
      addAmounts(emp, detail);

      return { data: empList };
    }

    Object.assign(entity, { [key]: value });
    if (sumTotal) {
      emp.totalHrs = (emp.totalHrs ?? 0) + newSum;
      emp.totalHrs = roundHours(emp.totalHrs);
    }

    return { data: empList };
  }

  if (entity[key] !== undefined) {
    if (dateDetail) {
      subtractAmounts(emp, detail);
      subtractAmounts(detail, dateDetail);
      delete dateDetail[key];

      if (sumTotal) {
        const newTotal = dateDetail.totalHrs! - prevValue;
        dateDetail.totalHrs = newTotal ? roundHours(newTotal) : undefined;
      }

      addAmounts(detail, dateDetail);
      addAmounts(emp, detail);
      detail.useDateDetails = !!detail.totalHrs || !!detail.perDiem;
      emp.useDetails = !!emp.totalHrs || !!emp.perDiem;

      return { data: empList };
    }

    if (detail) {
      subtractAmounts(emp, detail);
      delete detail[key];

      if (sumTotal) {
        const newTotal = detail.totalHrs! - prevValue;
        detail.totalHrs = newTotal ? roundHours(newTotal) : undefined;
      }

      addAmounts(emp, detail);
      emp.useDetails = !!emp.totalHrs || !!emp.perDiem;

      return { data: empList };
    }

    delete emp[key];
    if (sumTotal) {
      const newTotal = emp.totalHrs! - prevValue;
      emp.totalHrs = newTotal ? roundHours(newTotal) : undefined;
    }

    return { data: empList };
  }

  return { data: null };
};

export const changeTimesheetDate = (
  data: EmployeeTimesheet[],
  empID: string,
  detailID: string,
  date?: Date
) => {
  const empList = [...data];
  const { emp, detail } = findEntities(empList, empID, detailID);

  if (!emp) {
    return;
  }

  const details = emp.details ?? [];
  if (!detail) {
    return;
  }

  if (date) {
    if (!emp.useDetails) {
      emp.useDetails = true;
      resetTimesheetDetails(emp);
      delete emp.totalHrs;
    }

    if (!detail['date']) {
      const dateDetails = detail.dateDetails ?? [];
      const totals = dateDetails.reduce(
        (ac, dateDet) => {
          addAmounts(ac, dateDet);

          return ac;
        },
        {
          regularHrs: undefined,
          overTimeHrs: undefined,
          sickHrs: undefined,
          holidayHrs: undefined,
          perDiem: undefined,
          totalHrs: undefined,
        }
      );

      if (totals['totalHrs']) {
        subtractAmounts(emp, detail);
        resetTimesheetDetails(detail);
        addAmounts(detail, totals);
        addAmounts(emp, detail);
        detail.useDateDetails = true;
      }
    }

    detail['date'] = date;
    const position = details.findIndex((detail) => detail.id === detailID);
    if (details.length === position + 1) {
      details.push({
        id: uuidv4(),
        dateDetails: [{ id: uuidv4() }],
      });
    }
  } else {
    delete detail.date;
    emp.useDetails = false;
    setEmployeeUseDetails(emp);
    detail.useDateDetails = false;
  }

  return empList;
};

export const addNewDetail = (
  data: EmployeeTimesheet[],
  empID: string,
  detailID?: string
) => {
  const empList = [...data];
  const { emp, detail } = findEntities(empList, empID, detailID);

  if (!emp) {
    return;
  }

  if (detailID) {
    if (!detail) {
      return;
    }

    detail.dateDetails!.push({ id: uuidv4() });
  } else {
    emp.details!.push({
      id: uuidv4(),
      dateDetails: [{ id: uuidv4() }],
    });
  }

  return empList;
};

export const removeDetail = (
  data: EmployeeTimesheet[],
  empID: string,
  detailID: string,
  dateDetailID?: string
) => {
  const empList = [...data];
  const { emp, detail, dateDetail } = findEntities(
    empList,
    empID,
    detailID,
    dateDetailID
  );

  if (!emp) {
    return;
  }

  if (dateDetail) {
    const newDateDetails = detail!.dateDetails!.filter(
      (dateDet) => dateDet.id !== dateDetailID
    );

    subtractAmounts(detail, dateDetail);
    subtractAmounts(emp, dateDetail);

    detail.dateDetails = newDateDetails;
    detail.useDateDetails = false;
    emp.useDetails = false;
  } else if (detail) {
    const newDetails = emp.details!.filter((detail) => detail.id !== detailID);
    subtractAmounts(emp, detail);

    emp.details = newDetails;
    emp.useDetails = false;
  } else {
    return;
  }

  setEmployeeUseDetails(emp);

  return empList;
};

export const addDetailPerDateRange = (
  data: EmployeeTimesheet[],
  empID: string,
  dates: Date[]
) => {
  const empList = [...data];
  const { emp } = findEntities(empList, empID);

  if (!emp) {
    return;
  }

  const details: PayrollTimesheetDetails[] = emp.details ?? [];
  const periodsTimeDiff = dates[1].getTime() - dates[0].getTime();
  const periodDayDiff = Math.ceil(periodsTimeDiff / (1000 * 3600 * 24));

  for (let i = 0; i <= periodDayDiff; i++) {
    const date = new Date(dates[0]);
    date.setDate(date.getDate() + i);

    if (
      !details.some((detail) =>
        detail.date ? new Date(detail.date).getDate() === date.getDate() : false
      )
    ) {
      details.push({
        id: uuidv4(),
        dateDetails: [{ id: uuidv4() }],
        date,
      });
    }
  }

  const sortedDetails = details.sort((a, b) => {
    if (!a.date && b.date) {
      return 1;
    }

    if (a.date && !b.date) {
      return -1;
    }

    if (a.date && b.date) {
      return a.date.getTime() - b.date.getTime();
    }

    return 0;
  });

  emp.details = sortedDetails;
  emp.useDetails = true;

  return empList;
};

export const getSubmittedDraft = (
  seledtedDraft: string,
  data: PRTimesheetSummary[]
) => {
  const draft = data?.find((draft) => draft.id === seledtedDraft);

  return `${formatUTCDate(draft?.updatedAt ?? '')} - ${draft?.userName}`;
};

export const formatTimesheetEmployees = (data: EmployeeTimesheet[]) => {
  return data.map((emp) => {
    const details = emp.details ?? [];

    details.forEach((detail) => {
      emp.useDetails = true;

      if (!detail.id) {
        detail.id = uuidv4();
      }

      if (!detail.dateDetails) {
        detail.dateDetails = [{ id: uuidv4() }];
      } else {
        detail.dateDetails.forEach((dateDet) => {
          if (!dateDet.id) {
            dateDet.id = uuidv4();
          }
        });

        detail.dateDetails.push({ id: uuidv4() });
        detail.useDateDetails = true;
      }

      if (detail.date) {
        detail.date = transformUTCtoLocale(detail.date);
      }
    });

    const sortedDetails = details.sort((a, b) => {
      if (!a.date && b.date) {
        return 1;
      }

      if (a.date && !b.date) {
        return -1;
      }

      if (a.date && b.date) {
        return a.date.getTime() - b.date.getTime();
      }

      return 0;
    });

    sortedDetails.push({
      id: uuidv4(),
      dateDetails: [{ id: uuidv4() }],
    });

    emp.details = sortedDetails;

    return emp;
  });
};

export const checkTimesheetContent = (draft: EmployeeTimesheet[]) => {
  let missingHours = false;
  let missingPhases = false;

  const checkMissingHours = (detail: PayrollTimesheetDetail) => {
    const { totalHrs } = detail;
    missingHours = missingHours || totalHrs === undefined;
  };

  const checkMissingPhases = (detail: PayrollTimesheetDetail) => {
    missingPhases =
      missingPhases ||
      (!!detail.job && detail.job !== 'N/A' && !detail.phaseCode);
  };

  draft.forEach((emp) => {
    const { useDetails, details } = emp;

    if (useDetails) {
      details?.forEach((det) => {
        const detKeys = Object.keys(det);
        const { useDateDetails, dateDetails } = det;

        if (useDateDetails) {
          dateDetails?.forEach((dateDet) => {
            const dateDetkeys = Object.keys(dateDet);

            if (dateDetkeys.length > 1) {
              checkMissingHours(dateDet);
              checkMissingPhases(dateDet);
            }
          });
        } else if (detKeys.length > 2) {
          checkMissingHours(det);
          checkMissingPhases(det);
        }
      });
    } else {
      checkMissingHours(emp);
      checkMissingPhases(emp);
    }
  });

  return { missingHours, missingPhases };
};

export const generateConfirmationMessage = (
  defaultText: string,
  draft: EmployeeTimesheet[]
) => {
  let initial = defaultText;
  const { missingHours } = checkTimesheetContent(draft);

  if (!missingHours) {
    return initial;
  }

  initial = `${initial}\n\n The timesheet is missing hours for active employees.`;

  return initial;
};

export const prepareTimesheetPayload = (draft: EmployeeTimesheet[]) => {
  const processedDraft = draft.map((emp) => {
    const {
      firstName,
      lastName,
      useDetails,
      details,
      totalHrs,
      empID,
      salaried,
      ...otherFields
    } = emp;

    if (useDetails) {
      const empNewDetails: TimeSheetDetailsArgs[] = [];
      details?.forEach((det) => {
        const { useDateDetails, dateDetails, id, totalHrs, ...otherFields } =
          det;

        if (useDateDetails) {
          const empNewDateDetails: PayrollTimesheetDetailArgs[] = [];
          dateDetails?.forEach((dateDet) => {
            const { totalHrs, id, ...otherFields } = dateDet;
            const keysAmount = Object.keys(otherFields);

            if (keysAmount.length > 0) {
              empNewDateDetails.push(otherFields);
            }
          });

          empNewDetails.push({
            date: otherFields.date,
            dateDetails: empNewDateDetails,
          });
        } else {
          const keysAmount = Object.keys(otherFields);

          if (keysAmount.length > 0) {
            empNewDetails.push({
              ...otherFields,
            });
          }
        }
      });

      return { empID: `${empID}`, details: empNewDetails };
    } else {
      return { empID: `${empID}`, ...otherFields };
    }
  });

  return processedDraft;
};

export const moveEmployeeValuesToDetail = (employee: EmployeeTimesheet) => {
  const employeeDetails = employee.details ?? [];

  if (
    !employee.useDetails &&
    (employee.totalHrs || employee.job || employee.perDiem)
  ) {
    const firstDetail = employeeDetails[0] ?? {};
    firstDetail.regularHrs = employee.regularHrs;
    firstDetail.overTimeHrs = employee.overTimeHrs;
    firstDetail.sickHrs = employee.sickHrs;
    firstDetail.holidayHrs = employee.holidayHrs;
    firstDetail.job = employee.job;
    firstDetail.phaseCode = employee.phaseCode;
    firstDetail.perDiem = employee.perDiem;
    firstDetail.totalHrs = employee.totalHrs;
    delete employee.job;
    delete employee.phaseCode;
    employee.useDetails = true;
  }
};

export const moveDetailValuesToEmployee = (employee: EmployeeTimesheet) => {
  const employeeDetails = employee.details ?? [];
  const firstDetail = employeeDetails[0] ?? {};

  if (
    employee.useDetails &&
    getEmployeeActiveDetailsNumber(employee) === 1 &&
    isEmployeeDetailActive(firstDetail) &&
    !firstDetail.date
  ) {
    employee.job = firstDetail.job;
    employee.phaseCode = firstDetail.phaseCode;
    delete firstDetail.regularHrs;
    delete firstDetail.overTimeHrs;
    delete firstDetail.sickHrs;
    delete firstDetail.holidayHrs;
    delete firstDetail.totalHrs;
    delete firstDetail.job;
    delete firstDetail.phaseCode;
    delete firstDetail.perDiem;
    employee.useDetails = false;
  }
};

export const roundHours = (hours: number) => {
  return Math.round(hours * 100) / 100;
};
