import { useQuery } from '@tanstack/react-query';
import DropdownStyled from 'apps/tmr-frontend/src/app/components/inputs/StyledInputs/DropdownStyled';
import FormErrorMessage from 'apps/tmr-frontend/src/app/components/messages/FormErrorMessage';
import { useCompanyContext } from 'apps/tmr-frontend/src/app/context/CompanyContext';
import { GLAccount as GLAccountType } from 'apps/tmr-frontend/src/app/Interfaces/Accounting/AccountsPayables.interfaces';
import { fecthGLAccounts } from 'apps/tmr-frontend/src/app/services/GLAccountService';

type GLAccountProps = {
  value: string;
  showErrors: boolean;
  disabled: boolean;
  changeValue: (glAccount?: string) => void;
  disableFetch?: boolean;
  searchBonus?: boolean;
};

const GLAccount = ({
  showErrors,
  disabled,
  value,
  changeValue,
  disableFetch,
  searchBonus,
}: GLAccountProps) => {
  const { selectedCompany } = useCompanyContext();

  const glAccounts = useQuery({
    queryKey: ['getPCRglAccounts', selectedCompany],
    queryFn: ({ signal }) =>
      fecthGLAccounts(
        selectedCompany!.id,
        searchBonus ? [61030] : [63, 64, 65, 66, 67, 68, 61110],
        signal
      ),
    refetchOnWindowFocus: false,
    enabled: !!selectedCompany && !disableFetch,
  });

  const glAccountsItems =
    glAccounts.data?.map((glAccount: GLAccountType) => {
      return {
        ...glAccount,
        label: `${glAccount.GLAcct} ${glAccount.Description}`,
      };
    }) ?? [];
  const selectedInfo = glAccountsItems?.find(
    (account: GLAccountType) => account.GLAcct === value
  );

  return (
    <div className="w-full h-full">
      <DropdownStyled
        options={glAccountsItems}
        labelField="label"
        valueField="GLAcct"
        disabled={disabled}
        isLoading={glAccounts.isLoading}
        error={glAccounts.isError}
        className={`h-full printHide ${showErrors && 'p-invalid'}`}
        filter={true}
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
        tooltip={`Job or OverHead is required`}
        tooltipOptions={{
          position: 'bottom',
          event: 'hover',
          disabled: !showErrors,
        }}
      />
      <span className="printMedia printShow">{selectedInfo?.label}</span>
    </div>
  );
};

export default GLAccount;
