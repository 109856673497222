import { formatDate } from '../../../../../utils/formatUtils';
import { Panel } from 'primereact/panel';
import { useFormContext } from 'react-hook-form';
import FormInfo from '../../../../components/messages/FormInfo';
import { POGeneralInfo } from '../../../../Interfaces/Accounting/ACForms.interfaces';
import { useQuery } from '@tanstack/react-query';
import { useCompanyContext } from '../../../../context/CompanyContext';
import { fecthJobs } from '../../../../services/JobsContractsService';
import { JCJobSummary } from '../../../../Interfaces/Accounting/JobsContracts.interface';
import { APVendorSummary } from '../../../../Interfaces/Accounting/AccountsPayables.interfaces';
import { PO_TYPES } from '../FormElements/GeneralInfoForm';
import { fetchVendors } from '../../../../services/vendorsService';
import { getVendorKeyPerCompany } from '../../../accountsPayables/apUtil';
import { getSource } from 'apps/tmr-frontend/src/utils/company';

const GeneralInfoSummary = () => {
  const { selectedCompany } = useCompanyContext();
  const vendorKey = getVendorKeyPerCompany(getSource(selectedCompany));
  const { getValues } = useFormContext<POGeneralInfo>();
  const {
    job,
    poType,
    poDate,
    multipleYN,
    identifier,
    poID,
    description,
    vendor,
  } = getValues();
  const month = new Date(poDate);
  month.setDate(1);

  const jobs = useQuery({
    queryKey: ['getPoJobs', selectedCompany],
    queryFn: ({ signal }) =>
      fecthJobs({ companyID: selectedCompany!.id, status: [1] }, signal),
    refetchOnWindowFocus: false,
    enabled: false,
    keepPreviousData: true,
  });

  const vendors = useQuery({
    queryKey: ['getPoVendors', selectedCompany, poType],
    queryFn: ({ signal }) => {
      return fetchVendors(
        {
          company: selectedCompany!,
          active: true,
          types: [poType],
        },
        signal
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
    keepPreviousData: true,
  });

  const jobData = jobs.data?.find((data: JCJobSummary) => data.Job === job);
  const vendorData = vendors.data?.find(
    (vend: APVendorSummary) => vend[vendorKey] === vendor
  );
  const poTypeData = PO_TYPES.find((type) => type.value === poType);

  return (
    <Panel header="General Info" className="max-w-1200px mx-3 text-standard">
      <div className="grid">
        <div className="col-12 sm:col-6">
          <FormInfo label="Job" content={job} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Project Name" content={jobData?.Description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="PO Type" content={poTypeData?.label} />
        </div>
        <div className="col-12 sm:col-6 printHide" />
        <div className="col-12 sm:col-6">
          <FormInfo label="Vendor" content={vendorData.name} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Vendor Short Name" content={vendorData.sortName} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="PO Date" content={formatDate(poDate)} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Batch Month" content={formatDate(month)} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="Multiple Bldg/Loc" content={multipleYN} />
        </div>
        <div
          className={`col-12 sm:col-6 ${multipleYN !== 'Yes' && 'printHide'}`}
        >
          {multipleYN === 'Yes' && (
            <FormInfo label="Add'l Identifier" content={identifier} />
          )}
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="PO Desc" content={description} />
        </div>
        <div className="col-12 sm:col-6">
          <FormInfo label="PO#" content={poID} />
        </div>
      </div>
    </Panel>
  );
};

export default GeneralInfoSummary;
