import {
  formatCurrency,
  formatDate,
} from 'apps/tmr-frontend/src/utils/formatUtils';
import { Column } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { useState } from 'react';
import CalendarStyled from '../../../components/inputs/StyledInputs/CalendarStyled';
import Table from '../../../components/Table/Table';
import {
  PayrollTimesheetDetail,
  PayrollTimesheetDetails,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import ExpandIcon from './ExpandIcon';
import JobInput from './JobInput';
import PhaseCodeInput from './PhaseCodeInput';
import RegularHoursInput from './RegularHoursInput';
import TimesheetDayDetailsTable from './TimesheetDateDetailsTable';

type TimesheetDetailsTableProps = {
  details: PayrollTimesheetDetails[];
  empID: string;
  maxDate?: Date;
  minDate?: Date;
  changeDateDetails: (empID: string, detailID: string, date?: Date) => void;
  changeTimesheetValue: (
    empID: string,
    key: keyof PayrollTimesheetDetail,
    value?: number,
    detailID?: string,
    dateDetailID?: string
  ) => void;
  addDetailRow: (empID: string, detailID?: string) => void;
  removeDetailRow: (
    empID: string,
    detailID: string,
    dateDetailID?: string
  ) => void;
  hiddenCols?: string[];
  onlyPercentage?: boolean;
  companyHours: number;
  blockPercentage?: boolean;
  showErrors: boolean;
};

const TimesheetDetailsTable = ({
  details,
  empID,
  maxDate,
  minDate,
  changeDateDetails,
  changeTimesheetValue,
  addDetailRow,
  removeDetailRow,
  hiddenCols,
  onlyPercentage,
  companyHours,
  blockPercentage,
  showErrors,
}: TimesheetDetailsTableProps) => {
  const [nestedExpandedRows, setNestedExpandedRows] =
    useState<DataTableExpandedRows>(
      details.reduce((acc, detail) => {
        if (detail.useDateDetails) {
          acc[detail.id] = true;
        }

        return acc;
      }, {} as DataTableExpandedRows)
    );

  const rowExpansionTemplate = (data: PayrollTimesheetDetails) => {
    return (
      <TimesheetDayDetailsTable
        dateDetails={data.dateDetails ?? []}
        empID={empID}
        detailID={data.id!}
        changeTimesheetValue={changeTimesheetValue}
        addDateDetailRow={addDetailRow}
        removeDetailRow={removeDetailRow}
        hiddenCols={hiddenCols}
        onlyPercentage={onlyPercentage}
        companyHours={companyHours}
        blockPercentage={blockPercentage}
        showErrors={showErrors}
      />
    );
  };

  return (
    <Table
      data={details}
      className={`expandedTable noFooter w-full`}
      dataKey="id"
      expandedRows={nestedExpandedRows}
      rowExpansionTemplate={rowExpansionTemplate}
      scrollable={false}
      scrollHeight={undefined}
      alwaysHiddenColumns={hiddenCols}
      hideColumns={true}
      hideColumnsDropdown={true}
    >
      <Column
        header="Emp ID"
        style={{ minWidth: '100px' }}
        headerClassName="hidden"
        className="justify-content-center text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={() => {
          return <span className="visibility-hidden">details</span>;
        }}
        style={{ minWidth: '180px', maxWidth: '180px' }}
        headerClassName="hidden"
        className=" text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        style={{ minWidth: '160px', maxWidth: '160px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Total Hrs"
        body={(record) => {
          return (
            <div className="ml-auto">
              {record.date?.toLocaleDateString('en-US', {
                weekday: 'long',
              })}
            </div>
          );
        }}
        style={{ minWidth: '100px', maxWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="date"
        header="Dates"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <CalendarStyled
                className="printHide"
                readOnlyInput
                showButtonBar
                value={record.date}
                onChange={(event) => {
                  if (!event.value) {
                    setNestedExpandedRows((rows) => {
                      delete rows[record.id];

                      return { ...rows };
                    });
                  }

                  changeDateDetails(
                    empID,
                    record.id,
                    event.value ? (event.value as Date) : undefined
                  );
                }}
                maxDate={maxDate}
                minDate={minDate}
              />
              <span className="printMedia printShow">
                {formatDate(record.date)}
              </span>
            </span>
          );
        }}
        style={{ minWidth: '120px', maxWidth: '120px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="regularHrs"
        body={(record, options) => {
          return (
            <RegularHoursInput
              id={`reg_detail_${empID}_${options.rowIndex}`}
              regularHrs={record.regularHrs}
              onlyPercentage={onlyPercentage}
              blockPercentage={blockPercentage}
              onChange={(value) => {
                changeTimesheetValue(
                  empID,
                  'regularHrs',
                  value ?? undefined,
                  record.id
                );
              }}
              companyHours={companyHours}
              disabled={record.useDateDetails}
            />
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="OT Hrs"
        field="overTimeHrs"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.overTimeHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'overTimeHrs',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    record.id
                  );
                }}
                disabled={record.useDateDetails}
                maxFractionDigits={2}
              />
              <span className="printMedia printShow">{record.overTimeHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Sick Hrs"
        field="sickHrs"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.sickHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'sickHrs',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    record.id
                  );
                }}
                disabled={record.useDateDetails}
                maxFractionDigits={2}
              />
              <span className="printMedia printShow">{record.sickHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        header="Holiday Hrs"
        field="holidayHrs"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                value={record.holidayHrs}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'holidayHrs',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    record.id
                  );
                }}
                disabled={record.useDateDetails}
                maxFractionDigits={2}
              />
              <span className="printMedia printShow">{record.holidayHrs}</span>
            </span>
          );
        }}
        style={{ minWidth: '95px', maxWidth: '95px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="jobNumber"
        body={(record) => {
          if (nestedExpandedRows[record.id]) {
            return;
          }

          if (record.useDateDetails) {
            return (
              <div
                className="mx-auto printHide text-center totalColor hover:underline cursor-pointer"
                onClick={() => {
                  setNestedExpandedRows((rows) => {
                    rows[record.id] = true;

                    return { ...rows };
                  });
                }}
              >
                Expand for details
              </div>
            );
          }

          return (
            <JobInput
              value={record.job}
              onChange={(event) => {
                changeTimesheetValue(empID, 'job', event.value, record.id);

                if (record.phaseCode) {
                  changeTimesheetValue(
                    empID,
                    'phaseCode',
                    undefined,
                    record.id
                  );
                }
              }}
            />
          );
        }}
        style={{ minWidth: '300px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="phaseCode"
        body={(record) => {
          if (
            !record.job ||
            record.job === 'N/A' ||
            nestedExpandedRows[record.id]
          ) {
            return;
          }

          if (record.useDateDetails) {
            return;
          }

          return (
            <PhaseCodeInput
              value={record.phaseCode}
              onChange={(event) => {
                changeTimesheetValue(
                  empID,
                  'phaseCode',
                  event.value,
                  record.id
                );
              }}
              job={record.job}
              showErrors={showErrors && !record.phaseCode}
            />
          );
        }}
        style={{ minWidth: '300px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        field="perDiem"
        header="Per Diem"
        body={(record) => {
          return (
            <span className="text-center w-full">
              <InputNumber
                className="printHide"
                inputClassName="w-full blackText text-standard text-center"
                min={0}
                value={record.perDiem}
                onBlur={(event) => {
                  changeTimesheetValue(
                    empID,
                    'perDiem',
                    parseFloat(event.target.value?.replace(/,/g, '')) ??
                      undefined,
                    record.id
                  );
                }}
                disabled={record.useDateDetails}
              />
              <span className="printMedia printShow">{record.perDiem}</span>
            </span>
          );
        }}
        style={{ minWidth: '100px', maxWidth: '100px' }}
        headerClassName="hidden"
        className="text-standard blackText tableCell expandedTableCell"
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (options.rowIndex + 1 !== details.length && !record.date) {
            return;
          }

          return (
            <ExpandIcon
              expanded={nestedExpandedRows[record.id]}
              onClose={() => {
                setNestedExpandedRows((rows) => {
                  delete rows[record.id];

                  return { ...rows };
                });
              }}
              onOpen={() => {
                if (record.date) {
                  setNestedExpandedRows((rows) => {
                    rows[record.id] = true;

                    return { ...rows };
                  });
                } else {
                  addDetailRow(empID);
                }
              }}
            />
          );
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
      <Column
        body={(record, options) => {
          if (details.length !== 1 && options.rowIndex + 1 !== details.length) {
            return (
              <i
                className="pi pi-trash text-17px cursor-pointer"
                onClick={() => {
                  removeDetailRow(empID, record.id);
                }}
              />
            );
          }

          return;
        }}
        style={{ maxWidth: '30px' }}
        headerClassName={`hidden`}
        className={`text-standard blackText printHide tableCell justify-content-center expandedTableCell`}
        footerClassName="tableFooter"
      />
    </Table>
  );
};

export default TimesheetDetailsTable;
