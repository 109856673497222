import { useMutation } from '@tanstack/react-query';
import { arrayComparison } from 'apps/tmr-frontend/src/utils/utils';
import { AxiosError } from 'axios';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import {
  EmployeePCReimbursement,
  PCReimbursementArgs,
  PCReimbursementDetails,
} from '../../../Interfaces/Accounting/Payroll.interface';
import {
  postPCReimbursementDraft,
  putPCReimbursementDraft,
  putPCReimbursementSubmitDraft,
  putReimbursementApproveDraft,
  putReimbursementRejectDraft,
} from '../../../services/PayrollService';
import { Access } from '../../../Interfaces/Role.interfaces';
import {
  checkReimbursementContent,
  prepareReimbursementPayload,
} from './utils';

type PCReimbursementProcessProps = {
  approvalMode: boolean;
  draft: EmployeePCReimbursement[];
  defaultDraftID?: string;
  isSubmitted?: boolean;
  blockAll?: boolean;
  afterCheck: (showErrors: boolean) => void;
  afterUpdate: (
    data: EmployeePCReimbursement[],
    newSizeLimit: number,
    id: string
  ) => void;
  access: Access;
  resetDraft: () => void;
  filesToDeleteDefault: Record<number, string[]>;
};

const PCReimbursementProcess = ({
  defaultDraftID,
  approvalMode,
  isSubmitted,
  draft,
  blockAll,
  afterCheck,
  afterUpdate,
  access,
  resetDraft,
  filesToDeleteDefault,
}: PCReimbursementProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const toast = useRef<Toast>(null);
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const [blockSave, setBlockSave] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(
    isSubmitted ?? !defaultDraftID
  );
  const [blockApproval, setBlockApproval] = useState(
    approvalMode ? false : !defaultDraftID
  );
  const [hasBeenApproved, setHasBeenApproved] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const prevDraft = useRef(draft);

  const save = useMutation({
    mutationFn: ({
      draftID,
      draft,
    }: {
      draftID: string;
      draft: PCReimbursementArgs;
    }) => {
      if (draftID) {
        return putPCReimbursementDraft(draftID, draft, !!approvalMode);
      }

      return postPCReimbursementDraft(selectedCompany!.id, draft);
    },
    onSuccess: (data) => {
      let message = 'The data was saved successfully!';
      if (data.error.length) {
        message = `The data was saved with the exception of the following files: ${data.error.join(
          ', '
        )}.`;
      }

      showToast(
        'success',
        toast,
        'Save Petty Cash Reimbursement',
        message,
        3000
      );

      afterUpdate(
        data.draft.employees,
        data.draft.attachmentsSize,
        data.draft.id
      );

      setBlockSave(true);
      setBlockSubmit(false);
      setBlockApproval(false);

      if (!draftID) {
        setDraftID(data.draft.id);
      }
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Save Petty Cash Reimbursement',
        "The data couldn't be saved",
        3000
      );
    },
  });

  const saveDraft = (draftID: string, draft: EmployeePCReimbursement[]) => {
    const processedDraft = prepareReimbursementPayload(draft);

    processedDraft.map((emp) => {
      const prevAttachments = filesToDeleteDefault[emp.empID];

      if (prevAttachments) {
        emp.deleteAttachments = prevAttachments;
      }
    });

    save.mutate({ draftID, draft: processedDraft });
  };

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return putPCReimbursementSubmitDraft(draftID);
    },
    onSuccess: () => {
      setBlockSubmit(true);

      showToast(
        'success',
        toast,
        'Submit Petty Cash Reimbursement',
        'The data was submitted successfully!',
        3000
      );
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        'Submit Petty Cash Reimbursement',
        errorData?.message || `The draft couldn't be submitted`,
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: (draftID: string) => {
      return putReimbursementApproveDraft(draftID);
    },
    onSuccess: () => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      showToast(
        'success',
        toast,
        `${label} Petty Cash Reimbursement`,
        `The draft was ${mesLabel} successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        `${label} Petty Cash Reimbursement`,
        errorData?.message || `The draft couldn't be ${mesLabel}`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putReimbursementRejectDraft(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Petty Cash Reimbursement`,
        `The draft was rejected successfully!`,
        3000
      );

      setBlockApproval(true);
      setHasBeenApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Petty Cash Reimbursement`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  const formErrorMessage = (text: string) => {
    showToast('error', toast, `Petty Cash Reimbursement`, text, 3000);
  };

  useEffect(() => {
    if (prevDraft.current !== draft && !hasBeenApproved) {
      const areEquals = arrayComparison(prevDraft.current, draft);

      if (!areEquals) {
        setBlockSave(false);
        setBlockSubmit(true);
        setBlockApproval(true);
        prevDraft.current = draft;
      }
    }
  }, [draft, hasBeenApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <div className="sm:mr-auto">
        <ConfirmationDialog
          tagKey="draft-clear"
          Button={
            <LoadingButton
              label="Clear Form"
              fontSize="text-xl"
              bgColor="bg-orange-500"
              isLoading={false}
              type="button"
              disabled={hasBeenApproved}
            />
          }
          onConfirm={() => {
            resetDraft();
          }}
          message={'Are you sure you want to clear the form data?'}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        <LoadingButton
          label="Save"
          fontSize="text-xl"
          bgColor="buttonSecondary"
          isLoading={save.isLoading}
          onClick={() => saveDraft(draftID, draft)}
          disabled={blockAll || blockSave || save.isLoading}
        />
        {!approvalMode && access.editable === 'approval' && (
          <ConfirmationDialog
            Button={
              <LoadingButton
                label="Submit"
                fontSize="text-xl"
                isLoading={submit.isLoading}
                disabled={blockAll || blockSubmit || submit.isLoading}
                type="button"
              />
            }
            onConfirm={() => {
              if (checkReimbursementContent(draft)) {
                submit.mutate(draftID);
                afterCheck(false);
              } else {
                afterCheck(true);
                formErrorMessage('The form is missing some fields!');
              }
            }}
            message="Are you sure you want to submit this draft?"
          />
        )}
        {approvalMode && access.shouldApprove && (
          <ConfirmationDialog
            tagKey="draft-rejection"
            visibility={visibility}
            Button={
              <LoadingButton
                label={'Reject'}
                fontSize="text-xl"
                bgColor="bluwaiRed"
                isLoading={rejectRequest.isLoading}
                disabled={blockAll || blockApproval || rejectRequest.isLoading}
                type="button"
                onClick={() => setVisibility(true)}
              />
            }
            contentClassName="pb-0"
            onConfirm={() => {}}
            acceptClassName="hidden"
            rejectClassName="hidden"
            onHide={() => setVisibility(false)}
            message={
              <RejectionField
                onReject={() => {
                  setVisibility(false);
                }}
                onConfirm={(reason) => {
                  rejectRequest.mutate({ draftID, reason });
                  setVisibility(false);
                }}
              />
            }
          />
        )}
        {(approvalMode
          ? access.shouldApprove
          : access.editable === 'no-approval') && (
          <ConfirmationDialog
            tagKey="draft-approval"
            Button={
              <LoadingButton
                label={approvalMode ? 'Approve' : 'Upload'}
                fontSize="text-xl"
                bgColor="bg-green-500"
                isLoading={approveRequest.isLoading}
                disabled={blockAll || blockApproval || approveRequest.isLoading}
                type="button"
              />
            }
            onConfirm={() => {
              if (checkReimbursementContent(draft)) {
                approveRequest.mutate(draftID);
                afterCheck(false);
              } else {
                afterCheck(true);
                formErrorMessage('The form is missing some fields!');
              }
            }}
            message={`Are you sure you want to ${
              approvalMode ? 'approve' : 'upload'
            } this draft?`}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default PCReimbursementProcess;
