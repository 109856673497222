import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import RejectionField from '../../../components/dialog/RejectionField';
import LoadingButton from '../../../components/inputs/LoadingButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useProcessContext } from '../../../context/ProcessContext';
import {
  EmployeeTimesheet,
  PayrollTimesheetDetail,
  PayrollTimesheetDetailArgs,
  TimeSheetArgs,
  TimeSheetDetailsArgs,
} from '../../../Interfaces/Accounting/ACForms.interfaces';
import { Access } from '../../../Interfaces/Role.interfaces';
import {
  postTimesheetDraft,
  putTimesheetApproveDraft,
  putTimesheetDraft,
  putTimesheetRejectDraft,
  putTimesheetSubmitDraft,
} from '../../../services/PayrollService';
import {
  checkTimesheetContent,
  generateConfirmationMessage,
  prepareTimesheetPayload,
} from './util';

type TimesheetProcessProps = {
  approvalMode: boolean;
  draft: EmployeeTimesheet[];
  periodStart: Date;
  periodEnd: Date;
  defaultDraftID?: string;
  blockAll?: boolean;
  access: Access;
  changeToBundle: () => void;
  afterUpdate?: () => void;
  resetDraft: () => void;
  afterCheck: (value: boolean) => void;
};

const TimesheetProcess = ({
  defaultDraftID,
  approvalMode,
  draft,
  periodStart,
  periodEnd,
  blockAll,
  access,
  changeToBundle,
  afterUpdate,
  resetDraft,
  afterCheck,
}: TimesheetProcessProps) => {
  const { selectedCompany } = useCompanyContext();
  const processStatus = useProcessContext();
  const toast = useRef<Toast>(null);
  const [draftID, setDraftID] = useState<string>(defaultDraftID ?? '');
  const [visibility, setVisibility] = useState(false);
  const [askBundle, setAskBundle] = useState(true);
  const prevDraft = useRef(draft);

  const formErrorMessage = (text: string) => {
    showToast('error', toast, `Payroll Timesheet`, text, 3000);
  };

  const save = useMutation({
    mutationFn: ({
      draftID,
      draft,
    }: {
      draftID: string;
      draft: TimeSheetArgs;
    }) => {
      if (draftID) {
        return putTimesheetDraft(
          draftID,
          draft,
          periodStart,
          periodEnd,
          !!approvalMode
        );
      }

      return postTimesheetDraft(
        selectedCompany!.id,
        draft,
        periodStart,
        periodEnd
      );
    },
    onSuccess: (data) => {
      showToast(
        'success',
        toast,
        'Save Payroll Timesheet',
        'The data was saved successfully!',
        3000
      );

      processStatus.setSave(false);
      processStatus.setSubmit(true);
      processStatus.setApprove(true);

      if (!draftID) {
        setDraftID(data);
        localStorage.setItem('temp-timesheet-stored-id', data ?? '');
      }

      if (afterUpdate) {
        afterUpdate();
      }
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Save Payroll Timesheet',
        "The data couldn't be saved",
        3000
      );
    },
  });

  const saveDraft = (draftID: string, draft: EmployeeTimesheet[]) => {
    const processedDraft = prepareTimesheetPayload(draft);
    save.mutate({ draftID, draft: processedDraft });
  };

  const submit = useMutation({
    mutationFn: (draftID: string) => {
      return putTimesheetSubmitDraft(draftID);
    },
    onSuccess: () => {
      processStatus.setSubmit(false);

      showToast(
        'success',
        toast,
        'Submit Payroll Timesheet',
        'The data was submitted successfully!',
        3000
      );
    },
    onError: () => {
      showToast(
        'error',
        toast,
        'Submit Payroll Timesheet',
        "The data couldn't be submitted",
        3000
      );
    },
  });

  const approveRequest = useMutation({
    mutationFn: (draftID: string) => {
      return putTimesheetApproveDraft(draftID);
    },
    onSuccess: () => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      showToast(
        'success',
        toast,
        `${label} Payroll Timesheet`,
        `The draft was ${mesLabel} successfully!`,
        3000
      );

      processStatus.setApprove(false);
      processStatus.setAlreadyApproved(true);
    },
    onError: (error: AxiosError) => {
      const label = approvalMode ? 'Approve' : 'Upload';
      const mesLabel = approvalMode ? 'approved' : 'uploaded';

      const errorData = error.response?.data as {
        code: string;
        message: string;
      };
      showToast(
        'error',
        toast,
        `${label} Payroll Timesheet`,
        errorData?.message || `The draft couldn't be ${mesLabel}`,
        3000
      );
    },
  });

  const rejectRequest = useMutation({
    mutationFn: (props: { draftID: string; reason: string }) => {
      return putTimesheetRejectDraft(props.draftID, props.reason);
    },
    onSuccess: () => {
      showToast(
        'success',
        toast,
        `Reject Payroll Timesheet`,
        `The draft was rejected successfully!`,
        3000
      );

      processStatus.setApprove(false);
      processStatus.setAlreadyApproved(true);
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as {
        code: string;
        message: string;
      };

      showToast(
        'error',
        toast,
        `Reject Payroll Timesheet`,
        errorData?.message || `The draft couldn't be rejected`,
        3000
      );
    },
  });

  useEffect(() => {
    if (prevDraft.current !== draft && !processStatus.alreadyApproved) {
      processStatus.setSave(true);
      processStatus.setSubmit(false);
      processStatus.setApprove(false);

      prevDraft.current = draft;
    }
  }, [draft, processStatus.alreadyApproved]);

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      <div className="sm:mr-auto">
        <ConfirmationDialog
          tagKey="draft-clear"
          Button={
            <LoadingButton
              label="Clear Form"
              fontSize="text-xl"
              bgColor="bg-orange-500"
              isLoading={false}
              type="button"
              disabled={processStatus.alreadyApproved}
            />
          }
          onConfirm={() => {
            resetDraft();
          }}
          message={'Are you sure you want to clear the form data?'}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        {askBundle ? (
          <ConfirmationDialog
            tagKey="draft-save"
            Button={
              <LoadingButton
                label="Save"
                fontSize="text-xl"
                bgColor="buttonSecondary"
                isLoading={save.isLoading}
                disabled={blockAll || !processStatus.save || save.isLoading}
                type="button"
              />
            }
            onReject={() => {
              setAskBundle(false);
              saveDraft(draftID, draft);
            }}
            onConfirm={() => changeToBundle()}
            message={'Do you want to also fill out a Petty Cash Reimbursement?'}
          />
        ) : (
          <LoadingButton
            label="Save"
            fontSize="text-xl"
            bgColor="buttonSecondary"
            isLoading={save.isLoading}
            onClick={() => saveDraft(draftID, draft)}
            disabled={blockAll || !processStatus.save || save.isLoading}
          />
        )}
        {!approvalMode && access.editable === 'approval' && (
          <ConfirmationDialog
            tagKey="draft-submit"
            Button={
              <LoadingButton
                label="Submit"
                fontSize="text-xl"
                isLoading={submit.isLoading}
                disabled={blockAll || !processStatus.submit || submit.isLoading}
                type="button"
              />
            }
            onConfirm={() => {
              const { missingPhases } = checkTimesheetContent(draft);
              if (!missingPhases) {
                submit.mutate(draftID);
                afterCheck(false);
              } else {
                afterCheck(true);
                formErrorMessage(
                  'The Timesheet form is missing some phase codes!'
                );
              }
            }}
            message={generateConfirmationMessage(
              `Are you sure you want to submit this draft?`,
              draft
            )}
          />
        )}
        {approvalMode && access.shouldApprove && (
          <ConfirmationDialog
            tagKey="draft-rejection"
            visibility={visibility}
            Button={
              <LoadingButton
                label={'Reject'}
                fontSize="text-xl"
                bgColor="bluwaiRed"
                isLoading={rejectRequest.isLoading}
                disabled={
                  blockAll || !processStatus.approve || rejectRequest.isLoading
                }
                type="button"
                onClick={() => setVisibility(true)}
              />
            }
            contentClassName="pb-0"
            onConfirm={() => {}}
            acceptClassName="hidden"
            rejectClassName="hidden"
            onHide={() => setVisibility(false)}
            message={
              <RejectionField
                onReject={() => {
                  setVisibility(false);
                }}
                onConfirm={(reason) => {
                  rejectRequest.mutate({ draftID, reason });
                  setVisibility(false);
                }}
              />
            }
          />
        )}
        {(approvalMode
          ? access.shouldApprove
          : access.editable === 'no-approval') && (
          <ConfirmationDialog
            tagKey="draft-approval"
            Button={
              <LoadingButton
                label={approvalMode ? 'Approve' : 'Upload'}
                fontSize="text-xl"
                bgColor="bg-green-500"
                isLoading={approveRequest.isLoading}
                disabled={
                  blockAll || !processStatus.approve || approveRequest.isLoading
                }
                type="button"
              />
            }
            onConfirm={() => {
              const { missingPhases } = checkTimesheetContent(draft);
              if (!missingPhases) {
                approveRequest.mutate(draftID);
                afterCheck(false);
              } else {
                afterCheck(true);
                formErrorMessage(
                  'The Timesheet form is missing some phase codes!'
                );
              }
            }}
            message={generateConfirmationMessage(
              `Are you sure you want to ${
                approvalMode ? 'approve' : 'upload'
              } this draft?`,
              draft
            )}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TimesheetProcess;
