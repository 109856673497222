import React, { useContext, useMemo, useState } from 'react';

type ProcessContextData = {
  save: boolean;
  setSave: (save: boolean) => void;
  submit: boolean;
  setSubmit: (submit: boolean) => void;
  approve: boolean;
  setApprove: (approve: boolean) => void;
  alreadyApproved: boolean;
  setAlreadyApproved: (approve: boolean) => void;
};

const ProcessContext = React.createContext({} as ProcessContextData);

type ProcessContextProviderProps = {
  children: React.ReactNode;
};

export const ProcessContextProvider = ({
  children,
}: ProcessContextProviderProps) => {
  const [save, setSave] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [approve, setApprove] = useState(false);
  const [alreadyApproved, setAlreadyApproved] = useState(false);

  const contextValue = useMemo(
    () => ({
      save,
      submit,
      approve,
      alreadyApproved,
      setSave,
      setSubmit,
      setApprove,
      setAlreadyApproved,
    }),
    [save, submit, approve]
  );

  return (
    <ProcessContext.Provider value={contextValue}>
      {children}
    </ProcessContext.Provider>
  );
};

export const useProcessContext = () => useContext(ProcessContext);
