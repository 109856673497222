import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import { useCompanyContext } from '../../../context/CompanyContext';
import { useFormContext } from 'react-hook-form';
import { fetchAPOpenPerJob } from '../../../services/AccountsPayablesService';
import APOpenPerJobTable from './APOpenPerJobTable';
import { fetchAccountsPayablesFilters } from '../APFilters';
import { processPayableListPerJob } from '../apUtil';
import { PaymentBatch } from '../../../Interfaces/Accounting/AccountsPayables.interfaces';

type APopenPerJobTableContainerProps = {
  isDrilldown?: boolean;
  showPerJobRetainage?: boolean;
  showPerJobAmount?: boolean;
  createBatch?: boolean;
  changeCreateBatch?: (data: boolean) => void;
};

const APopenPerJobTableContainer = React.forwardRef<
  HTMLDivElement,
  APopenPerJobTableContainerProps
>(
  (
    {
      isDrilldown,
      showPerJobRetainage,
      showPerJobAmount,
      createBatch,
      changeCreateBatch,
    },
    ref
  ) => {
    const { selectedCompany } = useCompanyContext();
    const { watch } = useFormContext<fetchAccountsPayablesFilters>();
    const filters = watch();
    const enabled = !!selectedCompany;

    const { data, isFetching, isError } = useQuery({
      queryKey: ['getAPOpenPerJob', selectedCompany, filters],
      queryFn: ({ signal }) => {
        const { reportType, ...otherFilters } = filters;

        return fetchAPOpenPerJob(
          { ...otherFilters, company: selectedCompany! },
          signal
        );
      },
      refetchOnWindowFocus: false,
      enabled: enabled,
    });

    if (isFetching || !enabled)
      return (
        <div className="text-center mx-auto mt-3">
          <ProgressSpinner />
        </div>
      );

    if (isError)
      return (
        <ErrorMessage
          content={'Failed to obtain data! Please try again later.'}
        />
      );

    return (
      <APOpenPerJobTable
        accountsPayables={
          isDrilldown ? data.payables : processPayableListPerJob(data.payables)
        }
        drilldownStyles={isDrilldown}
        showRetainage={showPerJobRetainage}
        showOpenAmount={showPerJobAmount}
        createBatch={createBatch}
        changeCreateBatch={changeCreateBatch}
        draftID={data.batch?.id}
        defaultBatchPayables={data.batch?.payments.map(
          (payment: PaymentBatch) => {
            const { id, ...otherFields } = payment;
            return otherFields;
          }
        )}
      />
    );
  }
);

export default APopenPerJobTableContainer;
